import React, { lazy, useState } from "react";
import { useHome } from "../logic/Home/useHome";
import WatchLiveVideo from "../components/WatchLiveVideo";
import { connect } from "react-redux";
import { fetchOtherdetails } from "../actions/otherdetailsAction";
import { TitleComponent } from "../components/TitleComponent";
import { useSeo } from "../logic/Seo/useSeo";
import { useDimensions } from "../logic/Dimentions";
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useSeasonal } from "../logic/Seasonal";
import SecMenu from "../components/SecMenu";
import MobileMenu from "../components/MobileMenu";
const HomeLiveWatch = lazy(() => import("../components/Mobile/HomeLIveWatch"));
const FullSchedule = lazy(() => import("../components/Mobile/FullSchedule"));
const ShalomTvApp = lazy(() => import("../components/ShalomTvApp"));
const FindShalom = lazy(() => import("../components/FindShalom"));
const Subscription = lazy(() => import("../components/Subscription"));
const Footer = lazy(() => import("../components/Footer"));
const BottomTab = lazy(() => import("../components/BottomTab"));
const LiveTitle = lazy(() =>
  import("../components/Mobile/HomeLIveWatch/LiveTitle")
);

const WatchLive = ({ fetchOtherdetails, otherdetails }) => {
  useHome({ fetchOtherdetails, otherdetails });
  useSeo();
  const { width } = useDimensions();
  const [fullscreen, setFullscreen] = useState(false);
  const [showFullSchedule, setShowFullSchedule] = useState(false);
  const [toggleScreen, setToggleScreen] = useState(false);

  const { classes } = useSeasonal();
  return (
    <>
      {!fullscreen ? (
        <>
          {width >= 992 ? <SecMenu /> : <MobileMenu />}
          <WatchLiveVideo
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            toggleScreen={toggleScreen}
            setToggleScreen={setToggleScreen}
          />
          <TitleComponent title="Shalom world " />
          {width >= 992 && <SocialMediaWrap classes={classes} />}
          {width >= 992 ? (
            <>
              <ShalomTvApp />
              <FindShalom />
              <Subscription />
              <Footer />
            </>
          ) : (
            <>
              <LiveTitle setShowFullSchedule={setShowFullSchedule} />
              <HomeLiveWatch />
              {showFullSchedule && (
                <FullSchedule setShowFullSchedule={setShowFullSchedule} />
              )}
              <BottomTab />
            </>
          )}
        </>
      ) : (
        <WatchLiveVideo
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          toggleScreen={toggleScreen}
          setToggleScreen={setToggleScreen}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return { otherdetails: state.otherdetails.data };
};
export default connect(mapStateToProps, { fetchOtherdetails })(WatchLive);
