import React, { useState, useRef, useCallback } from "react";
import styles from "./PodcastDetail.module.scss";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { usePodCast } from "../../logic/PodCast/usePodCast";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import { useDimensions } from "../../logic/Dimentions";
import {
  addToPlaylist,
  appendToPlaylist,
  purgePlaylist,
  triggerPlay,
  setActiveId,
  fetchPodcast,
  fetchMorePodcast,
  fetchAllPodcastEpisodesPlay,
  updatePodcastEpisode,
} from "../../actions/podCastPlayListAction";
import { useSeo } from "../../logic/Seo/useSeo";
import PodcastBanner from "../PodcastBanner";
import ListenOn from "../ListenOn";
import PodcastEpisodes from "../PodcastEpisodes";
import PodcastDetailMob from "../Mobile/PodcastDetailMob";

const PodcastDetail = ({
  data,
  fetchAllPodcastEpisodesPlay,
  updatePodcastEpisode,
  appendToPlaylist,
  addToPlaylist,
  purgePlaylist,
  triggerPlay,
  setActiveId,
  fetchPodcast,
  fetchMorePodcast,
  play,
  activeId,
  progress,
}) => {

  useSeo();
  useViewportAnimation();
  const { width } = useDimensions();
  const [audioDtls, setAudioDtls] = useState(false);
  const [seasonId, setSeasonId] = useState(0); //based on Season drop down

  let history = useHistory();
  const ref = useRef(null);
  let { programId } = useParams();
  usePodCast({ fetchPodcast, programId, seasonId });

  /**
   * Push all the items to playlist and keep ID as active, trigger play
  */
  // data.podCastData
  // data.playLists
  const handleUpdatePlaylist = (id) => {
    let indexFound = 0;
    let podCastDataId = data.podCastData?.id;
    data.podCastData?.seasons[seasonId].episodes.forEach((element, index) => {
      if (id === element.id) {
        indexFound = index;
        data.podCastData.seasons[seasonId].episodes[index].active = true;
        setActiveId(data.podCastData.seasons[seasonId].episodes[index].id);
      } else {
        data.podCastData.seasons[seasonId].episodes[index].active = false;
      }
    });

    appendToPlaylist(data.podCastData.seasons[seasonId].episodes, indexFound, podCastDataId, data?.podCastData?.seasons[seasonId]?.id);

    const timer = setTimeout(() => {
      handlePlay(true);
    }, 60);
    return () => clearTimeout(timer);
  };


  /**
   * Trigger Play event in Redux
  */
  const handlePlay = (status) => {
    if (data.podCastData?.seasons[seasonId].page <= data.podCastData?.seasons[seasonId].pages) {

      let podcastId = data.podCastData?.id;
      let seasonNumber = data.podCastData.seasons[seasonId].seasonNumber
      fetchAllPodcastEpisodesPlay({ fetchPodcast, podcastId, seasonId, seasonNumber });
    }
    triggerPlay(status);
  };


  /**
   * Push all the items to playlist and keep index 0 as active, trigger play
  */

  const handleAddToPlaylist = () => {
    let indexFound = 0;
    let podCastDataId = data.podCastData?.id;

    data.podCastData?.seasons[seasonId].episodes.forEach((element, index) => {
      if (index === 0) {
        data.podCastData.seasons[seasonId].episodes[index].active = true;
        indexFound = index;
        setActiveId(data.podCastData.seasons[seasonId].episodes[index].id);
      } else {
        data.podCastData.seasons[seasonId].episodes[index].active = false;
      }
    });
    appendToPlaylist(data.podCastData.seasons[seasonId].episodes, indexFound, podCastDataId, data?.podCastData?.seasons[seasonId]?.id);

    const timer = setTimeout(() => {
      handlePlay(true);
    }, 60);
    return () => clearTimeout(timer);
  };

  /**
   * Redirect if taget _blank or history push
  */

  const handleSupportPodcast = () => {
    if (data.podCastData?.target === "_blank") {
      window.open(data.podCastData?.support, "_blank").focus();
    } else {
      history.push(data.podCastData?.support);
    }
  };

  /**
   * Set redux so that upcoming route has podcast episdoe already loaded.
   */
  const handleClickUrl = (episode) => {
    updatePodcastEpisode(episode);
  };

  const handleScrollToEpisodes = () => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const handleSeasonChange = (value) => {
    fetchPodcast({ slug: programId, seasonId: data.podCastData?.seasons[value].seasonNumber, page: 1 })
  }

  const loadMoreEpisodes = (pageNo) => {
    if (!isNaN(pageNo) && data?.podCastData.seasons && data?.podCastData?.seasons[seasonId]) {
      handleFetchMorePodcast(programId, data, pageNo, seasonId)
    }
  };

  const handleFetchMorePodcast = useCallback((programId, data, pageNo, seasonId) => {
    fetchMorePodcast({ slug: programId, seasonNumber: data.podCastData?.seasons[seasonId].seasonNumber, pageNo: pageNo, podcastId: data.podCastData.id })
  }, []);

  return (
    <>

      {width >= 992 ? (
        <>
          <div className={styles.PodcastDetailWrapper}>
            {/* Posdcast banner section */}
            <PodcastBanner
              data={data.podCastData}
              handleAddToPlaylist={handleAddToPlaylist}
              handleSupportPodcast={handleSupportPodcast}
              handleScrollToEpisodes={handleScrollToEpisodes}
              seasonId={seasonId}
            />
            {/* Posdcast Listen  section */}
            <ListenOn data={data.podCastData?.streamingPlatforms} ref={ref} />

            {/* Posdcast episodes section */}

            {data.podCastData && data.podCastData?.seasons && data.podCastData?.seasons.length >= 1 &&
              <PodcastEpisodes
                handlePlay={handlePlay}
                data={data.podCastData}
                handleUpdatePlaylist={handleUpdatePlaylist}
                handleAddToPlaylist={handleAddToPlaylist}
                activeId={activeId}
                progress={progress}
                podCastPlayListData={data.playLists}
                play={play}
                handleClickUrl={handleClickUrl}
                setSeasonId={setSeasonId}
                seasonId={seasonId}
                handleSeasonChange={handleSeasonChange}
                loadMoreEpisodes={loadMoreEpisodes}
              />
            }

          </div>
        </>
      ) : (
        <div
          className={`${styles.mobPodcastDetailWrapper} ${activeId ? styles.audioThumbShow : ""
            }`}
        >
          {data.podCastData && data.podCastData?.seasons && data.podCastData?.seasons.length >= 1 &&
            <PodcastDetailMob
              handlePlay={handlePlay}
              data={data?.podCastData}
              handleUpdatePlaylist={handleUpdatePlaylist}
              handleAddToPlaylist={handleAddToPlaylist}
              activeId={activeId}
              progress={progress}
              podCastPlayListData={data.playLists}
              play={play}
              handleClickUrl={handleClickUrl}
              setActiveId={setActiveId}
              purgePlaylist={purgePlaylist}
              setAudioDtls={setAudioDtls}
              audioDtls={audioDtls}
              handleSupportPodcast={handleSupportPodcast}
              setSeasonId={setSeasonId}
              seasonId={seasonId}
              handleSeasonChange={handleSeasonChange}
              loadMoreEpisodes={loadMoreEpisodes}
            />
          }
        </div>
      )
      }
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    data: state.podCastPlayList,
    play: state.podCastPlayList?.play,
    activeId: state.podCastPlayList?.activeId,
    progress: state.podCastPlayList?.progress,
  };
};

export default connect(mapStateToProps, {
  fetchPodcast,
  fetchMorePodcast,
  fetchAllPodcastEpisodesPlay,
  updatePodcastEpisode,
  appendToPlaylist,
  addToPlaylist,
  purgePlaylist,
  triggerPlay,
  setActiveId,
})(PodcastDetail);
