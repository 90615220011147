import React, { useState } from "react";
import { Col } from "react-bootstrap";
import styles from "./PodcastThumb.module.scss";
import { Link } from "react-router-dom";
import { useSeasonal } from "../../../logic/Seasonal";

/**
 * This component renders a responsive thumbnail for a podcast containing image episodesCount and play button
 *
 * @param {item} item The podcast item data.
 * @param {function} handleClick A function that is called when the thumbnail is clicked.
 * @returns {ReactElement} The rendered thumbnail component.
 */

const MobPodcastThumb = ({ item, handleClick }) => {
    /**
     * Tracks whether the image has been loaded.
     */
    const [isLoaded, setLoaded] = useState(false);

    /**
     * Called when the image is loaded.
     */
    const onLoad = () => {
        setLoaded(true);
    };

    /**
     * Called when the thumbnail is clicked.
     */
    const handleClickItem = () => {
        handleClick(item);
    };


    /**
     * Gets the seasonal classes.
     */
    const { classes } = useSeasonal();

    /**
     * Renders the thumbnail component.
     */
    return (
        <Col xs={12} md={6} lg={4} className="animation">
            <Link to={`/podcast/${item?.url}`} onClick={handleClickItem}>
                <div className={styles.mobPodcastThumb}>
                    <figure className="figImg">
                        <img
                            className={isLoaded ? "loaded" : null}
                            src={item?.thumbnail}
                            onLoad={onLoad}
                            alt=""
                        />
                    </figure>
                    <div className={styles.contentWrap}>
                        <h3 className={styles.thumbTitle}>{item?.title}</h3>
                        <div className={styles.playEpsodes}>
                            <span className={`play-icon xs ${classes.podcastPlayClass}`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="12"
                                    viewBox="0 0 10 12"
                                >
                                    <path
                                        d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z"
                                        transform="translate(10) rotate(90)"
                                        fill="#ebab0b"
                                    />
                                </svg>
                            </span>
                            <span className={styles.playText}>Play Episodes</span>
                        </div>
                        <div className={styles.episodesCount}>
                            {" "}
                            {item?.totalEpisodes} Episodes
                        </div>
                    </div>

                    <Link to={`/podcast/${item?.url}`} className="full_link"></Link>
                </div>
            </Link>
        </Col>
    );
};

export default MobPodcastThumb;
