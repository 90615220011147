/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from "react";
import styles from "./DonateDollarADayBanner.module.scss";
import imgBanner from "../../assets/img/bannerDAD.jpg";
import mobImgBanner from "../../assets/img/mobBannerDAD.jpg";

import imgBannerAUS from "../../assets/img/bannerDAD_AUS.jpg";
import mobImgBannerAUS from "../../assets/img/mobBannerDAD_AUS.jpg";

import imgQuotes from "../../assets/img/bannerDADQuotes.png";
import imgDADLogo from "../../assets/img/imgDADLogo.png";
import imgEADlogo from "../../assets/img/imgEADlogo.png";
import imgOPADLogo from "../../assets/img/imgOPADLogo.png";
import imgOFADLogo from "../../assets/img/imgOFADLogo.png";
import { useDimensions } from "../../logic/Dimentions";
import {
  RiFacebookFill,
  RiTwitterFill,
  RiWhatsappFill,
  RiMailFill
} from "react-icons/ri";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton
} from "react-share";


const DollarADayBanner = ({ donationData }) => {
  const { width } = useDimensions();

  // Add Dollar-A-Day script to DOM on component mount
  useEffect(() => {
    const script = document.createElement("script");

    if (process.env.REACT_APP_PRODUCTION === "true") {
      script.src =
        "https://ark.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    } else {
      script.src =
        "https://qabackend.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    }
    script.async = true;
    document.body.appendChild(script);

    // Remove script from DOM on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* Desktop banner */}
      <section className={`${styles.dollarADayBanner} ${((donationData?.region)?.toUpperCase() === 'AU') ? styles.donate_aus : '' }` }>
      {((donationData?.region)?.toUpperCase() === 'AU') ?
        <figure className="figImg">
          {width >= 992 ? (
            <img src={imgBannerAUS} alt="Dollar A Day AUS " />
          ) : (
            <img src={mobImgBannerAUS} alt="Dollar A Day AUS" />
          )}
        </figure>
        :
        (
          <figure className="figImg">
          {width >= 992 ? (
            <img src={imgBanner} alt="Dollar A Day " />
          ) : (
            <img src={mobImgBanner} alt="Dollar A Day " />
          )}
        </figure>
        )
      }
        <div className={`${styles.donateShare} ${styles.showMob}`}>
          <span>Share </span>

          <ul>
            <li className='iconFB'>
              <FacebookShareButton url={`${window.location.href}`}>
                <a href={() => false}>
                  <RiFacebookFill />
                </a>
              </FacebookShareButton>
            </li>
            <li className='iconTwitter'>
              <TwitterShareButton url={`${window.location.href}`}>
                <a href={() => false}>
                  <RiTwitterFill />
                </a>
              </TwitterShareButton>
            </li>
            <li className='iconWhatsapp'>
              <WhatsappShareButton url={`${window.location.href}`}>
                <a href={() => false}>
                  <RiWhatsappFill />
                </a>
              </WhatsappShareButton>
            </li>
            <li className='iconMail'>
              <EmailShareButton
                subject=""
                body=""
                url={`${window.location.href}`}>
                <a href={() => false}>
                  <RiMailFill />
                </a>
              </EmailShareButton>
            </li>
          </ul>

        </div>
        <div className={styles.bannerForm}>
          <div className={styles.bannerQuotes}>
            <div className={styles.imgText}>
              <figure className={styles.imgQuotes}>
                <img src={imgQuotes} alt="Quotes" />
              </figure>
              {((donationData?.region)?.toUpperCase() !== 'AU') &&
                  <figure className={styles.imgDADLogo}>
                    {((donationData?.region)?.toUpperCase() === 'UK') ?
                      <img src={imgOPADLogo} alt="One £ A Day" />
                      :
                      ((donationData?.region)?.toUpperCase() === 'EU') ?
                        ((donationData?.country)?.toUpperCase() === 'CH') ?
                          <img src={imgOFADLogo} alt="One ₣ A Day" />
                          :
                          <img src={imgEADlogo} alt="Euro A Day" />
                        :
                        <img src={imgDADLogo} alt="Dollar A Day" />
                    }
                  </figure>
              }

            </div>

            <div className={`${styles.donateShare}`}>
              <span>Share</span>
              <ul>
                <li className='iconFB'>
                  <FacebookShareButton url={`${window.location.href}`}>
                    <a href={() => false}>
                      <RiFacebookFill />
                    </a>
                  </FacebookShareButton>
                </li>
                <li className='iconTwitter'>
                  <TwitterShareButton url={`${window.location.href}`}>
                    <a href={() => false}>
                      <RiTwitterFill />
                    </a>
                  </TwitterShareButton>
                </li>
                <li className='iconWhatsapp'>
                  <WhatsappShareButton url={`${window.location.href}`}>
                    <a href={() => false}>
                      <RiWhatsappFill />
                    </a>
                  </WhatsappShareButton>
                </li>
                <li className='iconMail'>
                  <EmailShareButton url={`${window.location.href}`}>
                    <a href={() => false}>
                      <RiMailFill />
                    </a>
                  </EmailShareButton>
                </li>
              </ul>
            </div>

          </div>

          {width >= 992 && <>
            {/* Country specific form links */}
            {donationData && donationData?.country && donationData?.region &&
              <>
                {donationData.country.toUpperCase() === "CA" ? (
                  <>
                    {/* Show fundraiser form in CA */}
                    <div className={`${styles.bannerEmbededForm} section-region-ca section-country-${(donationData?.country)?.toUpperCase()}`}>
                      <a href="#XYJRQAGB"></a>
                    </div>
                  </>
                ) : donationData.region.toUpperCase() === "AU" ? (
                  <>
                    {/* Show fundraiser form in AU */}
                    < div className={`${styles.bannerEmbededForm} section-region-au section-country-${(donationData?.region)?.toUpperCase()}`}>
                      <a href="#XHRYSYLM"></a>
                    </div>
                  </>
                ) : donationData.region.toUpperCase() === "UK" ? (
                  <>
                    {/* Show fundraiser form in UK */}
                    < div className={`${styles.bannerEmbededForm} section-region-uk section-country-${(donationData?.region)?.toUpperCase()}`}>
                      <a href="#XHKDKLRT"></a>
                    </div>
                  </>

                ) : donationData.region.toUpperCase() === "EU" ? (
                  <>
                    {donationData?.country.toUpperCase() === "CH" ? (
                      <>
                        {/* Show fundraiser form in Switzerland*/}
                        <div className={`${styles.bannerEmbededForm} section-ch section-region-${(donationData?.region)?.toUpperCase()} section-country-${(donationData?.country)?.toUpperCase()}`}>
                          <a href="#XLDVSSCZ"></a>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Show fundraiser form in Europe*/}
                        <div className={`${styles.bannerEmbededForm} section-eu section-region-${(donationData?.region)?.toUpperCase()} section-country-${(donationData?.country)?.toUpperCase()}`}>
                          <a href="#XMLZGDSP"></a>
                        </div>
                      </>
                    )}

                  </>
                ) : (
                  <>
                    {/* Show fundraiser form in US, MX and ROW*/}
                    <div className={`${styles.bannerEmbededForm} section-region-us section-country-${(donationData?.country)?.toUpperCase()}`}>
                      <a href="#XHKAJKLB"></a>
                    </div>
                  </>
                )}
              </>
            }
          </>}

        </div>
      </section >

      {/* Mobile banner : Country specific form links */}

      {width < 992 && <div className={`${styles.mobViewDAD} `}>

        {donationData && donationData?.country && donationData?.region &&
          <>
            {donationData.country.toUpperCase() === "CA" ? (
              <>
                {/* Show fundraiser form in CA */}
                <div className={`${styles.mobEmbededForm} section-region-ca section-country-${(donationData?.country)?.toUpperCase()}`}>
                  <a href="#XYJRQAGB"></a>
                </div>
              </>

            ) : donationData.region.toUpperCase() === "AU" ? (
              <>
                {/* Show fundraiser form in AU */}
                < div className={`${styles.mobEmbededForm} section-region-au section-country-${(donationData?.region)?.toUpperCase()}`}>
                  <a href="#XHRYSYLM"></a>
                </div>
              </>

            ) : donationData.region.toUpperCase() === "UK" ? (
              <>
                {/* Show fundraiser form in UK */}
                < div className={`${styles.mobEmbededForm} section-region-uk section-country-${(donationData?.region)?.toUpperCase()}`}>
                  <a href="#XHKDKLRT"></a>
                </div>
              </>
            ) : donationData.region.toUpperCase() === "EU" ? (
              <>
                {donationData?.country.toUpperCase() === "CH" ? (
                  <>
                    {/* Show fundraiser form in Switzerland*/}
                    <div className={`${styles.bannerEmbededForm} section-ch section-region-${(donationData?.region)?.toUpperCase()} section-country-${(donationData?.country)?.toUpperCase()}`}>
                      <a href="#XLDVSSCZ"></a>
                    </div>
                  </>
                ) : (
                  <>
                    {/* Show fundraiser form in Europe*/}
                    <div className={`${styles.bannerEmbededForm} section-eu section-region-${(donationData?.region)?.toUpperCase()} section-country-${(donationData?.country)?.toUpperCase()}`}>
                      <a href="#XMLZGDSP"></a>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {/* Show fundraiser form in US, MX and ROW*/}
                <div className={`${styles.mobEmbededForm} section-region-us section-country-${(donationData?.country)?.toUpperCase()}`}>
                  <a href="#XHKAJKLB"></a>
                </div>
              </>
            )}
          </>
        }

        {/* Show Country Currency based Image for specific country/region  */}
        {((donationData?.region)?.toUpperCase() !== 'AU') &&
            <figure className="dad-logo mt-4">
              {((donationData?.region)?.toUpperCase() === 'UK') ?
                <img src={imgOPADLogo} alt="One £ A Day" />
                :
                ((donationData?.region)?.toUpperCase() === 'EU') ?
                  ((donationData?.country)?.toUpperCase() === 'CH') ?
                    <img src={imgOFADLogo} alt="One ₣ A Day" />
                    :
                    <img src={imgEADlogo} alt="Euro A Day" />
                  :
                  <img src={imgDADLogo} alt="Dollar A Day" />
              }
            </figure>
        }

        <div className={styles.thankyouWrap}>
          <p>
            <strong>
              Shalom World has huge plans to reach millions more with Christ's
              loving message, but we need your help!
            </strong>
          </p>
          <p>
            With your monthly donation, we welcome you to the Shalom Peace
            Fellowship - the family of our supporters
          </p>
        </div>
      </div >
      }
    </>
  )
}


export default DollarADayBanner;
