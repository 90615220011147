import { useState, useEffect } from 'react'

export default function useIsVisible(ref, rootMargin) {

    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting), { rootMargin }
        )
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [ref, rootMargin])

    return isIntersecting
}