/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

function ScrollToTop({ history }) {
  const location = useLocation();
  useEffect(() => {
    if (document.body.classList.contains("isplaying")) {
      document.body.classList.remove("isplaying");
    }
  }, [location]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (
        !history.location.pathname.includes("/show/") &&
        !history.location.pathname.includes("/watchon/channelfinder") &&
        !history.location.pathname.includes("/watchon/apps") &&
        !history.location.pathname.includes("/watchon/connectedtv") &&
        history.location.pathname != "/swprayer/todaymass" &&
        history.location.pathname != "/swprayer/masspetition" &&
        history.location.pathname != "/swprayer/prayerrequest" &&
        history.location.pathname != "/swprayer/testimony" &&
        history.location.pathname != "/swprayer/about"

      ) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
