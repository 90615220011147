import {
  FETCH_PODCAST,
  FETCH_MORE_PODCAST,
  FETCH_PODCASTEPISODE,
  UPDATE_PODCASTEPISODE,
  ADD_TOPLAYLIST,
  APPEND_TOPLAYLIST,
  UPDATE_PLAYLIST,
  PURGE_TOPLAYLIST,
  TRIGGER_PLAY,
  TRIGGER_ACTIVEID,
  TRIGGER_PROGRESS,
  FETCH_PODCAST_EPISODES,
  FETCH_PODCAST_EPISODESPLAY,
} from "./types";
import salomapi from "../apis";

export const addToPlaylist = (episode) => async (dispatch) => {
  dispatch({ type: ADD_TOPLAYLIST, payload: episode });
};

export const appendToPlaylist = (episode, indexFound, podCastDataId, seasonId) => async (dispatch) => {
  dispatch({
    type: APPEND_TOPLAYLIST,
    payload: episode,
    indexFound: indexFound,
    podCastDataId: podCastDataId,
    seasonId: seasonId
  });
};

export const updatePlaylist = (episode, indexFound) => async (dispatch) => {
  dispatch({ type: UPDATE_PLAYLIST, payload: episode, indexFound: indexFound });
};

export const purgePlaylist = () => async (dispatch) => {
  dispatch({ type: PURGE_TOPLAYLIST });
};

export const triggerPlay = (status) => async (dispatch) => {
  dispatch({ type: TRIGGER_PLAY, payload: status });
};

export const setActiveId = (status) => async (dispatch) => {
  dispatch({ type: TRIGGER_ACTIVEID, payload: status });
};

export const setProgress = (status) => async (dispatch) => {
  dispatch({ type: TRIGGER_PROGRESS, payload: status });
};

/**/
export const fetchPodcast = (params) => async (dispatch) => {
  let seasonId = params.seasonId ? params.seasonId : '';

  const response = await salomapi.get("/podcast/single?url=" + params.slug + "&season=" + seasonId + '&page=');
  if (response?.data?.status === "success") {
    dispatch({ type: FETCH_PODCAST, payload: response?.data });
  }
};
export const fetchMorePodcast = (params) => async (dispatch) => {
  let seasonNumber = params.seasonNumber ? params.seasonNumber : '';
  let page = params.pageNo >= 1 ? '&page=' + params.pageNo : "";
  const response = await salomapi.get("/podcast/episodes?podcastId=" + params.podcastId + "&season=" + seasonNumber + page);
  response.data.seasonNumber = seasonNumber;
  if (response?.data?.status === "success") {
    dispatch({ type: FETCH_MORE_PODCAST, payload: response?.data });
  }
};

export const fetchPodcastEpisode = (params) => async (dispatch) => {
  const response = await salomapi.get(
    "/podcast/episode/single?url=" + params.slug
  );
  if (response.data.status === "success") {
    dispatch({ type: FETCH_PODCASTEPISODE, payload: response?.data });
  }
};

export const updatePodcastEpisode = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PODCASTEPISODE, payload: data });
};

export const fetchAllPodcastEpisode = (params) => async (dispatch) => {
  let seasonNumber = params.seasonNumber ? params.seasonNumber : '';
  const response = await salomapi.get(`/podcast/allepisodes?podcastId=${params.podcastId}&season=${seasonNumber}`);
  if (response?.data?.status === "success") {
    dispatch({ type: FETCH_PODCAST_EPISODES, payload: response?.data });
  }
};

export const fetchAllPodcastEpisodesPlay = (params) => async (dispatch) => {
  let seasonNumber = params.seasonNumber ? params.seasonNumber : '';
  const response = await salomapi.get(`/podcast/allepisodes?podcastId=${params.podcastId}&season=${seasonNumber}`);
  if (response?.data?.status === "success") {
    dispatch({ type: FETCH_PODCAST_EPISODESPLAY, payload: response?.data });
  }
};

