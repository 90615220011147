import React from "react";
import Row from "react-bootstrap/Row";
import { useDimensions } from "../../logic/Dimentions";
import PodcastThumb from "../PodcastThumb";
import MobPodcastThumb from "../Mobile/PodcastThumb";

/**
 * This component renders a list of podcasts.
 *
 * @param {object} data The podcast data.
 * @param {function} handleClick A function that is called when a podcast is clicked.
 * @returns {PodcastList} The rendered podcast list component.
 */
const PodcastList = ({ data, handleClick }) => {
    /**
     * Gets the screen width.
     */
    const { width } = useDimensions();

    /**
     * Renders a podcast thumbnail for each podcast in the data array.
     */
    const rowStyles = width < 992 ? { height: "calc(100vh - 230px)", overflow: "auto" } : {};

    return (

        <Row style={rowStyles}>

            {data && data.length > 0
                ? data.map((item, index) => {
                    return (
                        <>
                            {width >= 992 ? (
                                <>
                                    <PodcastThumb
                                        item={item}
                                        handleClick={handleClick}
                                        key={index}
                                    />
                                </>
                            ) : (
                                <>
                                    <MobPodcastThumb
                                        item={item}
                                        handleClick={handleClick}
                                        key={index}
                                    />
                                </>
                            )}
                        </>
                    );
                })
                : null}
        </Row>
    );
};

export default PodcastList;
