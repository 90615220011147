import React, { useState, useEffect } from "react";
import styles from "./SwplusDashboard.module.scss";
import Container from 'react-bootstrap/Container';
import SwplusConfiguration from "../SwplusConfiguration";
import SwplusEditForm from "../SwplusEditForm";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/mediagalleryAction";
import { fetchCountryList } from "../../actions/swprayerAction";
import { fetchMediaGallery } from "../../actions/mediagalleryAction";
import { useHistory } from "react-router-dom";
import { useSeasonal } from '../../logic/Seasonal'

const SwplusDashboard = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const mediaUserProfile = useSelector(
    (state) => state.mediaUserProfileReducer
  );


  const mediaGallery = useSelector((state) => state.mediaGallery.mediaGallery);
  const countryList = useSelector((state) => state.swprayer.countryList);
  const { classes } = useSeasonal()
  const [displayType, setDisplayType] = useState(0);


  useEffect(() => {
    if (countryList.length == 0) {
      dispatch(fetchCountryList());
    }
    if (mediaGallery.length == 0) {
      dispatch(fetchMediaGallery());
    }
  }, []);

  const logoutHandler = () => {
    dispatch(logout());
    history.push(`/swplus`);
    // setTab("/swplus");
  };

  useEffect(() => {
    if (countryList.length == 0) {
      dispatch(fetchCountryList());
    }
    if (mediaGallery.length == 0) {
      dispatch(fetchMediaGallery());
    }
  }, []);

  return (
    <div
      className={styles.swplus_wrapper}
      style={{ background: `url(${mediaGallery.banner})` }}
    >

      <Container>
        <h1 className="is_title">SW+</h1>
        <div className={styles.SwplusDashboard}>
          <ul className={styles.ApplogoutContainer}>
            <li className={classes?.txtColor}>App ID : {mediaUserProfile.apiToken}</li>
            <li className={`${classes?.txtColor} ${styles.logout}`} onClick={logoutHandler}>
              Logout
            </li>
          </ul>
          <ul className={styles.header}>
            <li
              onClick={() => {
                setDisplayType(0);
              }}
              className={displayType == 0 && `${styles.active} ${classes?.txtColor} ${classes?.seasonalClass}`}
            >
              Configuration
            </li>
            <li
              onClick={() => {
                setDisplayType(1);
              }}
              className={displayType == 1 && `${styles.active} ${classes?.txtColor} ${classes?.seasonalClass}`}
            >
              Edit Details
            </li>
          </ul>
          {displayType == 0 ? (
            <SwplusConfiguration
              token={mediaUserProfile.apiToken}
            />
          ) : (
            <SwplusEditForm
              countryList={countryList}
              mediaGallery={mediaGallery}
              themeClasses={classes}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default SwplusDashboard;
