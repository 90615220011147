import React, { lazy, useState } from "react";
import EpisodeVideo from "../components/EpisodeVideo";
import { TitleComponent } from "../components/TitleComponent";
import { useSeo } from "../logic/Seo/useSeo";
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from "../logic/Seasonal";

const MobileEpiosdeDetails = lazy(() => import("../components/Mobile/EpisodeDetails"));
const CommonLayout = lazy(() => import("../components/Layouts/CommonLayout"));

const EpisodeVideoPage = () => {
  const { classes } = useSeasonal();
  const { width } = useDimensions();

  const [fullscreen, setFullscreen] = useState(false);
  const [toggleScreen, setToggleScreen] = useState(false);
  const [showControls, setShowControls] = useState(() => true);

  useSeo();

  /**
  * Determine the mobile operating system.
  * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
  *
  * @returns {String}
  */
  function androidOrIOS() {
    return [
      "iPad Simulanavigator.platformtor",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") &&
        "ontouchend" in document) ? (
      "mobile"
    ) : ("desktop");
  }

  return (
    <>
      <TitleComponent title="Shalom world " />
      {width >= 992 && <SocialMediaWrap classes={classes} />}

      {width < 991 || androidOrIOS() === 'mobile' ? (
        <CommonLayout>
          <MobileEpiosdeDetails
            showControls={showControls}
            setShowControls={setShowControls} />
        </CommonLayout>
      ) : (
        <>
          <EpisodeVideo
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            toggleScreen={toggleScreen}
            setToggleScreen={setToggleScreen}
            showControls={showControls}
            setShowControls={setShowControls}
          />
        </>
      )}
    </>
  );
};

export default EpisodeVideoPage;
