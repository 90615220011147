import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import styles from "./DonateDollarADay.module.scss";
import { Link, useLocation } from "react-router-dom";
import DonateDollarADayVideo from "../DonateDollarADayVideo";
import DonateDollarADayButton from "../DonateDollarADayButton";
import DonateDollarADayForm from "../DonateDollarADayForm";
import DonateDollarADayContent from "../DonateDollarADayContent";
import img_sw_logo from "../../assets/img/logo_sw.png";
import imgSupport from "../../assets/img/imgSupportQuotes.png";
import bgDAD from "../../assets/img/bgDAD.jpg";

/**
 * This component to display the Dollar-A-Day banner with country specific form links
 */

const DonateDollarADay = () => {
  const location = useLocation();
  const pathName = location.pathname;

  // Dynamically add and remove the script for Dollar-A-Day functionality
  useEffect(() => {
    const script = document.createElement("script");

    // Determine the script source based on the environment (production or QA)
    if (process.env.REACT_APP_PRODUCTION === "true") {
      script.src =
        "https://ark.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    } else {
      script.src =
        "https://qabackend.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    }
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const elRef = useRef(null);

  const [playing, setPlaying] = useState(false);

  return (
    <section
      className={styles.donateDollarWrapper}
      style={{ backgroundImage: `url(` + bgDAD + `)` }}
    >
      <Container>
        <figure className={styles.supportQuotes}>
          <img src={imgSupport} alt="Support our Mission" />
        </figure>
        <div className={styles.contentCenter}>
          <div className={styles.header_wrap}>
            <figure>
              <Link to={"/"}>
                <img src={img_sw_logo} alt="Shalom World" />
              </Link>
            </figure>
            <div className={styles.headerTagline}>
              <p>
                Entertainment that <br /> Heals and Transforms
              </p>
            </div>
          </div>
          <div className={`${styles.video_wrap} mediaCenter`}>
            <DonateDollarADayVideo
              playing={playing}
              setPlaying={setPlaying}
              pathName={pathName}
            />
          </div>
          <div
            className={styles.contentWrap}
            onClick={() => {
              setPlaying(false);
            }}
          >
            <h2>
              SHALOM WORLD <br />
              NEEDS YOUR SUPPORT
            </h2>

            <DonateDollarADayButton pathName={pathName} />
            <DonateDollarADayContent pathName={pathName} />

          </div>

          <DonateDollarADayForm pathName={pathName} ref={elRef} />

        </div>

        <div className={styles.footerWrap}>
          <p>
            Shalom World is an initiative of Shalom Media, a non-profit
            organization. All donations are tax-deductible.
          </p>
        </div>

      </Container>
    </section>
  );
};

export default DonateDollarADay;
