/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
export const useLatestEpisodes = props => {
    const { fetchCategoryEpisode, fetchLatestEpisode, latestEpisodes, category, path, page, country } = props
    //resetLatestData

    useEffect(() => {

        if (path === '/') {
            if (!latestEpisodes?.latestEpisodes || latestEpisodes?.latestEpisodes?.length === 0)
                fetchLatestEpisode(country)
        } else {
            let cat = category;
            let pag = page;
            if (!page) {
                pag = 1;
            }
            if (path === '/episodes') {
                cat = 'popular';

            }
            fetchCategoryEpisode(cat, pag, country);
        }
    }, [category, page])

    // useEffect(() => {

    //     if (path === '/' || path === '/episodes') {
    //         //Do nothing
    //         // if (latestEpisodes?.length === 0)
    //         //     fetchLatestEpisode(country)
    //     } else {
    //         let cat = category;
    //         let pag = page;
    //         if (!page) {
    //             pag = 1;
    //         }
    //         if (path === '/episodes') {
    //             cat = 'popular';
    //         }
    //         resetLatestData()

    //         fetchCategoryEpisode(cat, pag, country);
    //     }

    // }, [country])

}