/* eslint-disable import/no-anonymous-default-export */
import { FETCH_FOOTER, FETCH_FOOTEREIN } from "../actions/types.js";

const INTIAL_STATE = { footer: "", footerEinNo: "" };

export default (state = INTIAL_STATE, action) => {

  switch (action.type) {
    case FETCH_FOOTER:
      return {
        ...state,
        footer: action.payload,
      };
    case FETCH_FOOTEREIN:
      return {
        ...state,
        footerEinNo: action.payload,
      };
    default:
      return state;
  }
};
