/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  fetchProgramDetails,
  fetchLatestVideo,
  fetchRelatedVideo,
} from "../../../actions/programeAction";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Style from "./MobileShowDetails.module.scss";
import Row from "react-bootstrap/Row";
import ProgramThumb from "../../ProgramThumb";
import EpisodeThumbMobile from "../../Mobile/EpisodeThumb";
import _ from "lodash";
import { useTransition } from "../../../logic/PageTransition/useTransition";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InfiniteScroll from "react-infinite-scroller";
import { useSeasonal } from "../../../logic/Seasonal";
import CommonLoader from "../../Shimmers/CommonLoader";
import ShowAbout from "../ShowAbout";
import LoadMoreLoader from "../../Shimmers/LoadMoreLoader";
import Paginate from "../../../util/Paginate";

const MobileShowDetails = ({
  fetchProgramDetails,
  programes,
  fetchLatestVideo,
  fetchRelatedVideo,
}) => {
  const listingRef = useRef(null);

  // Getting the showId, speakerId, categoryId from the URL parameters
  let { showId, speakerId, categoryId } = useParams();

  // Access to the browser history object
  let history = useHistory();

  // Access to the current location object
  const { hash } = useLocation();

  // State variables
  const [programDetails, setProgramDetails] = useState([]);
  const [stickyMenu1, setStickyMenu1] = useState(false);
  const [nonStickyMenu1, setNonStickyMenu1] = useState(false);
  const [buttomTab, setButtomTab] = useState(4);
  const [seasonSeleted, setSeasonSeleted] = useState();
  const [page, setPage] = useState({ latest: 1, related: 1 });
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [programDetailsRd, setProgramDetailsRd] = useState();
  const { classes } = useSeasonal();
  const [hasMoreLatestEpisode, setHasMoreLatestEpisode] = useState(true);
  const [hasMoreRelatedEdpisode, setHasMoreRelatedEdpisode] = useState(true);
  const [hasMoreRelatedShow, setHasMoreRelatedShow] = useState(true);
  const [isShimmer, setisShimmer] = useState(false);
  const [speaker, setSpeaker] = useState({ value: "Speakers", ky: "" }); // Selected speaker-To be used for filter dropdown
  const [topic, setTopic] = useState({ value: "Categories", ky: "" }); // Selected top To be used for filter dropdown
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 9,
    perPage: 9,
  });

  // Dynamically add and remove the script for Dollar-A-Day functionality
  useEffect(() => {
    const script = document.createElement("script");

    // Determine the script source based on the environment (production or QA)
    if (process.env.REACT_APP_PRODUCTION === "true") {
      script.src =
        "https://ark.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    } else {
      script.src =
        "https://qabackend.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    }
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Load items based on the pagination state
  useEffect(() => {
    if (pagination && pagination.page !== 0) {
      loadItems();
    }
  }, [pagination]);

  // Excecutes pagination scroll to Top and increment page number
  const onPagination = (pageNumber) => {
    executeScroll();
    setPagination({
      ...pagination,
      page: pageNumber,
    });
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  // Redirect to the selected season if the URL has changed
  useEffect(() => {
    if (seasonSeleted?.url && seasonSeleted.url !== showId) {
      history.push("/show/" + seasonSeleted.url);
    }
  }, [seasonSeleted]);

  /*
        //setButtomTab(1)  | Related Show    | programDetails.childPrograms
        //setButtomTab(2)  | Related Episode | programDetails.relatedEpisodes
        //setButtomTab(3)  | About           | programDetails.description
        //setButtomTab(4)  | Latest Episode  | programDetails.latestEpisodes
        //setButtomTab(5)  | Testimonial     | programDetails.testimonial
  */

  // Fetch program details when the showId changes
  useEffect(() => {
    setisShimmer(true);

    fetchProgramDetails(showId, speakerId, categoryId)
      .then((value) => {
        if (value.status === "show_deleted") {
          history.push(`/show-not-found`);
        } else {
          setisShimmer(false);
          setProgramDetails(value);
          if (value.seasonlist && value.seasonlist.length > 0) {
            _.find(value.seasonlist, (o) => {
              if (o.url === showId) {
                setSeasonSeleted(o);
                return o;
              }
            });
          }

          if (value.childPrograms?.length >= 1) {
            setButtomTab(1); // Go to Related Shows tab
          } else {
            setButtomTab(4); // Go to Latest Episode
          }

          setSpeaker({
            value: value.speakers[speakerId] || "Speakers",
            ky: speakerId,
          });
          setTopic({
            value: value.topics[categoryId] || "Categories",
            ky: categoryId,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showId]);

  useEffect(() => {
    let flag = 0;
    _.map(programes, (a) => {
      if (flag === 0) {
        _.find(a?.data, (o) => {
          if (o.url === showId) {
            flag = 1;
            setProgramDetailsRd(o);
            return o;
          }
        });
      }
    });
  }, [showId]);

  // Set active tab 'Related Shows' if series title is '9 PM Series' . Not included in naive app
  useEffect(() => {
    if (programDetails.title === "9 PM Series") {
      setButtomTab(1);
    }
    if (
      programDetails?.latestEpisodes?.status &&
      programDetails.latestEpisodes.status === "error"
    ) {
      setHasMoreLatestEpisode(0);
    }
  }, [programDetails]);

  useEffect(() => {
    if (hash && hash === "#promo") {
      if (programDetails?.promoVideo?.length > 0) {
        const timer = setTimeout(() => {
          setButtomTab(3);
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [programDetails, hash]);

  const handleRouting = (speaker, topic) => {
    const newPath = `/show/${showId}/${speaker?.ky || "all"}/${topic?.ky || "all"
      }`;
    history.push(newPath);
  };

  // Filter latest episodes based on Speaker && Topic dropdown
  const handleFilterLatestEpisodes = (speaker, topic) => {
    let pageNo = pagination.page;
    let tags = [];
    if (programDetails.tagsIds != null) {
      tags = programDetails.tagsIds + "";
    }
    setisShimmer(true);

    fetchLatestVideo(
      programDetails.id,
      programDetails.programId,
      programDetails.tagsIds != null ? tags : "",
      pageNo,
      pagination.limit,
      speaker,
      topic
    ).then(async (res) => {
      if (res.status !== "error") {
        setTimeout(() => {
          setisShimmer(false);
        }, 1000);
        await setProgramDetails({
          ...programDetails,
          latestEpisodes: {
            ...programDetails.latestEpisodes,
            currentPage: pageNo,
            episodes: res.episodes,
            total: res?.total,
            pages: res?.pages,
            relatedPages: res?.relatedPages,
            relatedTotal: res?.relatedTotal,
          },
        });
      } else {
        setIsLoading(false);
        setisShimmer(false);

        await setProgramDetails({
          ...programDetails,
          latestEpisodes: {
            ...programDetails.latestEpisodes,
            currentPage: pageNo,
            episodes: [],
            total: res?.total,
            pages: res?.pages,
            relatedPages: res?.relatedPages,
            relatedTotal: res?.relatedTotal,
          },
        });
      }
    });
  };

  //setButtomTab(1)  | Related Show    | programDetails.childPrograms
  //setButtomTab(2)  | Related Episode | programDetails.relatedEpisodes
  //setButtomTab(3)  | About           | programDetails.description
  //setButtomTab(4)  | Latest Episode  | programDetails.latestEpisodes

  const loadItems = () => {
    if (buttomTab === 4) {
      let pageNo = pagination.page;
      let tags = [];
      if (programDetails.tagsIds != null) {
        tags = programDetails.tagsIds + "";
      }
      setisShimmer(true);
      fetchLatestVideo(
        programDetails.id,
        programDetails.programId,
        programDetails.tagsIds != null ? tags : "",
        pageNo
      ).then(async (res) => {
        if (res.status !== "error") {
          setTimeout(() => {
            setisShimmer(false);
          }, 1000);
          await setProgramDetails({
            ...programDetails,
            latestEpisodes: {
              ...programDetails.latestEpisodes,
              currentPage: pageNo,
              episodes: res.episodes,
            },
          });
        } else {
          setIsLoading(false);
          setisShimmer(false);
        }
      });
    } else if (buttomTab === 2) {
      if (programDetails.relatedEpisodes.pages >= page.related) {
        let pageNo = page.related + 1;
        let tags = [];

        if (!isLoading && pageNo !== 1) {
          setIsLoading(true);
          if (programDetails.tagsIds != null) {
            tags = programDetails.tagsIds + "";
          }

          fetchRelatedVideo(
            programDetails.id,
            programDetails.programId,
            programDetails.tagsIds != null ? tags : "",
            pageNo
          ).then(async (res) => {
            if (res.status !== "error") {
              setTimeout(() => {
                setIsLoading(false);
                setHasMoreRelatedEdpisode(true);
              }, 1000);
              if (res.episodes.length >= 1) {
                await setProgramDetails({
                  ...programDetails,
                  relatedEpisodes: {
                    ...programDetails.relatedEpisodes,
                    episodes: [
                      ...programDetails.relatedEpisodes.episodes,
                      ...res.episodes
                        .filter(function (cv, index) {
                          return !programDetails.relatedEpisodes.episodes.find(
                            function (e) {
                              return e.id === cv.id;
                            }
                          );
                        })
                        .slice(0, 6),
                    ],
                    currentPage: pageNo,
                  },
                });
                setPage({ ...page, related: pageNo });
              }
            } else {
              setIsLoading(false);
              setHasMoreRelatedEdpisode(false);
            }
          });
        } else {
        }
      }
    } else if (buttomTab === 1) {
      if (programDetails?.childPrograms?.pages >= page.related) {
        let pageNo = page.related + 1;

        if (!isLoading && pageNo !== 1) {
          setIsLoading(true);
          let tags = [];

          if (programDetails.tagsIds != null) {
            tags = programDetails.tagsIds + "";
          }

          fetchRelatedVideo(
            programDetails.id,
            programDetails.programId,
            programDetails.tagsIds != null ? tags : "",
            pageNo
          ).then(async (res) => {
            if (res.status !== "error") {
              setTimeout(() => {
                setIsLoading(false);
                setHasMoreRelatedShow(true);
              }, 1000);

              await setProgramDetails({
                ...programDetails,
                childPrograms: {
                  ...programDetails.childPrograms,
                  episodes: [
                    ...programDetails.childPrograms.episodes,
                    ...res.episodes,
                  ],
                  currentPage: pageNo,
                },
              });
              setPage({ ...page, related: pageNo });
            } else {
              setHasMoreRelatedShow(false);
              setIsLoading(false);
            }
          });
        } else {
        }
      }
    }
  };

  const handleScroll = (e) => {
    if (window.pageYOffset > 900) {
      setStickyMenu1(true);
    } else {
      setStickyMenu1(false);
    }
    if (window.pageYOffset < 890) setNonStickyMenu1(true);
    else setNonStickyMenu1(false);
  };

  const support = () => {
    history.push(`/donate`);

    //window.location.href = `https://shalomworld.my-shalom.org/sw/donation?CauseName=Shalom%20World%27s%20program%20production/${showId}`;
    // window.location.href = `https://sit-shalomworld.my-shalom.org/sw/donation?CauseName=Shalom%20World%27s%20program%20production/${showId}`;
  };

  const seasonSelect = (value) => {
    setSeasonSeleted(value);
    if (seasonSeleted.url != value.url) setProgramDetails(value);
  };

  const executeScroll = () =>
    listingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const loader = (
    <div className={""}>
      <LoadMoreLoader />
    </div>
  );

  return (
    <div className={Style.MobileShowDetails} ref={listingRef}>
      {programDetails.bannerImg != null ? (
        <>
          <figure
            className={`${Style.banner} ${navigator.userAgent === "shalom_world_app_mobile"
              ? "mob-app-mar-0"
              : "no-mob-app"
              }`}
          >
            <img src={programDetails.bannerImg} alt="Banner" />
          </figure>

          <div className={Style.episodesHeader}>
            <ul>
              {programDetails.childPrograms?.length > 0 ? (
                <li
                  className={
                    buttomTab === 1 && `${Style.active} ${classes?.txtColor}`
                  }
                  onClick={() => {
                    setButtomTab(1);
                    setIsLoadMore(true);
                    setPage({ latest: 1, related: 1 });
                    setIsLoading(false);
                    setPagination({
                      ...pagination,
                      page: 1,
                    });
                  }}
                >
                  Related Show
                </li>
              ) : null}

              {programDetails.title !== "9 PM Series" ? (
                <li
                  className={
                    buttomTab === 4 && `${Style.active} ${classes?.txtColor}`
                  }
                  onClick={() => {
                    setButtomTab(4);
                    setIsLoadMore(true);
                    setPage({ latest: 1, related: 1 });
                    setIsLoading(false);
                    setPagination({
                      ...pagination,
                      page: 1,
                    });
                  }}
                >
                  Latest Episode
                </li>
              ) : null}

              {programDetails.relatedEpisodes?.episodes?.length > 0 && (
                <li
                  className={
                    buttomTab === 2 && `${Style.active} ${classes?.txtColor}`
                  }
                  onClick={() => {
                    setButtomTab(2);
                    setIsLoadMore(true);
                    setPage({ latest: 1, related: 1 });
                    setIsLoading(false);
                    setPagination({
                      ...pagination,
                      page: 1,
                    });
                  }}
                >
                  Related Episode
                </li>
              )}

              <li
                className={
                  buttomTab === 3 && `${Style.active} ${classes?.txtColor}`
                }
                onClick={() => {
                  setButtomTab(3);
                  setIsLoadMore(true);
                  setIsLoading(false);
                  setPage({ latest: 1, related: 1 });
                  setPagination({
                    ...pagination,
                    page: 1,
                  });
                }}
              >
                About
              </li>
              {programDetails.testimonials?.length > 0 && (
                <li
                  className={
                    buttomTab === 5 && `${Style.active} ${classes?.txtColor}`
                  }
                  onClick={() => {
                    setButtomTab(5);
                    setIsLoadMore(true);
                    setPage({ latest: 1, related: 1 });
                    setIsLoading(false);
                    setPagination({
                      ...pagination,
                      page: 1,
                    });
                  }}
                >
                  Testimonial
                </li>
              )}
            </ul>

            {/* Seasons && Sticky Speaker && Topic dropdown starts here. To be shown for Latest Episodes tab ONLY*/}
            {buttomTab === 4 && (
              <>
                {!stickyMenu1 && programDetails.seasonlist?.length > 1 && (
                  <div
                    className={`${Style.seasonDropdown} ${classes.ddlColor}`}
                  >
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        seasonSeleted?.name
                          ? seasonSeleted.name
                          : programDetails.seasonlist[0].name
                      }
                      className={`${Style.seasonButton} custom-drop-down`}
                    >
                      {programDetails.seasonlist?.map((value) => {
                        return (
                          <Dropdown.Item
                            // href={value.slug}
                            onClick={() => {
                              seasonSelect(value);
                            }}
                          >
                            {value.name}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                )}

                <div
                  className={`${Style.ddlFilterWrap} ${classes.ddlColorOnly} filter_wrap d-flex align-items-center justify-content-between`}
                >
                  {programDetails?.speakers &&
                    Object.entries(programDetails?.speakers).length && (
                      <>
                        {/* Speaker Dropdown */}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            {speaker?.value}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setSpeaker({ value: "All", ky: "" });
                                handleFilterLatestEpisodes(
                                  { value: "All", ky: "" },
                                  topic
                                );
                                handleRouting({ value: "All", ky: "" }, topic);
                              }}
                            >
                              All{" "}
                            </Dropdown.Item>

                            {programDetails?.speakers !== undefined &&
                              Object.entries(programDetails?.speakers)?.map(
                                ([ky, speaker]) => {
                                  return (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setSpeaker({
                                            value: speaker,
                                            ky: ky,
                                          });
                                          handleFilterLatestEpisodes(
                                            { value: speaker, ky: ky },
                                            topic
                                          );
                                          handleRouting(
                                            { value: speaker, ky: ky },
                                            topic
                                          );
                                        }}
                                      >
                                        {speaker}
                                      </Dropdown.Item>
                                    </>
                                  );
                                }
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    )}

                  {programDetails?.topics &&
                    Object.entries(programDetails?.topics).length && (
                      <>
                        {/* Topic Dropdown */}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            {topic?.value}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setTopic({ value: "All", ky: "" });
                                handleFilterLatestEpisodes(speaker, "");
                                handleRouting(speaker, "");
                              }}
                            >
                              All{" "}
                            </Dropdown.Item>

                            {programDetails?.topics !== undefined &&
                              Object.entries(programDetails?.topics)?.map(
                                ([ky, topic]) => {
                                  return (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setTopic({ value: topic, ky: ky });
                                          handleFilterLatestEpisodes(speaker, {
                                            value: speaker,
                                            ky: ky,
                                          });
                                          handleRouting(speaker, {
                                            value: speaker,
                                            ky: ky,
                                          });
                                        }}
                                      >
                                        {topic}
                                      </Dropdown.Item>
                                    </>
                                  );
                                }
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    )}
                </div>
              </>
            )}

            {/* Sticky Speaker && Topic dropdown ends here*/}

            <div className={Style.EpisodeLists}>
              {/* Tab 1 Related Show  */}
              {buttomTab === 1 && (
                <>
                  {programDetails?.childPrograms?.length !== 0 ? (
                    <>
                      <InfiniteScroll
                        pageStart={1}
                        loadMore={loadItems}
                        hasMore={hasMoreRelatedShow}
                      >
                        <Row>
                          {programDetails?.childPrograms?.map((value) => {
                            return <ProgramThumb value={value} />;
                          })}
                        </Row>
                      </InfiniteScroll>
                      <div className="mt-1 d-none">&nbsp;</div>
                    </>
                  ) : (
                    // Length is zero
                    <p>Episodes Coming Soon</p>
                  )}
                </>
              )}

              {/* Tab 2 Related   */}

              {buttomTab === 2 && (
                <>
                  {programDetails?.relatedEpisodes?.episodes &&
                    programDetails?.relatedEpisodes?.episodes?.length !== 0 ? (
                    <>
                      <InfiniteScroll
                        pageStart={1}
                        loadMore={loadItems}
                        hasMore={hasMoreRelatedEdpisode}
                      >
                        {programDetails?.relatedEpisodes?.episodes.map(
                          (value) => {
                            return <EpisodeThumbMobile value={value} />;
                          }
                        )}
                      </InfiniteScroll>
                      <div className="mt-1 d-none">&nbsp;</div>
                    </>
                  ) : (
                    // Length is zero
                    <p>Episodes Coming Soon</p>
                  )}
                </>
              )}

              {/* Tab 3 Related Show  */}

              {buttomTab === 3 && <ShowAbout value={programDetails} />}

              {buttomTab === 4 && (
                <>
                  {programDetails?.latestEpisodes?.episodes &&
                    programDetails?.latestEpisodes?.episodes?.length !== 0 ? (
                    <>
                      {programDetails?.latestEpisodes?.episodes.map((value) => {
                        return <EpisodeThumbMobile value={value} />;
                      })}
                      {isShimmer && loader}
                      <Paginate
                        totalPages={programDetails?.latestEpisodes.pages}
                        onPagination={onPagination}
                        currentPage={pagination.page ? pagination.page : 1}
                        pageNumberLimit={3}
                      />
                    </>
                  ) : (
                    // Length is zero
                    <p>Episodes Coming Soon</p>
                  )}
                </>
              )}

              {buttomTab === 5 && programDetails ? (
                <div className={Style.testimonialSection}>
                  {programDetails.testimonials?.length > 0 &&
                    programDetails.testimonials?.map((value) => {
                      return (
                        <div className={Style.testimonial_list_each}>
                          <div className={Style.testimonial_list_desc}>
                            <p>{value.testimonial}</p>
                            <div
                              className={Style.testimonial_list_auth_details}
                            >

                              <h5>{value.name} </h5>
                              <h6>{value.country} </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : null}

            </div>
          </div>
        </>
      ) : (
        <CommonLoader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { programes: state.programes };
};

export default connect(mapStateToProps, {
  fetchProgramDetails,
  fetchLatestVideo,
  fetchRelatedVideo,
})(MobileShowDetails);
