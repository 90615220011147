import React from 'react'
import { useHome } from '../logic/Home/useHome'
// import MediaGallery from '../components/MediaGallery'

import { connect } from 'react-redux'
import { fetchOtherdetails } from '../actions/otherdetailsAction'
import { TitleComponent } from '../components/TitleComponent'
import { useSeo } from '../logic/Seo/useSeo'
import NewSwPluse from '../components/NewSwPluse'
import FeedbackLayout from '../components/Layouts/FeedbackLayout'

const Media = ({ fetchOtherdetails, otherdetails }) => {
  useHome({ fetchOtherdetails, otherdetails })
  useSeo()
  return (
    <FeedbackLayout>
      <TitleComponent title="Shalom world " />
      <NewSwPluse />
      {/* <MediaGallery /> */}
    </FeedbackLayout>
  )
}
const mapStateToProps = (state) => {
  return { otherdetails: state.otherdetails.data }
}
export default connect(mapStateToProps, { fetchOtherdetails })(Media)
