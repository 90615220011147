import React from "react";
import styles from "./DonateDollarADayButton.module.scss";

const DonateDollarADayContent = ({ pathName, setPlaying, }) => {
  return (
    <>
      {pathName === "/dollar-a-day-ca" ? (
        // Donate Btn for CA
        <div className={`${styles.btn_wrap} section-region-ca`}>
          <a href="#XZLPTVXN" className={`btn ${styles.donate_btn}`}>
            Donate
          </a>
        </div>
      ) : pathName === "/dollar-a-day-uk" ? (
        // Donate Btn for UK
        <div className={`${styles.btn_wrap} section-region-uk`}>
          <a href="#XFUVNJUP" className={`btn ${styles.donate_btn}`}>
            Donate
          </a>
        </div>

      ) : pathName === "/dollar-a-day-eu" ? (
        // Donate Btn for Europe
        <div className={`${styles.btn_wrap} section-region-eu`}>
          <a href="#XSDAXBUK" className={`btn ${styles.donate_btn}`}>
            Donate
          </a>
        </div>
      ) : pathName === "/dollar-a-day-ch" ? (
        // Donate Btn for Europe
        <div className={`${styles.btn_wrap} section-region-ch`}>
          <a href="#XGDGFUAT" className={`btn ${styles.donate_btn}`}>
            Donate
          </a>
        </div>

      ) : (
        //ELSE any other path Donate Btn for US, MX
        <div className={`${styles.btn_wrap} section-region-us`}>
          <a href="#XMKYELEL" className={`btn ${styles.donate_btn}`}>
            Donate
          </a>
        </div>
      )}
    </>
  );
};

export default DonateDollarADayContent;
