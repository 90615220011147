import {FETCH_CATEGORIES} from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_CATEGORIES:
            return action.payload;
        default:
            return state;
    }
}