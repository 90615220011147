import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from './NewsThumb.module.scss';
import { Link } from "react-router-dom";

const NewsThumb = ({value}) => {
    return(
        <div className={styles.newsThumb}>
            <Link to={'/news/'+value.blogUrl}>
                <Row>
                    <Col xs={5}><img src={value.blogImageThumb}/></Col>
                    <Col>
                            <div className={styles.title}>{value.blogTitle}</div>
                            <div className={styles.date}>{value.createdDate}</div>
                            <div className={styles.author}>
                                <img src={value.authorImage} alt={value.authorName}/>
                                <div className={styles.name}>{value.authorName}</div>
                            </div>
                    </Col>
                </Row>
            </Link>

        </div>
    )
}

export default NewsThumb;