import React from "react";
import styles from './ShowListShimmer.module.scss';

const ShowListShimmer = () => {
  return (
    <div className={`${styles.showsListShimmer} col-lg-3 col-md-4 col-sm-6`}>
         <div className={`${styles.shimmerImg} Shimmer `}></div>

      </div>
  );
};

export default ShowListShimmer;
