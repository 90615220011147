import React from 'react'
import Container from 'react-bootstrap/Container'
import FeedbackLayout from '../components/Layouts/FeedbackLayout'
import { useSeasonal } from '../logic/Seasonal'
import SocialMediaWrap from '../components/SocailMediaIcons'
import { useDimensions } from '../logic/Dimentions'

const ErrorPage = () => {
  const { classes } = useSeasonal()
  const { width } = useDimensions()

  return (
    <>
      <FeedbackLayout>
        <Container>
          {width >= 992 && <SocialMediaWrap classes={classes} />}
          <div className="pagenotfoundWrap">
            <div className={`notFoundText ${classes?.txtColor}`}>
              <span>404</span> page not found
            </div>
          </div>
        </Container>
      </FeedbackLayout>
    </>
  )
}

export default ErrorPage
