import React, { useState, useRef } from "react";
import { Formik, Field, ErrorMessage, useFormik } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import styles from "./TestimonyRequest.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import ThanksforSubmission from "../ThanksforSubmission";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GoogleRecaptcha from "../../components/Recaptcha";
import salomapi from "../../apis";
import { useSeasonal } from '../../logic/Seasonal';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "white",
    },
  },
}));

const TestimonyRequest = ({
  countryList,
  CountryInputChange,
  stateList,
  CountryChange,
  countryName,
  countryInputName,
}) => {
  const [state, setState] = useState("");
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [imageData, setImageData] = useState({
    photo: "",
    selectedFileName: "Upload photo",
    isinvalidphotoFormat: false,
    isfileSixeBig: false,
  });
  const [publishData, setPublishData] = useState({
    publish: 1,
    isshareTestimonyChecked: true,
  });
  const classes = useStyles();
  const [captchaReset, setCaptchaReset] = useState(false);
  const captcha = useRef(null);
  const [captchaValid, isCaptchaValid] = useState(false);
  const [validUser, isValidUser] = useState(false);
  const seasonal = useSeasonal();

  const StateChange = (event, newValue) => {
    setState(newValue);
    // setValue(newValue);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (countryInputName == "") {
      errors.country = "Required";
    }
    if (!values.massPetitionDesc) {
      errors.massPetitionDesc = "Required";
    }
    if (!values.address) {
      errors.address = "Required";
    }
    if (!values.city) {
      errors.city = "Required";
    }
    if (!values.postCode) {
      errors.postCode = "Required";
    }
    if (!state) {
      errors.state = "Required";
    }
    if (!captchaValid) {
      errors.captchaValid = "Please valid the Captcha";
    }
    if (!publishData.isshareTestimonyChecked) ;

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      postCode: "",
      country: "",
      photo: "",
      massPetitionDesc: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        firstname: values.firstName,
        lastname: values.lastName,
        address: values.address,
        email: values.email,
        city: values.city,
        state: state,
        zipcode: values.postCode,
        country: countryInputName,
        photo: imageData.photo,
        testimony: values.massPetitionDesc,
        testimony_permission: publishData.publish,
      };
      if (postData.photo === "" || postData.photo === undefined) {
        var dataNew = new FormData();
        dataNew.append("firstname", values.firstname);
        dataNew.append("lastname", values.lastname);
        dataNew.append("address", values.address);
        dataNew.append("email", values.email);
        dataNew.append("city", values.city);
        dataNew.append("state", state);
        dataNew.append("zipcode", values.postCode);
        dataNew.append("country", countryInputName);
        dataNew.append("testimony", values.massPetitionDesc);
        dataNew.append("testimony_permission", publishData.publish);
      } else {
        var dataNew = new FormData();
        dataNew.append("firstname", values.firstname);
        dataNew.append("lastname", values.lastname);
        dataNew.append("address", values.address);
        dataNew.append("email", values.email);
        dataNew.append("city", values.city);
        dataNew.append("state", state);
        dataNew.append("zipcode", values.postCode);
        dataNew.append("country", countryInputName);
        dataNew.append("testimony", values.massPetitionDesc);
        dataNew.append("photo", imageData.photo);
        dataNew.append("testimony_permission", publishData.publish);
      }
      if (captchaValid) {
        salomapi
          .post("/swprayer/testimonyrequest", dataNew)
          .then((response) => {
            setFormSuccess(true);
            resetForm({});
            setCaptchaReset(true);
            isCaptchaValid(false);
            setState("");
            CountryInputChange("", "");
            setImageData({
              photo: "",
              selectedFileName: "Upload photo",
              isinvalidphotoFormat: false,
              isfileSixeBig: false,
            });

            setTimeout(() => {
              setFormSuccess(false);
              setCaptchaReset(false);
            }, 4000);
          });
      }
      if (captcha.current.getValue()) {

        isValidUser(true);
        isCaptchaValid(true);
      } else {

        isValidUser(false);
        isCaptchaValid(false);
      }
      //  alert(JSON.stringify(values, null, 2));
    },
  });
  const handleChangePhotoUpload = (event) => {
    if (event.target.files[0]) {
      let selectedFile = event.target.files[0];
      let splitarr = selectedFile.name.split(".");
      if (
        splitarr.includes("jpeg") ||
        splitarr.includes("png") ||
        splitarr.includes("gif") ||
        splitarr.includes("PNG") ||
        splitarr.includes("JPG")
      ) {
        let selectedPhoto = event.target.value;
        selectedPhoto = selectedPhoto.replace(/C:\\fakepath\\/i, "");
        setImageData((prevState) => ({
          ...prevState,
          photo: selectedFile,
          selectedFileName: selectedPhoto,
          isinvalidphotoFormat: false,
        }));
        // this.setState({ photo: selectedFile, selectedFileName: selectedPhoto,isinvalidphotoFormat:false })
      } else {
        setImageData((prevState) => ({
          ...prevState,
          photo: "",
          selectedFileName: "",
          isinvalidphotoFormat: true,
        }));
        // this.setState({ photo: "", selectedFileName: "",isinvalidphotoFormat:true })
      }

      if (selectedFile.size > 5e6) {
        setImageData({
          photo: "",
          selectedFileName: "",
          isinvalidphotoFormat: false,
          isfileSixeBig: true,
        });
        // this.setState({
        //   photo: "",
        //   selectedFileName: "",
        //   isinvalidphotoFormat:false,
        //   isfileSixeBig: true
        //  })
      } else {
        setImageData((prevState) => ({
          ...prevState,
          photo: selectedFile,
          isfileSixeBig: false,
        }));
        //   this.setState({
        //     photo: selectedFile,
        //     isfileSixeBig: false })
        // }
      }
    }
  };

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      setPublishData({ publish: 1, isshareTestimonyChecked: true });
    } else {
      setPublishData({ publish: 0, isshareTestimonyChecked: false });
    }
  };

  const testimonyDescChange = (val) => {
    // let len = formik.values.massPetitionDesc.length
    if (val.target.value.length <= 250) formik.handleChange(val);

    // setCharCount(val.target.value.length)
  };
  const alertClose = () => {
    setFormSuccess(false);
    window.scrollTo(0, 1150);
  };
  const handleOnChange = () => {
    isCaptchaValid(true);
  };
  return (
    <div className={styles.TestimonyRequest}>
      <Container>
        {/* <h4>Mass description goes here</h4> */}
        <p className={styles.testimony_para}>
          <i>
            “I will praise you to all my brothers; I will stand up before the
            congregation and testify of the wonderful things you have done.”
            (Psalm 22:22){" "}
          </i>
        </p>
        <p className={styles.testimony_para}>
          Share testimonies of answered prayer, SW PRAYER’s impact on your life
          or that of a loved one. Your personal stories testify to the power of
          God in your life and bless all those who read them.
        </p>
        {/* <Formik

initialValues={{ email: '', password: '' }}
validate={values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }

  return errors;

}}

onSubmit={(values, { setSubmitting }) => {

  setTimeout(() => {

    // alert(JSON.stringify(values, null, 2));

    setSubmitting(false);

  }, 400);

}}

>

{({ isSubmitting }) => ( */}

        {/* // <Form>

  //   <Field type="email" name="email" />

  //   <ErrorMessage name="email" component="div" />

  //   <Field type="password" name="password" />

  //   <ErrorMessage name="password" component="div" />

  //   <button type="submit" disabled={isSubmitting}>

  //     Submit

  //   </button>

  // </Form> */}

        <Form className={styles.form} onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <input
                placeholder="First name *"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                name="firstName"
                className={
                  !formik.errors.firstName || !formik.submitCount
                    ? styles.input
                    : styles.input + " " + styles.formErrReact
                }
              ></input>
            </Col>
            <Col>
              <input
                placeholder="Last name *"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                name="lastName"
                className={
                  !formik.errors.lastName || !formik.submitCount
                    ? styles.input
                    : styles.input + " " + styles.formErrReact
                }
              ></input>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                placeholder="Email *"
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                className={
                  !formik.errors.email || !formik.submitCount
                    ? styles.input
                    : styles.input + " " + styles.formErrReact
                }
              ></input>
            </Col>
            <Col>
              <Autocomplete
                id="combo-box-demo"
                classes={classes}
                options={countryList}
                getOptionLabel={(option) => option.country_name}
                renderOption={(option) => (
                  <div style={{ color: "white" }}>{option.country_name}</div>
                )}
                style={{ width: 404 }}
                inputValue={countryInputName}
                onInputChange={CountryInputChange}
                onChange={CountryChange}
                renderInput={(params) => (
                  <TextField
                    className={
                      !formik.errors.country || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                    {...params}
                    placeholder="Select Country"
                    name="country"
                    variant="outlined"
                  />
                )}
              />
              {/* <input placeholder="Select Country *" className={styles.input}></input> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                placeholder="Address"
                onChange={formik.handleChange}
                value={formik.values.address}
                name="address"
                className={
                  !formik.errors.address || !formik.submitCount
                    ? styles.input
                    : styles.input + " " + styles.formErrReact
                }
              ></input>
            </Col>
            <Col>
              <input
                placeholder="City"
                onChange={formik.handleChange}
                value={formik.values.city}
                name="city"
                className={
                  !formik.errors.city || !formik.submitCount
                    ? styles.input
                    : styles.input + " " + styles.formErrReact
                }
              ></input>
            </Col>
          </Row>
          <Row>
            <Col>
              <Autocomplete
                id="combo-box-demo"
                classes={classes}
                options={stateList.length > 0 ? stateList : countryList}
                getOptionLabel={(option) =>
                  stateList.length > 0 ? option.name : option.country_name
                }
                renderOption={(option) => (
                  <div style={{ color: "white" }}>
                    {stateList.length > 0 ? option.name : option.country_name}
                  </div>
                )}
                style={{ width: 404 }}
                inputValue={state}
                onInputChange={StateChange}
                renderInput={(params) => (
                  <TextField
                    className={
                      !formik.errors.state || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                    {...params}
                    placeholder="Select State/ Province"
                    variant="outlined"
                  />
                )}
              />
              {/* <input placeholder="Select state / Province" className={styles.input}></input> */}
            </Col>
            <Col>
              <input
                placeholder="Postal Code"
                onChange={formik.handleChange}
                value={formik.values.postCode}
                name="postCode"
                className={
                  !formik.errors.postCode || !formik.submitCount
                    ? styles.input
                    : styles.input + " " + styles.formErrReact
                }
              ></input>
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea
                placeholder="Testimony *"
                onChange={testimonyDescChange}
                value={formik.values.massPetitionDesc}
                className={
                  !formik.errors.massPetitionDesc || !formik.submitCount
                    ? styles.textarea
                    : styles.textarea + " " + styles.formErrReact
                }
                name="massPetitionDesc"
              ></textarea>
              <p className={styles.textarea_para}>
                {" "}
                characters{" "}
                {formik.values.massPetitionDesc
                  ? formik.values.massPetitionDesc.length
                  : 0}{" "}
                / 250
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type="file"
                name="photo"
                accept=".png, .jpg, .PNG, .JPG"
                id="apply-photo"
                onChange={handleChangePhotoUpload}
                placeholder="Upload photo"
                className={styles.uploadbtn}
              ></input>
              <label htmlFor="apply-photo">
                <h6 className={styles.photoLbl}>
                  {imageData.selectedFileName}
                </h6>
              </label>
            </Col>
          </Row>
          <Row className="col-lg-12 chckbx-wrp">
            <input
              type="checkbox"
              id="chkbx2"
              checked={publishData.isshareTestimonyChecked}
              onChange={handleChangeCheckbox}
              className={styles.chkbox}
            />
            <label className={styles.label_txt} for="chkbx2">
              I give permission to publish the photo and testimony in the
              website
            </label>
          </Row>
          {Object.keys(formik.errors).length != 0 && formik.submitCount ? (
            <div className="form-group col-sm-12">
              <span className={styles.formtotNullfieldErr}>
                Please fill all required fields.
              </span>
            </div>
          ) : null}
          {formik.errors.email && formik.submitCount ? (
            <div className="form-group col-sm-12">
              <span className={styles.formtotNullfieldErr}>
                Please Enter a valid Email Id
              </span>
            </div>
          ) : null}

          {imageData.isinvalidphotoFormat ? (
            <div className="form-group col-sm-12">
              <span className={styles.formtotNullfieldErr}>
                Please upload valid photo format
              </span>
            </div>
          ) : null}
          {imageData.isfileSixeBig == true ? (
            <div className="form-group col-sm-12">
              <span className={styles.formtotNullfieldErr}>
                File too Big, please select a file less than 5mb
              </span>
            </div>
          ) : null}

          <Row className={styles.div_fields}>
            <GoogleRecaptcha
              onChange={handleOnChange}
              isCaptchaReset={captchaReset}
            />{" "}
          </Row>
          <Row>
            <Col>
              <button className={`${styles.prayer_request_btn} ${seasonal?.classes?.fullSchduleBtn}`}>SUBMIT</button>
            </Col>
          </Row>
        </Form>

        {/* // )}

// </Formik> */}
        {isFormSuccess ? <ThanksforSubmission alertClose={alertClose} /> : null}
      </Container>
    </div>
  );
};

export default TestimonyRequest;
