import React from 'react'
import AmazonSmile from '../components/PeacefellowshipIneer/AmazonSmile'
import SocialMediaWrap from '../components/SocailMediaIcons'
import { useDimensions } from '../logic/Dimentions'
import { useSeasonal } from '../logic/Seasonal'

const AmazonSmilePage = () => {
  const { width } = useDimensions()
  const { classes } = useSeasonal()

  return (
    <>
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <AmazonSmile />
    </>
  )
}

export default AmazonSmilePage
