import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useEventPastVideos = (props) => {
    const { fetchEventPastVideos } = props;

    let { eventId } = useParams();

    useEffect(() => {
        if (eventId) {
            fetchEventPastVideos(eventId);
        }
    }, [eventId, fetchEventPastVideos]);
};
