import React,{useState} from "react";
import styles from './StepsToShop.module.scss';
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useSeasonal } from '../../../logic/Seasonal';

const StepsToShop = ({
  heading,
  description,
  buttonTxt,
  buttonUrl,
  target,
  step,
  datas,
  content
}) => {

  const [dataDisplay,setDataDisplay] = useState(0);
  const {seasonal,classes} = useSeasonal();

  const {location} = useHistory();
  let path = location.pathname;
  return (
    <div className={`row ${styles.stepstoshop}`}>
      <div className={`col-lg-2 ${styles.steplist}`}>
        <div className={styles.stepContent}>
        <span className={classes?.txtColor}>Step</span>
        {step}
        </div>
        
      </div>
      <div className={`col-lg-10 ${styles.stepRight}`}>
      <div className={`${styles.description} ${classes?.contentLinkColor}`}>{ReactHtmlParser(description)}</div>
      {path=='/employermatching' ? 
        step == "01" ? (<iframe src="https://ww2.matchinggifts.com/shalomworld_iframe" className={styles.iframe} ></iframe>) :  (<p>{content}</p>):
        buttonUrl&&<a href={buttonUrl} target={target} className={`${styles.btn_link} ${classes?.fullSchduleBtn} ${classes?.txtColor}`}>
        {buttonTxt}
      </a>} 
      {datas &&
      <div className={styles.dataTab}>
      <ul>
        {datas.map((item,index) => {
          return(
            <li onClick={()=>{setDataDisplay(index)}} className={`${index==dataDisplay&&styles.active} ${classes?.seasonalClass}`}>
            <h5>
                <img src={item.icon} />
                {item.browser}
              </h5>
          </li>
          )
        })}
        </ul>
        {datas.map((item,index) => {
          return (
              <div style={{display:dataDisplay==index?'block':'none'}} className={styles.dataContent}>
                <p>{item.content}</p>
                <img src={item.image} />
              </div>
          );
        })}
        </div>
        }
    </div>
    </div>
  );
};
export default StepsToShop;
