/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './Donation.module.scss'
import iconPhone from '../../assets/img/iconChannelPhone.svg'
import iconMail from '../../assets/img/iconChannelMail.svg'
import { Container, Row, Col } from 'react-bootstrap'
import { fetchDonation } from '../../actions/donationAction'
import BrowserPersistence from '../../util/simplePersistance'
import ReactPlayer from 'react-player/lazy'
import { useSeasonal } from '../../logic/Seasonal'
import CommonLoader from '../Shimmers/CommonLoader'
import DonateDollarADayBanner from '../DonateDollarADayBanner';

/*
  *Author: [Author Name]
  *Description: This component renders a Donation section with different variations based on the user's country.
  *It displays donation information, contact details, and a donation banner or video.
  * !!!! IMPORTANT: Use 'country' for US,CA,MX && 'region' for EU,AU, UK !!!!
  * CRITICAL: URGENT ATTENTION REQUIRED! STRICTLY COMPLY with the variable usage for country selection!
  * use countries US, CA, MX, AND region UK, EU, AU Only.
*/

// Create an instance of BrowserPersistence for storing and retrieving data
const storage = new BrowserPersistence()

const Donation = ({ fetchDonation, donationData, seasonals }) => {

  // Retrieve the seasonal classes
  const { classes } = useSeasonal()
  // State for tracking scroll position

  useEffect(() => {
    // Fetch donation data if it hasn't been fetched yet
    if (donationData.length === 0) fetchDonation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDonation])
  useEffect(() => { }, [classes])

  const region = storage.getItem('CountryCode')
    ? storage.getItem('CountryCode')
    : 'us'

  const descriptionContent = (str) => {
    // Split the string by line breaks and wrap each line in a <p> tag
    if (str && str !== '') {
      return str.split('\n').map((str) => <p>{str}</p>)
    }
  }

  const handlePlaying = () => {
    // Add 'isplaying' class to body when the video is playing
    document.body.classList.add('isplaying')
  }

  const handleStop = () => {
    // Remove 'isplaying' class from body when the video is stopped
    document.body.classList.remove('isplaying')
  }

  const handleScroll = () => {
    if (document.body.className.match('isplaying')) {
      document.body.classList.remove('isplaying')
    }
  }

  useEffect(() => {

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll, { passive: true })
    // Clean up by removing the scroll event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const includedRegions = ['AU', 'CA', 'US', 'UK', 'EU', 'AA'];

  return (
    <>

      {/* Region Now{(donationData?.region)?.toUpperCase()} Country Now{donationData?.country} */}

      {/* Render the `DonateDollarADayBanner` component for specific countries US, CA, MX, AND region UK, EU, AU, AA Only*/}

      {!includedRegions.includes(donationData?.region?.toUpperCase()) ?
        <>
          {/* Render donation content for Australia. Does not include Fund Raise form */}
          {donationData?.data?.length !== 0 &&
            donationData?.data?.desc_title != '' ? (
            <div className={styles.donationWrapper}>
              {donationData?.data && donationData?.data?.banner_type === 'video' ? (
                donationData?.data?.banner && (
                  <div
                    className={`${styles.donation_banner} ${styles.video_banner} mediaCenter wide`}
                  >
                    {/* Render video banner */}

                    <ReactPlayer
                      url={donationData?.data?.banner}
                      width="100%"
                      height="100%"
                      light={donationData?.data?.placeholder_image}
                      // muted={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: 'nodownload noplaybackrate',
                            disablePictureInPicture: true,
                          },
                        },
                      }}
                      playing={true}
                      controls={true}
                      onStart={handlePlaying}
                      onPause={handleStop}
                      onPlay={handlePlaying}
                    />
                  </div>
                )
              ) : (
                <div className={`${styles.donation_banner} mediaCenter wide`}>
                  <img
                    src={donationData?.data?.banner}
                    alt="Donate Channel Banner"
                  />

                </div>
              )}

              <div className={styles.donate_channel_content}>
                <Container>
                  <Row>
                    <Col xs={12} lg={8} className={styles.channel_content}>
                      <figure className={styles.channel_logo}>
                        <img
                          src={donationData?.data?.desc_logo}
                          alt="Channel Logo"
                        />
                      </figure>
                      <div className={styles.content_wrap}>
                        {donationData?.data?.desc_title && <h1
                          className={`${styles.is_title} ${classes?.seasonalClass}`}
                        >
                          {donationData?.data?.desc_title}
                        </h1>}

                        <article>
                          <p>
                            {descriptionContent(donationData?.data?.desc_content)}
                          </p>
                        </article>
                      </div>
                    </Col>
                    <Col xs={12} lg={4} className={styles.monthy_donation}>
                      <div className={styles.content_wrap}>
                        <figure>
                          <img
                            src={donationData?.data?.desc_img}
                            alt="monthly donation"
                          />
                        </figure>
                        <p>{donationData?.data?.desc_img_content}</p>

                        {donationData?.data?.button.title && <a
                          style={{
                            backgroundColor: seasonals.color,
                            color: '#fff',
                          }}
                          href={donationData?.data?.button?.url}
                          target="_blank"
                          rel="noreferrer"
                          className={`${styles.btn_donate} ${classes?.backgroundColr}`}
                        >
                          {donationData?.data?.button.title}
                        </a>}


                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className={styles.donate_channel_loc_wrap}>
                <Container>
                  {donationData?.data?.card_section_title && <h2
                    className={`${styles.is_title} ${styles.center} ${classes?.seasonalClass}`}
                  >
                    {donationData?.data?.card_section_title}
                  </h2>}

                  <Row>
                    {donationData?.data &&
                      donationData?.data?.cards.length > 0 &&
                      donationData?.data?.cards.map((value, key) => {
                        return (
                          <Col xs={12} lg={6} className={styles.channel_box}>
                            <figure className="figImg">
                              <img src={value.image} alt="Channel Donation" />
                            </figure>
                            <div className={styles.content_wrap}>
                              <h4>{value.title}</h4>
                              <p> {value.content}</p>

                              {value?.link?.title && <a
                                style={{
                                  backgroundColor: seasonals.color,
                                  color: '#fff',
                                }}
                                href={value.link.url}
                                target="_blank"
                                rel="noreferrer"
                                className={`${styles.btn_donate} ${classes?.backgroundColr}`}
                              >
                                {value.link.title}
                              </a>}

                            </div>
                          </Col>
                        )
                      })}
                  </Row>
                </Container>
              </div>
            </div>
          ) : (
            <div className={styles.pageLoader}>
              <CommonLoader />
            </div>
          )}
        </>
        :
        <>
          {/* Render this section as common component for ALL countries  */}
          <DonateDollarADayBanner donationData={donationData} />

          {/* Render contact address section for US, CA, and MX Only*/}
          {((donationData?.country)?.toUpperCase() === 'US' || (donationData?.country)?.toUpperCase() === 'CA' || (donationData?.country)?.toUpperCase() === 'MX') &&
            <div className={styles.contact_address_wrap}>
              <Container>
                <Row>
                  <Col
                    xs={12}
                    lg={6}
                    className={`${styles.address_box} ${styles.mail}`}
                  >
                    {donationData?.mail?.heading && <figure>
                      <img src={iconMail} alt="icon mail" />
                    </figure>}


                    <h4>{donationData?.mail?.heading}</h4>
                    <address>{donationData?.mail?.address}</address>

                    {donationData?.mail?.btnTitle && <a
                      href={donationData?.mail?.btnUrl}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.btn_learnmore}
                    >
                      {donationData?.mail?.btnTitle}
                    </a>}


                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className={`${styles.address_box} ${styles.phone}`}
                  >
                    {donationData?.phone?.heading && <figure>
                      <img src={iconPhone} alt="icon phone" />
                    </figure>}


                    <h4>{donationData?.phone?.heading}</h4>
                    <address>

                      {donationData?.phone?.btnTitle && <p>
                        <strong>
                          {donationData?.phone?.btnTitle} : {' '}
                          <a href={'tel:' + donationData?.phone?.btnUrl}>
                            {donationData?.phone?.btnUrl}
                          </a>
                        </strong>
                      </p>}

                      {donationData?.phone?.address}
                    </address>
                  </Col>
                </Row>
              </Container>
            </div>
          }
        </>}


    </>
  )
}

const mapPropsToState = (state) => {
  return {
    donationData: state.donationData.data,
    seasonals: state.seasonal,
  }
}
export default connect(mapPropsToState, { fetchDonation })(Donation)
