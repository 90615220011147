import React from 'react'
import CommonLayout from '../components/Layouts/CommonLayout'
import Promotion from '../components/Promotion'
import { TitleComponent } from '../components/TitleComponent'
import { useSeo } from '../logic/Seo/useSeo'
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from '../logic/Seasonal'

const PromotionPage = () => {
  useSeo()
  const { width } = useDimensions();
  const { classes } = useSeasonal()

  return (
    <CommonLayout>
      <TitleComponent title="Shalom world " />
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <Promotion />
    </CommonLayout>
  )
}
// const mapStateToProps = (state) => {
//   return { otherdetails: state.otherdetails.data }
// }
export default PromotionPage
