import React from 'react';
import EmployeeMatching from '../components/PeacefellowshipIneer/EmployeeMatching';
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from "../logic/Seasonal";

const EmployeeMatchingPage = () => {
    const { width } = useDimensions();
    const { classes } = useSeasonal();
    return (
        <>
         {width >= 992 && <SocialMediaWrap classes={classes} />}
        <EmployeeMatching />
        </>

    )
}

export default EmployeeMatchingPage;