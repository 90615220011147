import React, { useState, useEffect, lazy, useCallback, useRef } from "react";
import { Formik, Field, ErrorMessage, useFormik } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GoogleRecaptcha from "../../components/Recaptcha";
import ThanksforStorySubmission from "../ThanksforStorySubmission";
import {
  fetchCountryList,
  fetchStateFromCountry,
} from "../../actions/swprayerAction";
import { connect } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import styles from "./StoryForm.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import imgUpload from "../../assets/img/icon-upload.svg";
import salomapi from "../../apis";

import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "white",
    },
  },
}));
const StoryForm = ({
  fetchCountryList,
  fetchStateFromCountry,
  countryList,

  stateList,
  xmasData,
}) => {
  const classes = useStyles();
  const [state, setState] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryInputName, setCountryInputName] = useState("");
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [imgChanged, setImgChanged] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [imageData, setImageData] = useState({
    file: "",
    selectedFileName: "",
    isinvalidphotoFormat: false,
    isfileSixeBig: false,
  });
  const [publishData, setPublishData] = useState({
    publish: 0,
    isshareTestimonyChecked: false,
  });

  const [captchaReset, setCaptchaReset] = useState(false);
  const captcha = useRef(null);
  const [captchaValid, isCaptchaValid] = useState(false);
  const [validUser, isValidUser] = useState(false);
  useEffect(() => {
    // if(fellowship.length ==0)
    fetchCountryList();
  }, []);
  const CountryChange = useCallback((event, newValue) => {
    if (newValue) {
      fetchStateFromCountry(newValue.id);
    }
    setState("");
    formik.setFieldValue('city', '')
    // formik.setFieldValue('address','')
  }, []);
  const CountryInputChange = useCallback((event, newInputValue) => {
    setCountryInputName(newInputValue);
  }, []);

  const StateChange = (event, newValue) => {
    setState(newValue);
    setCity("");
    // setValue(newValue);
  };
  const validate = (values) => {
    const errors = {};
    const urlregex = new RegExp(`/((http|https)://)(www.)?” 
   + “[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]” 
   + “{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`);

    if (!values.firstname) {
      errors.firstname = "Required";
      errors.msg = "Enter first name";
    } else if (values.firstname.length > 15) {
      errors.firstname = "Must be 15 characters or less";
      errors.msg = "Must be 15 characters or less";
    }

    if (!values.lastname) {
      errors.lastname = "Required";
      errors.msg = "Please enter your lastname";
    } else if (values.lastname.length > 20) {
      errors.lastname = "Must be 20 characters or less";
      errors.msg = "Must be 20 characters or less";
    }
    if (values.phone.toString().length > 0) {

      if (values.phone.toString().length < 7 || values.phone.toString().length > 15 || isNaN(values.phone)) {
        errors.phone = "Enter valid phone number";
        errors.msg = "Enter valid phone number";
      }
    }


    if (!values.email) {
      errors.email = "Required";
      errors.msg = "Please enter your email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
      errors.msg = "Please enter valid email";
    }
    if (countryInputName == "") {
      errors.country = "Required";
      errors.msg = " Please enter your country";
    }

    if (!values.address) {
      errors.address = "Required";
      errors.msg = " Please enter your address";
    }
    if (!values.city) {
      errors.city = "Required";
      errors.msg = " Please enter your city";
    }

    if (!values.videolink && !imageData.file) {
      errors.attachments = "Required";
      errors.msg = "Please add an attachment";
    }

    if (
      !imageData.file &&
      values.videolink &&
      !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(
        values.videolink
      )
    ) {
      errors.videolink = "Required";
      errors.msg = "Invalid Url";
    }

    //     if( imageData.file &&   imageData.isinvalidphotoFormat){
    //       errors.msg="Please upload valid attachment format"
    //     }
    //     if(  imageData.isfileSixeBig  ){
    // errors.msg = " File too Big, for the files larger than 20 MB, upload it to a drive and share the link with us."
    //     }

    // if (!imageData.file &&
    //   !/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/i.test(
    //     values.videolink
    //   )
    // ) {
    //   errors.videolink = "Invalid Link";
    // }
    // if (!values.phone) {
    //   errors.msg = "Please enter your phone number";
    // }
    if (!state) {
      errors.state = "Required";
      errors.msg = "Please enter your state";
    }
    if (!captchaValid) {
      errors.captchaValid = "Please valid the Captcha";
      errors.msg = "Please validate captcha";
    }
    if (!publishData.isshareTestimonyChecked) {
      errors.publish = "Required";
      errors.msg = "Please agree the terms and conditions";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      address: "",
      city: "",
      state: "",
      phone: "",
      country: "",
      file: "",
      videolink: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        firstname: values.firstname,
        lastname: values.lastname,
        address: values.address,
        email: values.email,
        city: values.city,
        state: state,
        phone: values.phone,
        country: countryInputName,
        file: imageData.file,
        videolink: values.videolink,
        // testimony: values.massPetitionDesc,
        disclaimer: publishData.publish,
      };
      if (postData.file === "" || postData.file === undefined) {
        var dataNew = new FormData();
        dataNew.append("firstname", values.firstname);
        dataNew.append("lastname", values.lastname);
        dataNew.append("address", values.address);
        dataNew.append("email", values.email);
        dataNew.append("city", values.city);
        dataNew.append("state", state);
        dataNew.append("phone", values.phone);
        dataNew.append("country", countryInputName);
        dataNew.append("videolink", values.videolink);
        // dataNew.append("testimony", values.massPetitionDesc);
        dataNew.append("disclaimer", publishData.publish);
      } else if (
        postData.videolink === "" ||
        postData.videolink === undefined
      ) {
        var dataNew = new FormData();
        dataNew.append("firstname", values.firstname);
        dataNew.append("lastname", values.lastname);
        dataNew.append("address", values.address);
        dataNew.append("email", values.email);
        dataNew.append("city", values.city);
        dataNew.append("state", state);
        dataNew.append("phone", values.phone);
        dataNew.append("country", countryInputName);
        dataNew.append("file", imageData.file);
        // dataNew.append("testimony", values.massPetitionDesc);
        dataNew.append("disclaimer", publishData.publish);
      } else {
        var dataNew = new FormData();
        dataNew.append("firstname", values.firstname);
        dataNew.append("lastname", values.lastname);
        dataNew.append("address", values.address);
        dataNew.append("email", values.email);
        dataNew.append("city", values.city);
        dataNew.append("state", state);
        dataNew.append("phone", values.phone);
        dataNew.append("country", countryInputName);
        dataNew.append("videolink", values.videolink);
        // dataNew.append("testimony", values.massPetitionDesc);
        dataNew.append("file", imageData.file);
        dataNew.append("disclaimer", publishData.publish);
      }
      setLoading(true);
      if (captchaValid) {
        salomapi.post("/christmasstoryform", dataNew).then((response) => {
          if (response.data.status === "success") {
            setFormSuccess(true);
            resetForm({});
            setCaptchaReset(true);
            isCaptchaValid(false);
            setState("");
            setPublishData({ isshareTestimonyChecked: false, publish: 0 });
            CountryInputChange("", "");
            setError("");
            setLoading(false);
            setImgChanged(false);
            setImageData({
              file: "",
              selectedFileName: "",
              isinvalidphotoFormat: false,
              isfileSixeBig: false,
            });
          } else {
            setFormSuccess(false);
            setError(response.data.msg);
          }

          setTimeout(() => {
            setFormSuccess(false);
            setCaptchaReset(false);
          }, 5000);
        });
      }
      if (captcha.current.getValue()) {
        isValidUser(true);
        isCaptchaValid(true);
      } else {
        isValidUser(false);
        isCaptchaValid(false);
      }
      // alert(JSON.stringify(values, null, 2));
    },
  });
  const handleChangePhotoUpload = (file) => {
    const types = file.type.split("/");
    let fileObj = { file };

    if (types.includes("jpeg") ||
      types.includes("png") ||
      types.includes("gif") ||
      types.includes("PNG") ||
      types.includes("JPG") ||
      types.includes("MP3") ||
      types.includes("MP4") ||
      types.includes("mp3") ||
      types.includes("mp4") ||
      types.includes("avi") ||
      types.includes("3gp") ||
      types.includes("mov") ||
      types.includes("mpeg") ||
      types.includes("wav") ||
      types.includes("wma") ||
      types.includes("aac") ||
      types.includes("gif'") ||
      types.includes("txt") ||
      types.includes("pdf")) {
      if (file.size > 2e7) {
        fileObj = {
          file: "",
          selectedFileName: "",
          isinvalidphotoFormat: false,
          isfileSixeBig: true
        };
      }
      else {
        fileObj = {
          file,
          selectedFileName: file.name,
          isinvalidphotoFormat: false,
          isfileSixeBig: false
        };
      }

    } else {
      fileObj = {
        file: "",
        selectedFileName: "",
        isinvalidphotoFormat: true,
        isfileSixeBig: false
      };
    }
    setImageData(fileObj);



  };

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      setPublishData({ publish: 1, isshareTestimonyChecked: true });
    } else {
      setPublishData({ publish: 0, isshareTestimonyChecked: false });
    }
  };

  // const testimonyDescChange = (val) => {
  // // let len = formik.values.massPetitionDesc.length
  // if (val.target.value.length <= 250) formik.handleChange(val);

  // // setCharCount(val.target.value.length)
  // };
  const alertClose = () => {
    setFormSuccess(false);
    window.scrollTo(0, 1150);
  };
  const handleOnChange = () => {
    isCaptchaValid(true);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      handleChangePhotoUpload(acceptedFiles[0]);
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div
      id="your-story"
      className={styles.StoryWrapper}
      style={{ backgroundImage: `url(${xmasData?.storyFormBackground})` }}
    >
      <Container>
        {/* <img src={xmasData?.storyFormBackground}></img>  */}
        <Row>
          <Col xs={12} md={5}>
            <div className={styles.storyFormContent}>
              <h2>{xmasData?.storyFormTitle}</h2>
              <p>{xmasData?.storyFormDescription}</p>
            </div>
          </Col>
          <Col xs={12} md={7} className={styles.storyFormWrap}>
            <Form className={`${styles.form} storyForm`} onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12} lg={6}>
                  <input
                    placeholder="First name *"
                    onChange={formik.handleChange}
                    value={formik.values.firstname}
                    name="firstname"
                    className={
                      !formik.errors.firstname || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                  ></input>
                </Col>
                <Col xs={12} lg={6}>
                  <input
                    placeholder="Last name *"
                    onChange={formik.handleChange}
                    value={formik.values.lastname}
                    name="lastname"
                    className={
                      !formik.errors.lastname || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                  ></input>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <input
                    type="number"
                    placeholder="Phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    name="phone"
                    className={
                      !formik.errors.phone || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                  ></input>
                </Col>
                <Col xs={12} lg={6}>
                  <input
                    placeholder="Email *"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    name="email"
                    className={
                      !formik.errors.email || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                  ></input>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <input
                    placeholder="Address *"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                    name="address"
                    className={
                      !formik.errors.address || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                  ></input>
                </Col>
                <Col xs={12} lg={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    classes={classes}
                    options={countryList}
                    getOptionLabel={(option) => option.country_name}
                    renderOption={(option) => (
                      <div style={{ color: "white" }}>
                        {option.country_name}
                      </div>
                    )}
                    inputValue={countryInputName}
                    onInputChange={CountryInputChange}
                    onChange={CountryChange}
                    renderInput={(params) => (
                      <TextField
                        className={
                          !formik.errors.country || !formik.submitCount
                            ? styles.input
                            : styles.input + " " + styles.formErrReact
                        }
                        {...params}
                        placeholder="Select Country *"
                        name="country"
                        variant="outlined"
                      />
                    )}
                  />
                  {/* <input placeholder="Select Country *" className={styles.input}></input> */}
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    disabled={countryInputName === "" ? true : false}
                    classes={classes}
                    options={stateList.length > 0 ? stateList : countryList}
                    getOptionLabel={(option) =>
                      stateList.length > 0 ? option.name : option.country_name
                    }
                    renderOption={(option) => (
                      <div style={{ color: "white" }}>
                        {stateList.length > 0
                          ? option.name
                          : option.country_name}
                      </div>
                    )}
                    inputValue={state}
                    onInputChange={StateChange}
                    renderInput={(params) => (
                      <TextField
                        className={
                          !formik.errors.state || !formik.submitCount
                            ? styles.input
                            : styles.input + " " + styles.formErrReact
                        }
                        {...params}
                        placeholder="Select State/ Province *"
                        variant="outlined"
                      />
                    )}
                  />
                  {/* <input placeholder="Select state / Province" className={styles.input}></input> */}
                </Col>
                <Col xs={12} lg={6}>
                  <input
                    placeholder="City *"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    name="city"

                    className={
                      !formik.errors.city || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                  ></input>
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={6} className={styles.fileDropWrap}>
                  <h4>Attachment</h4>
                  {/* <label
                    htmlFor="apply-photo"
                    className={styles.drop_zone}
                    onChange={() => {
                      setadd(true);
                    }}
                  > */}
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input
                      type="file"
                      name="file"
                      id="apply-photo"
                      //  onChange={handleChangePhotoUpload}
                      placeholder=""
                      className={styles.uploadbtn}
                      {...getInputProps()}
                    />
                    {imageData.file === "" ? (
                      <div className="drop_content">
                        <figure>
                          <img src={imgUpload} />
                        </figure>
                        <p>
                          Drop your files here or <span>browse</span>
                          <br />{" "}
                          <em>Supports: PDF, JPEG, JPG, PNG, MP3, MP4, etc.</em>
                        </p>
                      </div>
                    ) : (
                      <h6
                        className={`${styles.photoLbl} ${imgChanged ? styles.show : null
                          }`}
                      >
                        {imageData.selectedFileName}
                      </h6>
                    )}
                  </div>
                  {/* </label> */}
                </Col>
                <Col xs={12} lg={6} className={styles.captchaWrap}>
                  <div className="chckbx-wrp">
                    <input
                      type="checkbox"
                      id="chkbx2"
                      checked={publishData.isshareTestimonyChecked}
                      onChange={handleChangeCheckbox}
                      className={styles.chkbox}
                    />
                    <label className={styles.label_txt} for="chkbx2">
                      I agree to have my story published on Shalom World TV and
                      social media platforms. I have read the{" "}
                      <a href="/termsandconditions">Terms and Conditions</a>.
                    </label>
                  </div>
                  <div className={styles.div_fields}>
                    <GoogleRecaptcha
                      onChange={handleOnChange}
                      isCaptchaReset={captchaReset}
                    />{" "}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={12}>
                  <input
                    placeholder="Story video link"
                    onChange={formik.handleChange}
                    value={formik.values.videolink}
                    name="videolink"
                    className={
                      !formik.errors.videolink || !formik.submitCount
                        ? styles.input
                        : styles.input + " " + styles.formErrReact
                    }
                  ></input>
                </Col>
              </Row>

              <Row>
                {/* <Col>
                <label
                    htmlFor="apply-photo"
                    className={styles.drop_zone}
                    onChange={() => {
                      setImgChanged(true);
                    }}
                  >
                  <div {...getRootProps()} style={{ background: "white" }}>
                    <input {...getInputProps()} />
                   
                      {imageData.file === "" ? (
                        <div className={styles.drop_content}>
                          <figure>
                            <img src={imgUpload} />
                          </figure>
                          {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    )}
                        </div>
                      ) : (
                        <h6
                          className={`${styles.photoLbl} ${
                            imgChanged ? styles.show : null
                          }`}
                        >
                          {imageData.selectedFileName}
                        </h6>
                      )}
                  </div>
                    </label>
                </Col> */}
              </Row>
              <Row>
                {formik.errors.msg && formik.submitCount ? (
                  <div className="form-group col-sm-12">
                    <span className={styles.formtotNullfieldErr}>
                      {formik.errors.msg}
                    </span>
                  </div>
                ) : null}

                {/* {Object.keys(formik.errors).length != 0 &&
                formik.submitCount ? (
                  formik.errors.email  ? (
                    <div className="form-group col-sm-12">
                      <span className={styles.formtotNullfieldErr}>
                        Please Enter a valid Email Id
                      </span>
                    </div>
                  ) : formik.errors.videolink ? (
                    <div className="form-group col-sm-12">
                      <span className={styles.formtotNullfieldErr}>
                        Please Enter a valid Video Url
                      </span>
                    </div>
                  ) : imageData.isinvalidphotoFormat ? (
                    <div className="form-group col-sm-12">
                      <span className={styles.formtotNullfieldErr}>
                        Please upload valid photo format
                      </span>
                    </div>
                  ) : imageData.isfileSixeBig == true ? (
                    <div className="form-group col-sm-12">
                      <span className={styles.formtotNullfieldErr}>
                        File too Big, for the files larger than 20 MB, upload it
                        to a drive and share the link with us.
                      </span>
                    </div>
                  ) : (
                    <div className="form-group col-sm-12">
                      <span className={styles.formtotNullfieldErr}>
                        Please fill all required fields.
                      </span>
                    </div>
                  )
                ) : null} */}

                {/* {formik.errors.email && formik.submitCount ? (
                  <div className="form-group col-sm-12">
                    <span className={styles.formtotNullfieldErr}>
                      Please Enter a valid Email Id
                    </span>
                  </div>
                ) : null}

                {formik.errors.videolink && formik.submitCount ? (
                  <div className="form-group col-sm-12">
                    <span className={styles.formtotNullfieldErr}>
                      Please Enter a valid Video Url
                    </span>
                  </div>
                ) : null}

          {Object.keys(formik.errors).length != 0 &&
                formik.submitCount ? (
                  <div className="form-group col-sm-12">
                    <span className={styles.formtotNullfieldErr}>
                      Please fill all required fields.
                    </span>
                  </div>
                ) : null}*/}

                {imageData.isinvalidphotoFormat ? (
                  <div className="form-group col-sm-12">
                    <span className={styles.formtotNullfieldErr}>
                      Please upload valid format attachments
                    </span>
                  </div>
                ) : null}
                {imageData.isfileSixeBig == true ? (
                  <div className="form-group col-sm-12">
                    <span className={styles.formtotNullfieldErr}>
                      File too Big, for the files larger than 20 MB, upload it
                      to a drive and share the link with us.
                    </span>
                  </div>
                ) : null}
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  {loading ? (
                    <button
                      className={`${styles.prayer_request_btn} ${styles.uploading}`}
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="danger"
                      />
                      Uploading...
                    </button>
                  ) : (
                    <button
                      className={styles.prayer_request_btn}
                      onClick={() =>
                        document
                          .getElementById("altertMsg")
                          .scrollIntoView({ block: "center" })
                      }
                    >
                      SUBMIT
                    </button>
                  )}
                </Col>
              </Row>
            </Form>

            {/* // )}
 
// </Formik> */}

            <div id="altertMsg" className={styles.alertMsgWrapper}>
              {isFormSuccess ? (
                <ThanksforStorySubmission alertClose={alertClose} />
              ) : null}
            </div>
            <div></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countryList: state.swprayer.countryList,
    stateList: state.swprayer.stateList,
  };
};
export default connect(mapStateToProps, {
  fetchCountryList,
  fetchStateFromCountry,
})(StoryForm);
