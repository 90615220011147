/* eslint-disable import/no-anonymous-default-export */
import { FETCH_CAREERS, FETCH_MORE_JOBS, FETCH_CAREER_DETAILS, CLEAR_CAREERS } from '../actions/types';

const INTIAL_STATE = {
    careers: [],
    moreJobs: [],
    maxPage: 1,
    page: 1
};

export default (state = INTIAL_STATE, action) => {

    switch (action.type) {
        case FETCH_CAREERS:
            return {
                ...state,
                careers: action.payload,
                maxPage: action.maxPage,
            };
        case FETCH_MORE_JOBS:
            return {
                ...state,
                moreJobs: state.moreJobs.concat(action.payload),
                page: action.page
            }
        case FETCH_CAREER_DETAILS:
            return {
                ...state,
                careerDetails: action.payload
            }
        case CLEAR_CAREERS:
            return {
                ...state,
                careers: [],
                moreJobs: [],
                maxPage: 1,
                page: 1

            }
        default:
            return state;
    }
}