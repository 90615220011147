import {FETCH_OTHER_DETAILS} from '../actions/types';

const INTIAL_STATE = {data:[]};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_OTHER_DETAILS:
            return {...state,data:action.payload};
        default:
            return state;
    }
}