import { useState,useEffect } from 'react';
export const useSwprayerLiveVideo = props => { 
    const {fetchToken,getMassSteamUri,region} = props
    const [watchUrl,setWatchUrl] = useState('')

    useEffect(()=>{
        
        fetchToken().then((value)=>{
            getMassSteamUri(value,region).then((value2)=>{
                setWatchUrl(`${value2.url}?token=${value.secure_token}&timestamp=${value.timestamp}&clientId=${value.client_id}&region=${region}`)
            })
        })
    },[region])
    return {
        watchUrl
    }
}