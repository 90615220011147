import React, { lazy } from "react";
import "./CommonStyle.scss";
import { useDimensions } from "../../logic/Dimentions";
const Menu = lazy(() => import("../Menu"));

const Subscription = lazy(() => import("../Subscription"));
const Footer = lazy(() => import("../Footer"));
const MobileMenu = lazy(() => import("../MobileMenu"));
const BottomTab = lazy(() => import("../BottomTab"));

const FeedbackLayout = ({ children }) => {
    const { width } = useDimensions();
    return (
        <>
            {width >= 1025 ? <Menu /> : <MobileMenu />}

            <div>{children}</div>

            {width >= 1025 ? (
                <>
                    <Subscription />
                    <Footer />
                </>
            ) : (
                <BottomTab />
            )}
        </>
    );
};

export default FeedbackLayout;
