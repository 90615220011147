import React, { lazy, Suspense } from 'react';
import Row from 'react-bootstrap/Row';
import EpisodeListShimmer from '../Shimmers/EpisodeListShimmer/EpisodeListShimmer';
import _ from 'lodash';
const Episodethumb = lazy(() => import('../EpisodeThumb'));

const EpisodeList = ({ data, status }) => {
    return (
        <>
            {status === 'loading' ? <Row>{_.times(9, () => <EpisodeListShimmer />)} </Row> : <Row>
                {data && data.data?.length > 0 ? data.data.map((value, index) => {
                    return (<Suspense ><Episodethumb value={value} key={index} /></Suspense>)
                }) : _.times(9, () => <EpisodeListShimmer />)}
            </Row>}
        </>
    )
}

export default EpisodeList;
