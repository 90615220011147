import React, { useEffect, useState, useCallback, useRef } from "react";
import Container from "react-bootstrap/Container";
import { Form, Row, Col } from "react-bootstrap";
import { fetchSwprayerDetails } from "../../actions/swprayerAction";
import {
  fetchCountryList,
  fetchStateFromCountry,
  fetchTimeFromDate,
} from "../../actions/swprayerAction";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import "react-responsive-modal/styles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GoogleRecaptcha from "../../components/Recaptcha";
import TextField from "@material-ui/core/TextField";
import Modal from "react-responsive-modal";
import styles from "./Publication.module.scss";
import { useFormik } from "formik";
import salomapi from "../../apis";
import thank2 from "../../assets/img/thankyou1.png";
import { values } from "lodash";
import { useSeasonal } from "../../logic/Seasonal";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "white",
    },
  },
}));

const Publication = ({
  fetchSwprayerDetails,
  fetchCountryList,
  fetchStateFromCountry,
  swprayerDetails,
  countryList,
  stateList,
  themeClasses,
}) => {
  const [state, setState] = useState("");
  const [captchaReset, setCaptchaReset] = useState(false);
  const captcha = useRef(null);
  const [captchaValid, isCaptchaValid] = useState(false);
  const [validUser, isValidUser] = useState(false);
  const [countryInputName, setCountryInputName] = useState("");
  const [isPromoPopupOpen, setPromoPopupOpen] = useState(false);
  const [isFormSuccess, setFormSuccess] = useState(false);
  const seasonal = useSeasonal();

  useEffect(() => {
    if (swprayerDetails == 0) {
      fetchSwprayerDetails();
    }
    fetchCountryList();
  }, []);
  const StateChange = (event, newInputValue) => {
    setState(newInputValue);
    // setValue(newValue);
  };
  const CountryChange = useCallback((event, newValue) => {
    if (newValue) {
      fetchStateFromCountry(newValue.id);
    }
  }, []);
  const CountryInputChange = useCallback((event, newInputValue) => {
    setCountryInputName(newInputValue);
  }, []);
  const onOpenModal = () => {
    setPromoPopupOpen(true);
  };
  const thankModalClose = () => {
    setPromoPopupOpen(false);
    setFormSuccess(false);
  };

  const redirectPage = (type) => {
    if (type == "Shalom Tidings") {
      window.location.href = `https://shalomtidings.org/`;
    } else {
      window.location.href = `https://shalomtidings.org/our-app/`;
    }
  };

  const classes = useStyles();
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (countryInputName == "") {
      errors.country = "Required";
    }
    if (!values.address) {
      errors.address = "Required";
    }
    if (!values.city) {
      errors.city = "Required";
    }
    if (!values.postCode) {
      errors.postCode = "Required";
    }
    if (!state) {
      errors.state = "Required";
    }
    if (!captchaValid) {
      errors.captchaValid = "Please valid the Captcha";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      lastName: "",
      address: "",
      city: "",
      postCode: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        firstname: values.firstName,
        lastname: values.lastName,
        address: values.address,
        email: values.email,
        city: values.city,
        state: state,
        zipcode: values.postCode,
        country: countryInputName,
      };
      if (captchaValid) {
        salomapi.post("/swprayer/subscribe", postData).then((response) => {
          setFormSuccess(true);
          resetForm({});
          setCaptchaReset(true);
          isCaptchaValid(false);
          setState("");
          CountryInputChange("", "");

          setTimeout(() => {
            setFormSuccess(false);
            setCaptchaReset(false);
          }, 4000);
        });
      }
      if (captcha.current.getValue()) {
        isValidUser(true);
        isCaptchaValid(true);
      } else {
        isValidUser(false);
        isCaptchaValid(false);
      }
    },
  });

  const handleOnChange = () => {
    isCaptchaValid(true);
  };
  return (
    <section className={styles.publication_container}>
      <Container>
        <Row className={styles.foot_feat}>
          {swprayerDetails?.other?.otherSection.map((value) => {
            return (
              <div className="col-sm-4">
                <span className="col-lg-3"></span>
                <span className="col-lg-6">
                  <img src={value.icon} />
                </span>
                <span className="col-lg-3"></span>
                <h3 className={styles.publicationH3}>{value.title}</h3>
                <p className={styles.publication_para}>{value.description}</p>
                <a
                  className={`${styles.publication_btn} ${seasonal?.classes?.fullSchduleBtn}`}
                  onClick={
                    value.title == "Shalom Messenger"
                      ? onOpenModal
                      : () => {
                          redirectPage(value.title);
                        }
                  }
                >
                  {value.title === "Shalom Prayer Book"
                    ? "DOWNLOAD"
                    : "SUBSCRIBE"}
                </a>
              </div>
            );
          })}
        </Row>
        <Modal
          open={isPromoPopupOpen}
          onClose={() => setPromoPopupOpen(false)}
          center
          classNames={styles.subscriptionModal}
          showCloseIcon={false}
        >
          <div className="modalWrapper">
            <div className={styles.subscriptionwrp}>
              <body className={styles.poupBackground}>
                <Container>
                  <Form
                    className={`row ${styles.subscribeForm}`}
                    onSubmit={formik.handleSubmit}
                  >
                    <Row>
                      <Col xs={12} sm={6}>
                        <input
                          placeholder="First name *"
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                          name="firstName"
                          className={
                            !formik.errors.firstName
                              ? styles.input
                              : styles.input + " " + styles.formErrReact
                          }
                        ></input>
                        {formik.errors.firstName ? (
                          <div>{formik.errors.firstName}</div>
                        ) : null}
                      </Col>
                      <Col xs={12} sm={6}>
                        <input
                          placeholder="Last name *"
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          name="lastName"
                          className={
                            !formik.errors.lastName
                              ? styles.input
                              : styles.input + " " + styles.formErrReact
                          }
                        ></input>
                        {formik.errors.lastName ? (
                          <div>{formik.errors.lastName}</div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6}>
                        <input
                          placeholder="Email *"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          name="email"
                          className={
                            !formik.errors.email
                              ? styles.input
                              : styles.input + " " + styles.formErrReact
                          }
                        ></input>
                        {formik.errors.email ? (
                          <div>{formik.errors.email}</div>
                        ) : null}
                      </Col>
                      <Col xs={12} sm={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          classes={classes}
                          options={countryList}
                          getOptionLabel={(option) => option.country_name}
                          renderOption={(option) => (
                            <div style={{ color: "white" }}>
                              {option.country_name}
                            </div>
                          )}
                          style={{ width: 404 }}
                          inputValue={countryInputName}
                          onInputChange={CountryInputChange}
                          onChange={CountryChange}
                          renderInput={(params) => (
                            <TextField
                              className={
                                !formik.errors.country
                                  ? styles.input
                                  : styles.input + " " + styles.formErrReact
                              }
                              {...params}
                              placeholder="Select Country"
                              name="country"
                              variant="outlined"
                            />
                          )}
                        />
                        {formik.errors.country ? (
                          <div>{formik.errors.country}</div>
                        ) : null}
                        {/* <input placeholder="Select Country *" className={styles.input}></input> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6}>
                        <input
                          placeholder="Address"
                          onChange={formik.handleChange}
                          value={formik.values.address}
                          name="address"
                          className={
                            !formik.errors.address
                              ? styles.input
                              : styles.input + " " + styles.formErrReact
                          }
                        ></input>
                        {formik.errors.address ? (
                          <div>{formik.errors.address}</div>
                        ) : null}
                      </Col>
                      <Col xs={12} sm={6}>
                        <input
                          placeholder="City"
                          onChange={formik.handleChange}
                          value={formik.values.city}
                          name="city"
                          className={
                            !formik.errors.city
                              ? styles.input
                              : styles.input + " " + styles.formErrReact
                          }
                        ></input>
                        {formik.errors.city ? (
                          <div>{formik.errors.city}</div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          classes={classes}
                          options={
                            stateList.length > 0 ? stateList : countryList
                          }
                          getOptionLabel={(option) =>
                            stateList.length > 0
                              ? option.name
                              : option.country_name
                          }
                          renderOption={(option) => (
                            <div style={{ color: "white" }}>
                              {stateList.length > 0
                                ? option.name
                                : option.country_name}
                            </div>
                          )}
                          style={{ width: 404 }}
                          inputValue={state}
                          onInputChange={StateChange}
                          renderInput={(params) => (
                            <TextField
                              className={
                                !formik.errors.state
                                  ? styles.input
                                  : styles.input + " " + styles.formErrReact
                              }
                              {...params}
                              placeholder="Select State/ Province"
                              variant="outlined"
                            />
                          )}
                        />
                        {/* <input placeholder="Select state / Province" className={styles.input}></input> */}
                      </Col>
                      <Col xs={12} sm={6}>
                        <input
                          placeholder="Postal Code"
                          onChange={formik.handleChange}
                          value={formik.values.postCode}
                          name="postCode"
                          className={
                            !formik.errors.postCode
                              ? styles.input
                              : styles.input + " " + styles.formErrReact
                          }
                        ></input>
                      </Col>
                    </Row>
                    <Row className={`col-lg-12 ${styles.div_fields}`}>
                      <GoogleRecaptcha
                        onChange={handleOnChange}
                        isCaptchaReset={captchaReset}
                      />
                    </Row>
                    <Row>
                      <Col xs={12} sm={6}>
                        <button
                          className={`${styles.mass_petition_btn} ${seasonal?.classes?.fullSchduleBtn}`}
                          disabled={formik.isSubmitting}
                          type="submit"
                        >
                          SUBMIT
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </body>
            </div>
          </div>
        </Modal>
        <Modal open={isFormSuccess} onClose={() => setFormSuccess(false)}>
          <img alt="" src={thank2} className="imgSubcribe" />
          <h3>Your subscription form has been successfully submitted!!!</h3>
          <button onClick={thankModalClose}> ok</button>
        </Modal>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    swprayerDetails: state.swprayerDetails,
    countryList: state.swprayer.countryList,
    stateList: state.swprayer.stateList,
  };
};

export default connect(mapStateToProps, {
  fetchSwprayerDetails,
  fetchCountryList,
  fetchStateFromCountry,
})(Publication);
