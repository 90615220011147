/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import { connect } from "react-redux";
import styles from "./CookiePolicy.module.scss";
import "rodal/lib/rodal.css";
import { fetchCookie } from "../../actions/cookieAction";
import BrowserPersistence from "../../util/simplePersistance";
import ReactHtmlParser from "react-html-parser";
import { useSeasonal } from "../../logic/Seasonal";
import { useLocation } from "react-router-dom";

const CookiePolicyPopup = ({ fetchCookie, cookieData, rregion }) => {

  const storage = new BrowserPersistence();
  const location = useLocation();
  let path = location.pathname;
  const [visible, setVisible] = useState(false);
  const { classes } = useSeasonal();

  useEffect(() => {
    const cookiePolicy = storage.getItem("cookiePolicyList");
    // eslint-disable-next-line eqeqeq
    let timer;
    if (
      cookiePolicy?.confirm != true &&
      path != "/dollar-a-day" &&
      path != "/donate" &&
      path != "/prisons"      
    ) {
      if (cookiePolicy?.count >= 1 && cookiePolicy?.count <= 9) {
        storage.setItem("cookiePolicyList", {
          ...cookiePolicy,
          count: cookiePolicy?.count + 1,
          ttl: new Date(),
          confirm: false,
        });
      } else if (localStorage?.count >= 10) {
        storage.setItem("cookiePolicyList", {
          ...cookiePolicy,
          count: 0,
          ttl: new Date(),
          confirm: false,
        });
      } else {
        storage.setItem("cookiePolicyList", {
          ...cookiePolicy,
          count: 1,
          ttl: new Date(),
          confirm: false,
        });
        timer = setTimeout(() => {
          setVisible(true);
        }, 4000);

      }
    } else {
      storage.setItem("cookiePolicyList", {
        ...cookiePolicy,
        count: 1,
        ttl: new Date(),
        confirm: true,
      });
    }

    return () => clearTimeout(timer);


  }, []);

  useEffect(() => {
    const cookiePolicyT = storage.getItem("cookiePolicyList");
    if (
      rregion[0] &&
      !cookieData?.content &&
      !cookiePolicyT?.confirm &&
      (cookiePolicyT?.count === 0 || cookiePolicyT?.count === 1)
    ) {
      fetchCookie(rregion[0]);
    }
  }, [rregion]);

  const hide = () => {
    setVisible(false);
    const cookiePolicy = localStorage.getItem("cookiePolicyList");
    storage.setItem("cookiePolicyList", {
      ...cookiePolicy,
      count: 1,
      ttl: new Date(),
      confirm: false,
    });
  };

  const accept = () => {
    setVisible(false);
    storage.setItem("cookiePolicyList", {
      count: 1,
      ttl: new Date(),
      confirm: true,
    });
  };




  return (
    <>
      <div className={styles.cookiePolicyModal}>
        {cookieData && cookieData?.content?.length >= 1 && rregion[0] && <>

          <Rodal
            visible={visible}
            onClose={hide}
            width="100%"
            className={`${styles.cookiePolicyWrap} ${classes?.cookiePolicy}`}

          >
            <div className={styles.cookieContentWrap}>
              {ReactHtmlParser(cookieData?.content)}
              <button
                className={`${styles.btn_accept_continue} ${classes?.backgroundColr}`}
                onClick={() => {
                  accept();
                }}
              >
                Accept & Continue
              </button>
            </div>
          </Rodal>
        </>}

      </div>
    </>
  );
};

const mapPropsToState = (state) => {
  return { cookieData: state.cookieData, rregion: state.region };
};
export default connect(mapPropsToState, { fetchCookie })(CookiePolicyPopup);
