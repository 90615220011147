/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export const usePodCast = (props) => {
    const { fetchPodcast, programId } = props;

    useEffect(() => {
        fetchPodcast({ slug: programId, seasonId: "" });
    }, []);
};
