import { FETCH_BANNER, FETCH_MOBBANNER } from '../actions/types.js';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {

        case FETCH_MOBBANNER:
            return action.payload;
        default:
            return state;
    }
}