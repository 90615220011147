/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./WeekSchedule.module.scss";
import { Container } from "react-bootstrap";
import { fetchLiveSchedule2 } from "../../actions/LiveScheduleAction";
import { connect } from "react-redux";
import moment from "moment";
import BrowserPersistence from "../../util/simplePersistance";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import { useSeasonal } from '../../logic/Seasonal';

const storage = new BrowserPersistence();
const WeekSchedule = ({ fetchLiveSchedule2, fullSchedule, liveSchedule, region }) => {
  const [localRegion, setLocalRegion] = useState(storage.getItem("CountryCode"));


  useEffect(() => {
    if (fullSchedule.length === 0) {
      fetchLiveSchedule2()
    }
  }, [fullSchedule]);

  useEffect(() => {
    setLocalRegion(region);
  }, [region]);

  const regionChange = (e) => {
    setLocalRegion(e);
  };

  const { classes } = useSeasonal();
  return (
    <Container style={{ position: "relative" }}>
      <div className={styles.selWrap}>
        <span className={styles.actionPlay}></span>
        <div className={`${styles.hmDropdown} ${classes.watchLiveDDL}`}>

          {/* <div style={{ color: 'red' }}>
            localRegion{localRegion} region {region}
          </div> */}

          {liveSchedule && (
            <Dropdown className={classes?.dropDownItemHover}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">

                {liveSchedule[localRegion]?.name && <>
                  {liveSchedule[localRegion]?.caption} ({liveSchedule[localRegion]?.name})
                </>}

                {/* {!localRegion && liveSchedule && <>
                  {(_.toArray(liveSchedule)[0]?.caption)}
                  {(_.toArray(liveSchedule)[0]?.name)}
                </>} */}
              </Dropdown.Toggle>

              <Dropdown.Menu>

                {_.toArray(liveSchedule).map((value, index) => {
                  return (
                    <>
                      {value.slug !== localRegion ?
                        <Dropdown.Item
                          onClick={(e) => {
                            regionChange(value.slug);
                          }}
                        >
                          {value.caption} ({value.name})
                        </Dropdown.Item> :
                        null
                      }
                    </>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

      </div>

      <table className={styles.tab_content}>
        <thead>
          <tr>
            <th>
              <p>TIME  </p>
            </th>

            {_.toArray(fullSchedule).length > 0
              && fullSchedule[localRegion] && fullSchedule[localRegion]["00:00 AM"]
              ? fullSchedule[localRegion]["00:00 AM"].map((value) => {
                return (
                  <th>
                    <label>{moment(value.date).format("MMM DD")}</label>
                    <p className={styles.fullweekPara}>
                      {moment(value.date).format("ddd")}
                    </p>
                  </th>
                );
              })
              : null}
          </tr>
        </thead>

        <tbody>
          {_.toArray(fullSchedule).length > 0
            ? _.toArray(fullSchedule[localRegion]).map((value, key) => {
              return (
                <tr>
                  {value.map((value2, index) => {
                    return (
                      <>
                        {index === 0 ? <td>{value2.start}</td> : null}
                        <td>{value2.name}</td>
                      </>
                    );
                  })}
                </tr>
              );
            })
            : null}
        </tbody>
      </table>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return { fullSchedule: state.fullSchedule, liveSchedule: state.liveSchedule };
};

export default connect(mapStateToProps, { fetchLiveSchedule2 })(WeekSchedule);
