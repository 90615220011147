import React from "react";
import imgVideo from '../../assets/img/imgVideo.jpg';
import imgVideoPrison from '../../assets/img/imgVideoPrison.png';
import ReactPlayer from "react-player";
import styles from "./DonateDollarADayVideo.module.scss";

/**
 * This component renders a video player for the specified region. 
 * @param{playing} prop specifies whether the video player is playing.
 * @param{setPlaying}  prop is used to set the `playing` state.
 * @param{pathName}  prop specifies the region for the video player.
 * The possible values are `/prisons`, and rest of the world its same video.
 * //{pathName === '/prisons' ? 'https://vimeo.com/826268354/2a8528c444' : 'https://vimeo.com/824104848/097b72a415?embedded=false'}
 */

const DonateDollarADayVideo = ({ playing, setPlaying, pathName }) => {

  return (
    <>
      {playing ?
        // Render a ReactPlayer component if the video player is playing.
        <ReactPlayer
          url={pathName === "/dollar-a-day-ca" ? (
            // Donate Video for CA
            'https://vimeo.com/824104848/097b72a415?embedded=false'

          ) : pathName === "/dollar-a-day-uk" ? (
            // Donate Video for UK

            'https://vimeo.com/824104848/097b72a415?embedded=false'

          ) : pathName === "/dollar-a-day-eu" ? (
            // Donate Video for Europe

            'https://vimeo.com/824104848/097b72a415?embedded=false'

          ) : pathName === "/dollar-a-day-ch" ? (
            // Donate Video for Switzerland

            'https://vimeo.com/824104848/097b72a415?embedded=false'
  
          ) : pathName === "/prisons" ? (

            'https://vimeo.com/826268354/2a8528c444'

          ) : (
            // Donate Video for US, MX, Row

            'https://vimeo.com/824104848/097b72a415?embedded=false'
          )}
          className={`${styles.player} `}
          id="VideoPlayer"
          width={"100%"}
          height={"100%"}
          controls={true}
          playing={true}
        /> :
        // Render a play icon and an image if the video player is not playing.
        <>
          <span className={styles.play_icon}
            onClick={() => {
              setPlaying(true);
            }}
          ></span>
          <img alt="video" src={pathName === '/prisons' ? imgVideoPrison : imgVideo} />
        </>
      }
    </>
  );
};

export default DonateDollarADayVideo;
