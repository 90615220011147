import React from "react";

import ReactHtmlParser from "react-html-parser";
import { useSeasonal } from '../../../logic/Seasonal';

const Howandwhy = ({ heading, description }) => {
  const {seasonal,classes} = useSeasonal();
  return (
    <div>
      {heading && <h3>{heading}</h3>}

      <p>{ReactHtmlParser(description)}</p>
    </div>
  );
};
export default Howandwhy;
