import React, { useState, useEffect } from "react";
import styles from "./shortfilmVideo.module.scss";
import Container from "react-bootstrap/Container";
import { Link, useParams, useHistory } from "react-router-dom";
import { fetchTwitter } from "../../actions/xmasAction";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const ShortFilmVideos = ({ fetchTwitter, twitterData, iframeUrl }) => {
  useEffect(() => {
    // if(fellowship.length ==0)
    fetchTwitter();
  }, []);

  const [tabActive, SetTabActive] = useState("Facebook Vidoes");
  return (
    <Container>
      <div className={styles.XmasVideos}>
        {/* Live iframe link */}
        <iframe
          scrolling="auto"
          frameborder="0"
          src={iframeUrl?.iframeUrl}
          className={styles.iframe}
        ></iframe>

        {/* QA  iframe link*/}
        {/* <iframe scrolling="auto" frameborder="0" src="https://qabackend.shalomworld.org/sample-page/" className={styles.iframe} ></iframe>  */}
      </div>
    </Container>
  );
};

const mapPropsToState = (state) => {
  return { twitterData: state.twitterData };
};
export default connect(mapPropsToState, { fetchTwitter })(ShortFilmVideos);
