import React, { useEffect, useRef, useState } from "react";
//import ReactPlayer from 'react-player'
import ReactPlayer from "react-player/lazy";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaVolumeDown,
  FaCompress,
} from "react-icons/fa";
import { AiTwotoneSetting } from "react-icons/ai";
import Slider from "@material-ui/core/Slider";
import styles from "./LiveVideoPlayerMobile.module.scss";
import { useDimensions } from "../../logic/Dimentions";
import { useDispatch } from "react-redux";
import { fetchLiveUrl } from "../../actions/watchLiveAction";
import imgPreloader from "../../assets/img/preloader.gif";
import { useLocation } from "react-router-dom";
import ReactFullscreen from "react-easyfullscreen";
import { useSeasonal } from "../../logic/Seasonal";
import { Spinner } from "react-bootstrap";

const LiveVideoPlayerMobile = ({
  url,
  fullscreen,
  setFullscreen,
  playError,
  setPlayError,
  live,
  play,
  setPlay,
  config,
}) => {
  const [videos, setVideos] = useState({});
  const [currentDuration, setCurrentDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [selectedVideo, setSelectedVideo] = useState(0);
  const [settingDisplay, setSettingDisplay] = useState(false);
  const [toggleScreen, setToggleScreen] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState(0);
  const [showControls, setShowControls] = useState(0);
  const [playerReady, setPlayerReady] = useState(false); // Video player buffering loading
  const { classes } = useSeasonal();
  const location = useLocation();

  let path = location.pathname;
  const player = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (toggleScreen && document.body.classList.contains("landscape")) {
      document.body.classList.remove("landscape");
    }
  }, [toggleScreen]);

  if (window.matchMedia("(orientation: portrait)").matches) {
    // you're in PORTRAIT mode
    document.body.classList.remove("landscape");
  }

  if (!toggleScreen && window.matchMedia("(orientation: landscape)").matches) {
    // you're in LANDSCAPE mode
    document.body.classList.add("landscape");
  }

  useEffect(() => {
    setPlayError(true);
    if (url) {
      dispatch(fetchLiveUrl(url)).then((res) => {
        let a = res.data.split(",");
        let arr = a.filter((item) => item.includes("RESOLUTION"));
        let obj = {};
        arr.map((item, index) => {
          if (index === 0) {
            obj['0'] = url; //Auto url for m3u8
          }
          const key = item.search("https");
          const urlEnd = item.search("#");
          const resolution = item.slice(0, key).split("x");

          obj[parseFloat(resolution[0].slice(11))] = item.slice(key, urlEnd);
        });
        setVideos(obj);
      });
    }
  }, [dispatch, setPlayError, url]);

  const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);

    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_volume",
      JSON.stringify({
        creation: Math.round(new Date().getTime() / 1000),
        volume: newValue,
      })
    );
  };

  // Close events

  if (document.addEventListener) {
    document.addEventListener("webkitfullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
  }

  document.addEventListener("keydown", (e) => EscKey(e));

  function EscKey(e) {
    if (e.code === "Escape") {
      fullscreen && setFullscreen(false);
      setToggleScreen(false);
      root.classList.remove("h_overflow");
      document.body.classList.remove("full-screen");
    }
  }

  function exitHandler() {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      fullscreen && setFullscreen(false);
      setToggleScreen(false);
      root.classList.remove("h_overflow");
      document.body.classList.remove("full-screen");
    }
  }
  var root = document.getElementsByTagName("html")[0];

  //
  const getResolutionFromWidth = (res) => {
    if (res > 1500) return "Full HD";
    else if (res > 1100) return "HD";
    else if (res > 800) return "SD";
    else if (res >= 640) return "Low";
    else if (res === '0') return "Auto";
  };

  const handleSelectResolution = (res) => {
    let value = getResolutionFromWidth(res);
    setSelectedResolution(value);
  };

  /**
* @desc hide controls after certain interval
*/

  useEffect(() => {
    let myTimeout = setInterval(() => {
      setShowControls(false);
      setSettingDisplay(false)
    }, 12000);
    return () => {
      clearTimeout(myTimeout);
    };
  }, []);

  document.addEventListener("mousemove", (e) => {
    setShowControls(true);
  });

  useEffect(() => {

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }

  }, []);

  document.addEventListener("mousemove", (e) => {
    setShowControls(true);
  });

  function handleKeyDown(event) {
    if (fullscreen) {
      if (event.keyCode === 32) {
        setPlay(!play);
        setSettingDisplay(false);
      }

      if (event.keyCode === 27) {
        setShowControls(true);
        setToggleScreen(false);
        setFullscreen(false);
        setSettingDisplay(false);
      }
    }
  }

  useEffect(() => {
    setPlay(false);
    if (localStorage.getItem("SHALOM_WORLD_BROWSER_volume") != null) {
      let videoVolume = JSON.parse(
        localStorage.getItem("SHALOM_WORLD_BROWSER_volume")
      );

      setTimeout(() => {
        setVolume(0);
        if (videoVolume?.volume) {
          setVolume(videoVolume.volume);
        }
      }, 2000);
    }

  }, []);

  const handleOnBufferEnd = (event, newValue) => {
    setPlayerReady(true)

  };

  const handleOnBufferStart = (event, newValue) => {
    setPlayerReady(false)
  };

  const handleOnReady = (event) => {
    setPlayerReady(true)
  };


  return (
    <>
      <ReactFullscreen>
        {({ ref, onRequest, onExit }) => (
          <div
            id="VideoPlayerWrap"
            className={`${styles.videoPlayer
              } player1 mobile-videoplayer mobile-player-normal ${"volume-" + volume
              }`}
            ref={ref}
          >

            {!play && (
              <span
                className={`${styles.actionPlay} ${classes.playClass} ${classes.borderOnly} `}
                onClick={() => {
                  setPlay(true);
                  setSettingDisplay(false);
                }}
              ></span>
            )}

            {url === "" ? (
              <div className={`${styles.preloader} ${classes.loaderColor}`}>
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div
                className={`${styles.custom_live_player} custom_live_player`}
                onClick={() => setPlay(!play)}
              >
                {!playerReady && play &&
                  <div className={`${styles.preloader} ${classes.loaderColor}`}>
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                }

                <ReactPlayer
                  ref={player}
                  url={videos[selectedVideo] || url}
                  className={styles.player}
                  volume={volume}
                  width={"100%"}
                  config={{
                    file: {
                      hlsOptions: config,
                    },
                  }}
                  playing={play}
                  height={path === "/" ? "100%" : "100%"}
                  controls={false}
                  onContextMenu={(e) => e.preventDefault()}
                  playsInline
                  onBufferEnd={handleOnBufferEnd}
                  onBuffer={handleOnBufferStart}
                  onReady={handleOnReady}
                />
              </div>
            )}
            <div className={`${styles.control} custom-controls`} style={{ opacity: showControls ? 1 : 0 }}>

              <button
                onClick={() => {
                  if (url == "") {
                    setPlayError(true);
                  }
                  setPlay(!play);
                }}
              >
                {!play ? <FaPlay /> : <FaPause />}
              </button>
              {/* <div style={{ color: 'blue' }}>Anroid</div> */}
              <button
                title={volume}
                onClick={() => {
                  setVolume(volume == 1 ? 0 : 1);
                }}
              >
                {volume == 1 ? (
                  <FaVolumeUp />
                ) : volume > 0 ? (
                  <FaVolumeDown />
                ) : (
                  <FaVolumeMute />
                )}
              </button>

              <div className={styles.slider}>
                <Slider
                  value={volume}
                  min={0}
                  step={0.1}
                  max={1}
                  scale={(x) => x ** 10}
                  valueLabelDisplay="off"
                  onChange={handleChangeVolume}
                />
              </div>

              <div className={styles.liveText}>LIVE</div>
              {!toggleScreen ? (
                <button
                  className={styles.rightIcon}
                  onClick={() => {
                    onRequest();
                    setToggleScreen(true);
                    root.classList.add("h_overflow");
                    document.body.classList.add("full-screen");
                    //document.getElementById("VideoPlayer").requestFullscreen()
                  }}
                >
                  <FaExpand />
                </button>
              ) : (
                <button
                  className={styles.rightIcon}
                  onClick={() => {
                    onExit();
                    setToggleScreen(false);
                    setFullscreen(false);
                    root.classList.remove("h_overflow");
                    document.body.classList.remove("full-screen");
                  }}
                >
                  <FaCompress />
                </button>
              )}
              <button
                className={styles.setting}
                onClick={() => {
                  setSettingDisplay(!settingDisplay);
                }}
              >
                <ul style={{ display: settingDisplay ? "block" : "none" }}>
                  {Object.keys(videos)?.length > 0 &&
                    Object.keys(videos)
                      .reverse()
                      .map((video, index) => {
                        return (
                          <>
                            <li
                              // eslint-disable-next-line eqeqeq
                              className={`${selectedVideo == video
                                ? styles.selected
                                : ""
                                }`}
                              onClick={() => {
                                let cd = currentDuration;
                                setSelectedVideo(video);
                                handleSelectResolution(video);
                                setPlay(true);
                                player.current.seekTo(cd, "seconds");
                              }}
                            >
                              {getResolutionFromWidth(video)}
                            </li>
                          </>
                        );
                      })}
                </ul>
                <AiTwotoneSetting />
              </button>
            </div>
          </div>
        )}
      </ReactFullscreen>
    </>
  );
};

export default LiveVideoPlayerMobile;
