import {FETCH_BLOG_CATEGORIES,FETCH_BLOG_LIST,UPDATE_BLOG_LIST,FETCH_NEWS_DESCRIPTION,REMOVE_NEWS_DESCRIPTION} from './types';
import {salomapi3} from '../apis';
import salomapi from '../apis';

export const fetchBlogCategories = () => async dispach => {
    const response = await salomapi3.get('/blog/categories');
    dispach({type:FETCH_BLOG_CATEGORIES,payload:response.data.categories})
}
export const fetchBlogList = (loadPage,category) => async dispach => {
    const response = await salomapi.get('blog?perpage=14&page='+loadPage+'&category='+category+'&timestamp='+Math.floor(+new Date() / 1000) );
    if(loadPage>1)
    dispach({type:UPDATE_BLOG_LIST,payload:response.data})
    else
    dispach({type:FETCH_BLOG_LIST,payload:response.data})
}
export const fetchNewsDescription = (url) => async dispach => {
    const response = await salomapi.get('/blog/single?url='+url+'&timestamp='+Math.floor(+new Date() / 1000));
    
    dispach({type:FETCH_NEWS_DESCRIPTION,payload:response.data})
}
export const removeNewsDescription = () => async dispach => {
    dispach({type:REMOVE_NEWS_DESCRIPTION})
}