/* eslint-disable import/no-anonymous-default-export */
import { FETCH_CURRENCY } from '../actions/types.js';

const INTIAL_STATE = { data: [] };

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CURRENCY:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}