import React, { useEffect } from "react";
import CommonLayout from "../../Layouts/CommonLayout";
import Banner from "../Banner/Banner";
import CountrySelectBox from "../CountrySelectBox/CountrySelectBox";
import CountryDataCard from "../CountryDataCards";
import ContentSection from "../ContentSection";
import VechcleTypes from "../VechcleTypes";
import DonateMore from "../DonateMore";
import { connect } from "react-redux";
import styles from "./Cardonation.module.scss";
import { fetchCarDonation } from "../../../actions/carDonationAction";

const Cardonation = ({ carDonation, fetchCarDonation, classes }) => {
  useEffect(() => {
    // if(fellowship.length ==0)
    fetchCarDonation();
  }, []);
  const handleChangeCountry = (event) => {
    fetchCarDonation(event);
  };

  return (
    <CommonLayout>
      <div className={styles.spfamazon_wrapper}>
        {Object.keys(carDonation).length > 0 ? (
          <div className="container">
            <Banner bannerDetails={carDonation.banner_details} />
            <CountrySelectBox
              handleChangeCountry={handleChangeCountry}
              countries={carDonation.countries}
              countrySelectionText={carDonation.country_selection_text}
              countrySelectionText1={carDonation.country_selection_text_1}
            />
            <section className={styles.content_section}>
              {carDonation.region === "others" && (
                <p>{carDonation.pageDescription}</p>
              )}
              {carDonation.country_data && carDonation.region != "others" ? (
                <>
                  <CountryDataCard cardData={carDonation.country_data.card} />
                  <ContentSection
                    heading={carDonation.country_data.pageHeading}
                    description={carDonation.country_data.pageDescription}
                    donationTxt={carDonation.country_data.link_title}
                    donationUrl={carDonation.country_data.link_url}
                    target={carDonation.country_data.link_target}
                  />{" "}
                  <VechcleTypes />
                </>
              ) : null}
              {carDonation.country_data ? (
                <DonateMore
                  moreWaysGiveTitle={carDonation.country_data.moreWaysGiveTitle}
                  moreWaysGive={carDonation.country_data.moreWaysGive}
                />
              ) : null}
            </section>
          </div>
        ) : null}
      </div>
    </CommonLayout>
  );
};
const mapPropsToState = (state) => {
  return { carDonation: state.carDonationData.carDonation };
};
export default connect(mapPropsToState, { fetchCarDonation })(Cardonation);
