/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { DiApple, DiAndroid } from 'react-icons/di'
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { IoChevronBack } from 'react-icons/io5'
import { IoArrowRedoOutline } from 'react-icons/io5'
import { IoMdClose } from 'react-icons/io'
import { FaPinterestP, FaFacebookF } from 'react-icons/fa'
import moment from 'moment'
import ShareModal from '../Mobile/ShareModal'
import CountryDropDown from '../CountryDropdown'
import { useSeasonal } from '../../logic/Seasonal'
import Assets from '../Layouts/Assets'
import styles from './MobileMenu.module.scss'
import { fetchSecMenu, fetchMobMenu } from '../../actions/menuAction'
import { fetchCommonSearchResult } from '../../actions/menuAction'
import { FaXTwitter } from "react-icons/fa6";

const MobileMenu = ({
  fetchSecMenu,
  fetchCommonSearchResult,
  searchResults,
  secMenu,
  fetchMobMenu,
  mobilMenu,
}) => {

  const [searchOpen, setSearchOpen] = useState(false)
  const [searchCat, setSearchCat] = useState('all')
  const [menuOpen, setMenuOpen] = useState(false)
  const [country, setCountry] = useState('us')
  const [searchData, setSearchData] = useState('')
  const [searchTerm, setSearchTerm] = useState({ search: "", type: "all" }); //what users types with each keyboard event.
  const [open, setOpen] = useState(false)
  const closeShareModal = () => setOpen(false)

  const { classes } = useSeasonal()
  let history = useHistory()

  useEffect(() => {
    if (secMenu.length === 0) {
      fetchSecMenu()
    }
  }, [secMenu])

  useEffect(() => {
    if (mobilMenu.length === 0) {
      fetchMobMenu()
    }
  }, [mobilMenu])

  const searchByKeyword = (e) => {
    const obj = { ...searchTerm, search: e.target.value };
    setSearchTerm(obj);
    fetchCommonSearchResult(e.target.value, searchCat)
    setSearchData(e.target.value)
  }

  const searchingWithKeyword = (action, value) => {
    const obj = { ...searchTerm, [`${action}`]: value };
    setSearchTerm(obj);
  };

  const submitedSearch = (e) => {
    e.preventDefault()
    setSearchOpen(false);

    history.push({
      pathname: `/searchResult/${searchData}`,
      state: {
        filters: searchTerm,
      },
    });
  }

  const renderType = (type) => {
    switch (type) {
      case "episodes":
        return "EPISODES";
      case "programs":
        return "SHOWS";
      case "podcastepisodes":
        return "Podcast episodes";
      case "podcasts":
        return "Podcast";
      default:
        return "NEWS";
    }
  };

  const renderLink = (result) => {
    switch (result.type) {
      case "episodes":
        return "/episode/" + result.url;
      case "programs":
        return "/show/" + result.url;
      case "podcastepisodes":
        return "/podcast/" + result.programSlug + "/" + result.url;
      case "podcasts":
        return "/podcast/" + result.url;

      default:
        return "/news/" + result.blogUrl;
    }
  };

  const renderImage = (result) => {
    switch (result.type) {
      case "blog":
        return result.blogImage;
      case "podcastepisodes":
        return result.thumbnail;
      case "podcasts":
        return result.thumbnail;
      default:
        return result.image;
    }
  };

  return (
    <header
      className={`${history.location.pathname.split('/').length > 2 &&
        history.location.pathname.split('/')[1] === 'news'
        ? `${styles.mobHeader} ${styles.mobHeaderNews}`
        : styles.mobHeader
        } mobile-header`}
    >
      {navigator.userAgent !== 'shalom_world_app_mobile' ? (

        <div className="container">

          <div className={styles.mobHdrWrap}>
            <div className={styles.mobhdrMenu}>
              <div
                className={styles.hamburger}
                onClick={() => {
                  setMenuOpen(true)
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className={`${styles.mobileSideNav} ${menuOpen && styles.open}`}
              >
                <div
                  className={styles.menuOverlay}
                  onClick={() => {
                    setMenuOpen(false)
                  }}
                ></div>
                <div className={styles.sideNavWrap}>
                  <div className={styles.logoWrap}>
                    <div
                      className={styles.navCloseBtn}
                      onClick={() => {
                        setMenuOpen(false)
                      }}
                    >
                      <span>
                        <IoMdClose></IoMdClose>
                      </span>
                    </div>

                    <figure>
                      <a href="/">
                        <img src={Assets.logos} alt="Shalomworld" />
                      </a>
                    </figure>
                  </div>

                  <div className="d-none">
                    <CountryDropDown
                      selectCountry={setCountry}
                      country={country}
                      isMobileMenu={true}
                    />
                  </div>

                  <ul className={styles.menuList}>
                    {mobilMenu.map((value, index) => {
                      return (
                      
                        <li className={styles.menulistItems}>

                          {value?.slug === "donateButton" ? (
                            <a 
                              key={index}
                              href={value?.url}
                              className={styles.menulistItem}
                            >
                              {value?.title}
                            </a>
                          ) : (
                            value.target === "_blank" ? (
                              <a 
                                href={value.url} 
                                target="_blank"
                                title={value.title}
                                className={styles.menulistItem}
                              >
                                {value.title}
                              </a>
                            ) : (
                              <>
                                {value.openSameTab === true ? (
                                  <a 
                                    href={value.url} 
                                    target="_self"
                                    title={value.title}
                                    className={styles.menulistItem}
                                  >
                                    {value.title}
                                  </a>
                                ) : (
                                  <Link 
                                    title={value.title}
                                    to={"/" + value.slug}
                                    className={styles.menulistItem}
                                  >
                                    {value.title}
                                  </Link>
                                )}
                              </>
                            )
                          )}

                        </li>
                      )
                    })}
                    <li className={styles.menulistItems}>
                      <a
                        href={
                          /Android/i.test(navigator.userAgent)
                            ? 'https://play.google.com/store/apps/details?id=com.ceino.shalomworld&hl=en'
                            : 'https://apps.apple.com/in/app/shalom-world-tv/id995030357'
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={`${styles.menulistItem} ${styles.getAppLink} ${classes?.fullSchduleBtn}`}
                      >
                        Get Shalom World App
                        <span>
                          {/Android/i.test(navigator.userAgent) ? (
                            <DiAndroid />
                          ) : (
                            <DiApple />
                          )}
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className={styles.cunnectWith}>
                    <p>Connect with us</p>
                    <p>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className={classes?.txtColor}
                        href="https://www.facebook.com/shalomworld/"
                      >
                        <FaFacebookF></FaFacebookF>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className={classes?.txtColor}
                        href="https://twitter.com/ShalomWorldTV"
                      >
                        <FaXTwitter></FaXTwitter>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className={classes?.txtColor}
                        href="https://www.instagram.com/shalomworldtv/"
                      >
                        <AiFillInstagram></AiFillInstagram>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className={classes?.txtColor}
                        href="https://in.pinterest.com/shalomworldtv/"
                      >
                        <FaPinterestP></FaPinterestP>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className={classes?.txtColor}
                        href="https://www.youtube.com/user/ShalomWorldWide"
                      >
                        <AiFillYoutube></AiFillYoutube>
                      </a>
                    </p>
                  </div>
                  <div className={styles.bottomTxt}>
                    <p className>© {moment().format('YYYY')} Shalom media</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.mobHdrBackBtn}>
              <span>
                <IoChevronBack
                  onClick={() => window.history.back()}
                ></IoChevronBack>
              </span>
            </div>
            <div className={styles.mobHdrShareBtn}>
              <span>
                <IoArrowRedoOutline
                  onClick={() => setOpen(true)}
                ></IoArrowRedoOutline>
              </span>
            </div>
            <div className={styles.mobhdrLogo}>
              <figure>
                <Link to={'/'}>
                  <img src={Assets.logos} alt="Shalomworld" />
                </Link>
              </figure>
            </div>

            {/* search section */}
            <div className={styles.mobhdrSearch}>

              <span
                className="icon-search"
                onClick={() => {
                  setSearchOpen(true)
                }}
              >
                {' '}
              </span>

              <div
                className={`${styles.hdrSearchResult} ${searchOpen ? styles.open : null
                  }`}
              >
                <div className={styles.hdrSearchResultWrap}>
                  <div className={styles.searchBox}>

                    <form className="h-100" onSubmit={submitedSearch}>
                      <div className={styles.searchBoxRow}>
                        <div className={styles.searchBoxDropdown}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {searchCat === "all"
                                ? "All"
                                : searchCat === "programs"
                                  ? "Shows"
                                  : searchCat === "episodes"
                                    ? "Episodes"
                                    : searchCat === "podcastepisodes"
                                      ? "Podcast episodes"
                                      : searchCat === "podcasts"
                                        ? "Podcast"
                                        : "News"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setSearchCat("all");
                                  searchingWithKeyword(
                                    "type",
                                    "all"
                                  );
                                }}
                              >
                                All
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setSearchCat("programs");
                                  searchingWithKeyword(
                                    "type",
                                    "programs"
                                  );
                                }}
                              >
                                Shows
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setSearchCat("episodes");
                                  searchingWithKeyword(
                                    "type",
                                    "episodes"
                                  );
                                }}
                              >
                                Episodes
                              </Dropdown.Item>


                              <Dropdown.Item
                                onClick={() => {
                                  setSearchCat("blog");
                                  searchingWithKeyword(
                                    "type",
                                    "blog"
                                  );
                                }}
                              >
                                News
                              </Dropdown.Item>

                              <Dropdown.Item
                                onClick={() => {
                                  setSearchCat("podcasts");
                                  searchingWithKeyword(
                                    "type",
                                    "podcasts"
                                  );
                                }}
                              >
                                Podcast
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div className={styles.searchBoxform}>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={searchByKeyword}
                          />
                          {searchResults &&
                            searchResults.length > 0 &&
                            Array.isArray(searchResults) ? (
                            <div className={styles.searchListResult}>
                              <ul>
                                {searchResults && searchResults.length > 0
                                  ? searchResults?.map((result, index) => {
                                    return (
                                      <li key={index}>
                                        <Link to={renderLink(result)}

                                          onClick={() => {
                                            setSearchOpen(false)
                                          }}
                                        >
                                          <div className={styles.imgWrap}>
                                            <img
                                              src={renderImage(result)}
                                              alt=""
                                            />
                                          </div>
                                          <h5 className={styles.textWrap}>
                                            {result.type === "blog"
                                              ? result.blogTitle
                                              : result.title}
                                            <span
                                              className={`${styles.resType} ${classes.txtColor}`}
                                            >
                                              {renderType(result.type)}
                                            </span>
                                          </h5>
                                        </Link>
                                      </li>
                                    );
                                  })
                                  : null}
                              </ul>
                            </div>
                          ) : null}
                        </div>

                        <div
                          className={styles.searchBoxClose}
                          onClick={() => {
                            setSearchOpen(false)
                          }}
                        >
                          <svg
                            height="48"
                            viewBox="0 0 48 48"
                            width="48"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="svgclose"
                              d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"
                            />
                          </svg>
                        </div>

                      </div>
                    </form>

                  </div>
                </div>

              </div>
            </div>

          </div>
          <ShareModal onCloseModal={closeShareModal} open={open} />
        </div>

      ) : null}

    </header>
  )
}
const mapStateToProps = (state) => {
  return {
    searchResults: state.commonSearch.searchResult,
    secMenu: state.secMenu,
    mobilMenu: state.mobilMenu,
  }
}

export default connect(mapStateToProps, {
  fetchCommonSearchResult,
  fetchSecMenu,
  fetchMobMenu,
})(MobileMenu)
