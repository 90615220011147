import { FETCH_REGION } from "./types";
import { salomapi3 } from "../apis";
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();

export const fetchRegion = () => async (dispatch) => {
  async function fetchFromServer() {
    try {
      const response = await salomapi3.get("/region");
      await saveRegion(response?.data?.region);
      await saveIso(response?.data?.isoCountryCode);
      await saveTimeZone(response?.data?.timeZone);

      dispatch({ type: FETCH_REGION, payload: response?.data?.region });
    } catch (error) {
      dispatch({
        type: FETCH_REGION,
        payload: error,
      });
      throw error;
    }
  }

  const localRegionData = await retrieveRegion();
  if (localRegionData) {
    dispatch({ type: FETCH_REGION, payload: localRegionData });
  } else {
    await fetchFromServer();
  }
  async function retrieveRegion() {
    return storage.getItem("CountryCode");
  }

  async function saveRegion(region) {
    //86400
    return storage.setItem("CountryCode", region, 10);
  }
  async function saveTimeZone(timeZone) {
    return storage.setItem("TimeZoneCode", timeZone, 10);
  }
  async function saveIso(isoCode) {
    return storage.setItem("IsoCountryCode", isoCode, 10);
  }
};
