export const useViewportAnimation = () => {
    //animation
    // just "anim" in your element
    // window.addEventListener("load", () => {
        function isInViewport(el, gap) {
            let top = el.offsetTop;
            let left = el.offsetLeft;
            let height = el.offsetHeight;
            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }
            return (
                (window.pageYOffset + window.innerHeight - gap) >= (top) &&
                (window.pageYOffset) <= (height + top)
            );
        }
        let getElem = document.querySelectorAll('.animation');
        //please change as per the design
        const breakPoints = {
            desktop: 250,
            laptop: 80,
            tab: 50,
            mobile: 30
        };
        let targetGap;
        window.innerWidth >= 1200 ? targetGap = breakPoints.desktop :
            window.innerWidth >= 1024 ? targetGap = breakPoints.laptop :
            window.innerWidth >= 768 ? targetGap = breakPoints.tab :
            targetGap = breakPoints.mobile;

        function anim() {
            getElem.forEach(element => {
                
                if(isInViewport(element, targetGap)){
                    element.classList.add("visibleAnim") 
                }
            })
        }

        if(getElem.length > 0 ){
            window.addEventListener('scroll', anim, false);
            anim()
        }
        // getElem.length > 0 ? anim() : null;
    
}