import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./PodcastAudioThumb.module.scss";
import { FiX } from "react-icons/fi";
import PodcastPlayScreen from "../../Mobile/PodcastPlayScreen";
import PodcastPlaylist from "../PodcastPlaylist";

const PodcastAudioThumb = ({
    episode,
    podCastPlayListData,
    handlePlay,
    handleUpdatePlaylist,
    play,
    handleSetActiveId,
    activeId,
    progressData,
    audioDtls,
    setAudioDtls,
    handleClearPlaylist,
    setEpisodeInfo,
    episodeInfo,
    setPlaylist,
    isPlaylist,
}) => {

    
    const[titleAnim, setTilteAnimate] = useState(false);

    useEffect(() => {
        
        setTilteAnimate(false);
        const timer = setTimeout(() => {
            setTilteAnimate(true);
        }, 100);
        return () => clearTimeout(timer);
        
        
    }, [episode?.id]);


    const scrollDisable = (e) => {
        const timer = setTimeout(() => {
            document.querySelector("html").classList.add("scroll_disable");
            document.body.classList.add("scroll_disable");
        }, 1000);
        return () => clearTimeout(timer);
    };

    const handlePlayThisItem = (id) => {
        let newPlayList = podCastPlayListData;
        let objectFound = podCastPlayListData.find((item) => item.active === true);
        let elemenPosition = podCastPlayListData.indexOf(objectFound);

        let nextObject = podCastPlayListData.find((item) => item.id === id);
        let nextObjectPostion = podCastPlayListData.indexOf(nextObject);
        newPlayList[nextObjectPostion].active = true;
        newPlayList[elemenPosition].active = false;
        handleUpdatePlaylist(newPlayList[nextObjectPostion].id);
    };

    const playToggleClass = (id) => {
        if (id === activeId) {
            handlePlay(!play);
            // setPlay(!play);
        } else {
            //Append eps to play list
            handleUpdatePlaylist(id);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        return;
    };
    const handleShowPlaylist = () => {
        setPlaylist(true);
        return;
    };
   

    return (
        <>
            <div className={`${styles.PodcastAudioThumb} ${titleAnim ? 'animTitle' : ''}`}>
                <span
                    className={styles.close}
                    onClick={() => {
                        handlePlay(false);
                        handleClearPlaylist();
                    }}
                >
                    <FiX />
                </span>
                <div className="audio-thumb">
                    {/*  */}
                    <figure className="figImg">
                        <img src={episode.thumbnail?.small} alt={episode.title} />
                    </figure>

                    <div className="audio-dtls">
                        <div className="podcastSlidingText">
                            <div  className="slideText">
                                <span>{episode?.title}</span>
                                <span>{episode?.title}</span>
                            </div>
                        </div>
                    </div>
                    <Link
                        className="full_link"
                        onClick={(e) => {
                            handleClick(e);
                            scrollDisable();
                            setAudioDtls(true);
                        }}
                    ></Link>
                </div>
                <div className={styles.playActionWrapp}>
                <div
                    className={`${styles.playlist_wrap} blinkBtn `}
                    onClick={() => {
                        handleShowPlaylist();
                        scrollDisable();
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                    >
                        <g fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                            <rect width="25" height="24" stroke="none" />
                            <rect x="0.5" y="0.5" width="24" height="23" fill="none" />
                        </g>
                        <g transform="translate(-1438 -916.189)">
                            <line
                                x2="18.804"
                                transform="translate(1438 918.189)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="1.2"
                            />
                            <line
                                x2="12.5"
                                transform="translate(1438 933.184)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="1.2"
                            />
                            <g transform="translate(1452.98 928.804)">
                                <line
                                    x2="9.917"
                                    transform="translate(0 4.647)"
                                    fill="none"
                                    stroke="#fff"
                                    stroke-width="1.2"
                                />
                                <line
                                    x2="9.917"
                                    transform="translate(4.958 0) rotate(90)"
                                    fill="none"
                                    stroke="#fff"
                                    stroke-width="1.2"
                                />
                            </g>
                            <line
                                x2="18.804"
                                transform="translate(1438 926.275)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="1.2"
                            />
                        </g>
                    </svg>
                </div>
                {/* Play/Pause button */}
                <div className={styles.play_pause_wrap}>
                    <div
                        className={`${styles.play} ${play && activeId === episode.id ? styles.pause : styles.play
                            }`}
                        onClick={(e) => {
                            handleClick(e);
                            playToggleClass(episode.id);
                        }}
                    >
                        {play && activeId === episode.id ? 
                            // play
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><g transform="translate(-323 -1192)"><rect width="3" height="12" rx="1" transform="translate(323 1192)"/><rect width="3" height="12" rx="1" transform="translate(329 1192)"/></g></svg>
                            : 
                            // pause
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><path d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z" transform="translate(10) rotate(90)" fill="#ebab0b"/></svg>
                        
                        }
                    </div>
                </div>
                </div>
            </div>
            <PodcastPlayScreen
                audioDtls={audioDtls}
                setAudioDtls={setAudioDtls}
                episode={episode}
                activeId={activeId}
                progressData={progressData}
                handlePlay={handlePlay}
                play={play}
                podCastPlayListData={podCastPlayListData}
                handleSetActiveId={handleSetActiveId}
                handleUpdatePlaylist={handleUpdatePlaylist}
                setEpisodeInfo={setEpisodeInfo}
                episodeInfo={episodeInfo}
                isPlaylist={isPlaylist}
                setPlaylist={setPlaylist}
            />

            <PodcastPlaylist
                isPlaylist={isPlaylist}
                setPlaylist={setPlaylist}
                podCastPlayListData={podCastPlayListData}
                handlePlayThisItem={handlePlayThisItem}
                handlePlay={handlePlay}
                play={play}
                handleSetActiveId={handleSetActiveId}
                activeId={activeId}
                progressData={progressData}
            />
        </>
    );
};

export default PodcastAudioThumb;
