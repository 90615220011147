import { salomapi3 } from "../apis";

export const fetchUser =
  ({ token }) =>
  async (dispatch) => {
    const response = await salomapi3.post(
      `/media/forgotpass/verify?token=${token}`
    );
    return response;
  };
