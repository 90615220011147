
import styles from './CategoryList.module.scss';
import { Navbar } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCategories } from '../../actions/categoryAction';
import { useCategoryList } from "../../logic/CategoryList/useCategoryList";
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDimensions } from '../../logic/Dimentions';
import { useSeasonal } from '../../logic/Seasonal';

const CategoryList = ({ fetchCategories, categories, SetCatgeory, setPagination }) => {
    const { width } = useDimensions();
    const { location } = useHistory();
    let { category } = useParams()
    const { seasonal, classes } = useSeasonal();
    let path = location.pathname;
    let pageName = 'shows';
    if (path == '/shows' || path == '/episodes') {
        category = 'popular'
    }
    if (path.search('/episodes') !== -1) {
        pageName = 'episodes';
    }

    const [stickyMenu1, setStickyMenu1] = useState(false)
    const [limitCategory, setLimitedCategory] = useState([]);
    const [dropDownCategory, setDropDownCategory] = useState([]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    useEffect(() => {
        if (categories.length > 0 && limitCategory.length == 0) {
            if (width <= 1025) {
                setLimitedCategory(categories)
                setDropDownCategory([])
            } else if (width < 1118) {
                setLimitedCategory(categories.slice(0, 6))
                setDropDownCategory(categories.slice(6, categories.length))
            } else if (width < 1212) {
                setLimitedCategory(categories.slice(0, 7))
                setDropDownCategory(categories.slice(7, categories.length))
            } else {
                setLimitedCategory(categories.slice(0, 8))
                setDropDownCategory(categories.slice(8, categories.length))
            }
        }
    }, [categories])

    useEffect(() => {

        if (width <= 1025) {
            setLimitedCategory(categories)
            setDropDownCategory([])
        } else if (width < 1118) {
            setLimitedCategory(categories.slice(0, 6))
            setDropDownCategory(categories.slice(6, categories.length))
        } else if (width < 1212) {
            setLimitedCategory(categories.slice(0, 7))
            setDropDownCategory(categories.slice(7, categories.length))
        } else {
            setLimitedCategory(categories.slice(0, 8))
            setDropDownCategory(categories.slice(8, categories.length))
        }
    }, [width])

    const handleScroll = (e) => {

        if (window.pageYOffset > 500) {
            setStickyMenu1(true)
        } else {
            setStickyMenu1(false)
        }
    }
    const handleResetPaginate = (e) => {

    }

    useCategoryList({ fetchCategories, categories })

    const Submenu = (
        <ul className={styles.nav__submenu}>
            {
                dropDownCategory ? dropDownCategory.map((category1, index) => {
                    return (
                        <li className={styles.nav__submenu_item} key={index}>
                            <Link
                                onClick={handleResetPaginate}
                                to={`/${pageName}/${category1.slug}`}
                                className={`${category1.slug === category && styles.active} ${seasonal?.color != undefined && classes?.categoryList}`}>
                                {category1.title}
                            </Link>
                        </li>
                    )
                }) : null
            }
        </ul>
    )
    return (
        width > 1025 ? <Navbar className={styles.Menu}>
            <header className={styles.MenuList}>
                <div className={`container ${limitCategory.length == 0 ? 'headmenuload' : ''}`}>
                    <div className={styles.head_menu}>
                        <ul className={`${styles.menu_links} ${stickyMenu1 ? styles.stickMenu : null}`}>
                            {limitCategory ? limitCategory.map((value, index) => {
                                return (
                                    <li className="list-items" key={index} className={value.title === 'Events' && pageName === 'episodes' ? 'd-none' : ''}>
                                        <Link onClick={handleResetPaginate} to={`/${pageName}/${value.slug}`} style={(value.slug == category && seasonal?.color != undefined) ? { color: seasonal?.color } : { color: '#fff' }} className={`${value.slug === category && styles.active} ${seasonal?.color != undefined && classes?.categoryList}`}>{value.title}</Link>
                                    </li>
                                )
                            }) : null}
                            <li className={styles.nav__menu_item}> <a data-toggle="tooltip" title="More" > More </a> {Submenu}</li>
                        </ul>
                    </div>
                </div>
            </header>
        </Navbar> :
            <ul className={`${styles.mobileList} ${path.search('/shows') !== -1 && styles.mobileListShows}`}>
                {limitCategory ? limitCategory.map((value, index) => {
                    return (
                        <li className={value.title === 'Events' && pageName === 'episodes' ? 'd-none' : ''} key={index}>
                            <Link to={`/${pageName}/${value.slug}`} className={`${value.slug === category && styles.active} ${value.slug === category && classes?.txtColor}`}>{value.title}</Link>
                        </li>
                    )
                }) : null}
            </ul>
    )
}

const mapStateToProps = (state) => {
    return ({ categories: state.categories })
}

export default connect(mapStateToProps, { fetchCategories })(CategoryList);