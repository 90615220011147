import React from 'react'
import styles from './NewSwPluseTabs.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { Link } from 'react-scroll'
import { useSeasonal } from '../../logic/Seasonal'
import ReactHtmlParser from 'react-html-parser'

function SwPluseTabs({ data, SetForm, handleScrollToSign }) {
  const { classes } = useSeasonal()
  const handleScrollTo = () => {
    handleScrollToSign()
  }

  return (
    <section className={styles.swplusAboutWrap}>
      <Container>
        <nav className={styles.tabNav}>
          <ul>
            <li className={`${styles.sign}`}>
              <Link
                to="Signup/Signin"
                spy={true}
                smooth={false}
                className={`${classes.afterHoverClass} ${classes.beforeHoverClass} ${classes.txtColor} ${classes.textDecororationNone}`}
              >
                Sign up / Sign in
              </Link>
            </li>
            <li>
              <Link
                to="features"
                spy={true}
                smooth={false}
                className={`${classes.afterHoverClass} ${classes.txtColor} ${classes.textDecororationNone}`}
              >
                Features
              </Link>
            </li>
            <li>
              <Link
                to="faq"
                spy={true}
                smooth={false}
                className={`${classes.afterHoverClass} ${classes.txtColor} ${classes.textDecororationNone}`}
              >
                FAQ
              </Link>
            </li>
            <li>
              <Link
                to="suport"
                spy={true}
                smooth={false}
                className={`${classes.afterHoverClass} ${classes.txtColor}`}
              >
                Support
              </Link>
            </li>
          </ul>
        </nav>
        <Row className={styles.aboutContentVideoWrap}>
          <Col lg={6} className={styles.aboutContentWrap}>
            <div className={styles.aboutContent}>
              <h2>{data?.feature_title}</h2>
              {ReactHtmlParser(data?.feature_desc)}
              <a
                href={() => false}
                className={`${styles.btn_knowmore} ${classes?.fullSchduleBtn} float-right d-none`}
                onClick={() => SetForm(true)}
              >
                {data?.feature_link}
              </a>

              <a
                href={() => false}
                className={`${styles.btn_knowmore} ${classes?.fullSchduleBtn} float-left`}
                onClick={() => handleScrollTo()}
              >
                Sign up / Sign in
              </a>
            </div>
          </Col>
          <Col lg={6} className={styles.aboutVideoWrap}>
            <div className={styles.video_wrap}>
              {data?.feature_promo ? (
                <ReactPlayer
                  url={data?.feature_promo}
                  width="100%"
                  height="100%"
                  controls={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload noplaybackrate',
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                  light={data?.image}
                  playing
                  playIcon={<span className={styles.btn_play}></span>}
                />
              ) : (
                <>
                  {data && data.image ? (
                    <figure>
                      <img src={data?.image} alt="Shalom world" />
                    </figure>
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SwPluseTabs
