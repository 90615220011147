import {
  FETCH_COMMON_SEARCH,
  FETCH_MENU_LIST,
  FETCH_SEC_MENU_LIST,
  FETCH_MOBMENU,
  FETCH_COMPLETE_SEARCH_START,
  FETCH_COMPLETE_SEARCH_SUCCESS,
  UPDATE_COMPLETE_SEARCH,
} from "./types";
import axios from "axios";
import salomapi, { salomapi2 } from "../apis";
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();
let cancelToken;

export const fetchPrimaryMenu = () => async (dispatch) => {
  async function fetchFromServer() {
    try {
      const response = await salomapi.get("menus");
      dispatch({ type: FETCH_MENU_LIST, payload: response.data.menu });
      await saveMenu(response.data.menu);
    } catch (error) {
      dispatch({ type: FETCH_MENU_LIST, payload: error });
      throw error;
    }
  }
  const localMenuData = await retrieveMenu();
  if (localMenuData)
    dispatch({ type: FETCH_MENU_LIST, payload: localMenuData });
  else await fetchFromServer();
  async function retrieveMenu() {
    return storage.getItem("menuList_v8");
  }

  async function saveMenu(menu) {
    return storage.setItem("menuList_v8", menu, 86400);
  }
};

export const fetchSecMenu = () => async (dispatch) => {
  const response = await salomapi.get("secondarynav");
  dispatch({ type: FETCH_SEC_MENU_LIST, payload: response.data.menu });
};
export const fetchMobMenu = () => async (dispatch) => {
  const response = await salomapi2.get("menus");
  dispatch({ type: FETCH_MOBMENU, payload: response.data.result });
};

/**
 * The following executes when user types in. Send axios cancel token for each requests.
 * Cancel previous requests if not completed when a new one is called. This secion makes it easy to use axios cancel request feature
 * If a request is not fulfilled, aborting/cancelling the request before a new request is made can help us update last api results to Redux.
 */

export const fetchCommonSearchResult = (search, type) => async (dispatch) => {
  if (search !== "") {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();

    const response = await salomapi.get(
      "/searchweb?s=" + search + "&type=" + type + "&page=1&perpage=10",
      { cancelToken: cancelToken.token }
    );
    dispatch({ type: FETCH_COMMON_SEARCH, payload: response.data });
  }
};

/**
 * The following executes when user hits on 'Enter' 'Return'. Send axios request.
 * FETCH_COMPLETE_SEARCH_START - mark status as 'loading'
 * FETCH_COMPLETE_SEARCH_SUCCESS - mark status as successfull. This secion makes it easy to use loader while request is processing
 */

export const fetchCompleteSearchResult =
  (search, type, page) => async (dispatch) => {
    let response;

    if (page > 1) {
      response = await salomapi.get(
        "/searchweb?s=" +
        search +
        "&type=" +
        type +
        "&page=" +
        page +
        "&perpage=10"
      );
      response.data.status === "success" &&
        dispatch({
          type: UPDATE_COMPLETE_SEARCH,
          payload: response.data.result,
        });
    } else {
      dispatch({ type: FETCH_COMPLETE_SEARCH_START });
      response = await salomapi.get(
        "/searchweb?s=" + search + "&type=" + type + "&page=1&perpage=10"
      );
      response.data.status === "success"
        ? dispatch({
          type: FETCH_COMPLETE_SEARCH_SUCCESS,
          payload: response.data ? response.data : [],
        })
        : dispatch({
          type: FETCH_COMPLETE_SEARCH_SUCCESS,
          payload: response.data ? response.data : [],
        });
    }
  };
