import { FETCH_CATEGORIES } from './types';
import { salomapi3 } from '../apis';
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();

export const fetchCategories = () => async dispach => {
    const response = await salomapi3.get('/program/categories', {
        params: {
            region: storage.getItem("CountryCode"),
            isoCountryCode: storage.getItem("IsoCountryCode"),
            timeZone: storage.getItem("TimeZoneCode"),
        },
    });

    dispach({ type: FETCH_CATEGORIES, payload: response.data.categories })
}