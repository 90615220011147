import React from "react";
import styles from "./PodcastBanner.module.scss";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import { Button } from "react-bootstrap";
import { useSeasonal } from '../../logic/Seasonal'

const PodcastBanner = ({ data, handleAddToPlaylist, handleSupportPodcast, handleScrollToEpisodes, seasonId }) => {
  useViewportAnimation();
  const { classes } = useSeasonal()

  const handlePlay = () => {
    handleScrollToEpisodes();
    handleAddToPlaylist()
  };

  return (
    <>
      <section className={styles.PodcastBanner}>
        <figure className="figImg">
          <img src={data?.bannerImg} alt={data?.title} />
        </figure>
        {data?.latestEpisode && <div className={`${styles.bannerContent} container`}>
          <div className={styles.episode_total_time}>
            <strong> {data.latestEpisode[seasonId]} Episodes</strong> <strong>{data?.totalDuration[seasonId]}</strong>
          </div>
          <h1>{data?.title}</h1>

          <div className={styles.btn_wrap}>
            <Button className={`web_btn btn btn-primary ${classes?.bgBtnClass}`} onClick={handlePlay}>Play Episodes</Button>
            <Button className={`web_btn btn btn-outline-primary ${classes?.fullSchduleBtn}`} onClick={handleSupportPodcast}>Support Podcasts</Button>
          </div>

        </div>}

      </section>
    </>
  );
};

export default PodcastBanner;
