import styles from "./SignInTab.module.scss";
import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormik } from "formik";

import { salomapi3 } from "../../apis";
import { useSeasonal } from "../../logic/Seasonal";
import { useHistory } from "react-router-dom";
const ResetPassword = ({ setScreen, userId }) => {
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const { seasonal, classes } = useSeasonal();
  const regexp = /^\S*$/;
  const history = useHistory();
  const validate = (values) => {
    let errors = {};
    if (!values.password) {
      errors.password = "Required";
    }
    if (!values.repassword) {
      errors.repassword = "Required";
    }
    if (values.password && values.repassword) {
      if (values.password !== values.repassword) {
        errors.mismatch = "Password mismatch";
      }
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      repassword: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        newPassword: values.password,
        rePassword: values.repassword,
        userId: userId,
      };
      salomapi3.post(`/media/reset/password`, postData).then((res) => {
        if (res.data.status == "success") {
          setErrorMsg("");
          setFormSuccess(true);
          setSuccessMsg(res.data.msg);
          resetForm({});
          setTimeout(() => {
            setScreen("signIn");
            history.replace("/swplus");
          }, 3000);
        } else if (res.data.status == "error") {
          setErrorMsg(res.data.msg);
          setFormSuccess(false);
        }
      });
    },
  });

  return (
    <div className={`${styles.gallery_signup} ${styles.forgotPassword}`}>
      <h3>Reset Your Password</h3>
      <form className={styles.form_align} onSubmit={formik.handleSubmit}>
        <Row>
          <Col sm={12}>
            <div
              className={`${styles.textClass} ${formik.errors.password && formik.submitCount && styles.formerror
                }`}
            >
              <input
                type="password"
                onChange={formik.handleChange}
                value={formik.values.email}
                name="password"
                placeholder="Enter Your new Password"
              ></input>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div
              className={`${styles.textClass} ${formik.errors.repassword &&
                formik.submitCount &&
                styles.formerror
                }`}
            >
              <input
                type="password"
                onChange={formik.handleChange}
                value={formik.values.email}
                name="repassword"
                placeholder="Re Enter your new Password"
              ></input>
            </div>
          </Col>
        </Row>
        <div className={styles.btns_wrap}>
          <button
            type="submit"
            className={`${styles.signup_btn} ${classes?.bgBtnClass}`}
          >
            SUBMIT
          </button>
          <button
            className={`${styles.signup_btn} ${classes?.bgBtnClass}`}
            onClick={() => {
              history.replace("/swplus");
            }}
          >
            CANCEL
          </button>
        </div>

        {!formik.errors.mismatch &&
          Object.keys(formik.errors).length != 0 &&
          formik.submitCount ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>
              Please fill all required fields.
            </span>
          </div>
        ) : null}
        {formik.errors.mismatch && formik.submitCount ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>
              Password mismatch
            </span>
          </div>
        ) : null}
        {isFormSuccess ? (
          <div className="form-group col-sm-12">
            <span className={styles.resonseSuccessMsg}> {successMsg}</span>
          </div>
        ) : null}
        {errorMsg ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>{errorMsg}</span>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default ResetPassword;
