import React from "react";
import styles from "./NewSwPluseSupport.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useSeasonal } from "../../logic/Seasonal";
function SwPluseSupport({ data, SetSupport, viewSupport }) {
  const { seasonal, classes } = useSeasonal();
  return (
    <div
      className={styles.need_help_wrap}
      id="suport"
      style={{ backgroundImage: `url(${data.support_image})` }}
    >
      <Container>
        <Row className="justify-content-end">
          <Col className={styles.need_help_content}>
            <h2>{data?.support_title}</h2>
            <p>{data?.support_desc}</p>
            {/* <a
              className={styles.btn_support}
              onClick={() => SetSupport(!viewSupport)}
            >
              Support
            </a> */}
            {/* {viewSupport && ( */}
            <>
              <div className={styles.support_details}>
                <p>
                  Tel :{" "}
                  <a href={"tel:" + data?.support_phone} className={classes?.txtColor}>
                    {data?.support_phone}
                  </a>
                </p>
                <p>
                  Email :{" "}
                  <a href={"mailto:" + data?.support_email}  className={classes?.txtColor}>
                    {data?.support_email}
                  </a>
                </p>
              </div>
            </>
            {/* )} */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SwPluseSupport;
