import React from "react";
import styles from "./NewSwPluseFeature.module.scss";
import { Container, Row, Col } from "react-bootstrap";
// import imgFeature from "../../assets/img/imgFeature.jpg";
// import iconFeature1 from "../../assets/img/iconFeature1.svg";
// import iconFeature2 from "../../assets/img/iconFeature2.svg";
// import iconFeature3 from "../../assets/img/iconFeature3.svg";
// import iconFeature4 from "../../assets/img/iconFeature4.svg";
// import iconFeature5 from "../../assets/img/iconFeature5.svg";
// import iconFeature6 from "../../assets/img/iconFeature6.svg";
// import iconFeature7 from "../../assets/img/iconFeature7.svg";
// import iconFeature8 from "../../assets/img/iconFeature8.svg";
// import iconFeature9 from "../../assets/img/iconFeature9.svg";
// import iconFeature10 from "../../assets/img/iconFeature10.svg";
// import iconFeature11 from "../../assets/img/iconFeature11.svg";
// import iconFeature12 from "../../assets/img/iconFeature12.svg";

import ReactHtmlParser from "react-html-parser";
function NewSwpluseFeature({ featureData }) {
  let { s_feature_title, s_feature_content, s_feature_1, s_feature_2 } =
    featureData;
  return (
    <section className={styles.swplusFeaturesWrap} id="features">
      <Container>
        <div className={styles.featureContent}>
          {ReactHtmlParser(s_feature_title)}
          {/* <h2>{s_feature_title}</h2> */}
          <p>{s_feature_content}</p>
        </div>
        <Row>
          <Col lg={4} className={styles.featureImg}>
            <figure>
              <img src={featureData?.s_feature_image} alt="Features" />
            </figure>
          </Col>
          <Col lg={4} className={styles.featuresList}>
            <h4>{s_feature_1}</h4>
            <ul>
              {featureData?.s_feature_content_1?.map(({ data, icon }) => {
                return (
                  <li key={data}>
                    <figure>
                      <img src={icon} alt="Features" />
                    </figure>
                    <span>{data}</span>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col lg={4} className={styles.featuresList}>
            <h4>{s_feature_2}</h4>
            <ul>
              {featureData?.s_feature_content_2?.map(({ data, icon }) => {
                return (
                  <li key={data}>
                    <figure>
                      <img src={icon} alt="Features" />
                    </figure>
                    <span>{data}</span>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default NewSwpluseFeature;
