import React, { useState, useEffect, lazy, useCallback, useRef } from "react";
import StoryForm from "../StoryForm";
import { connect } from "react-redux";
import { fetchXmas } from "../../actions/xmasAction";
import { Container, Row, Col, Form } from "react-bootstrap";
import XmasVideos from "../XmasVideos";
import styles from "./Xmas.module.scss";
import imgXmas from "../../assets/img/xmas_deer.svg";
import Rodal from "rodal";
// include styles
import "rodal/lib/rodal.css";
import { Formik, Field, ErrorMessage, useFormik } from "formik";
import salomapi from "../../apis";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  fetchCountryList,
  fetchStateFromCountry,
} from "../../actions/swprayerAction";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "white",
    },
  },
}));

const ChristmasPage = ({
  fetchXmas,
  fetchCountryList,
  xmasData,
  countryList,
}) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryInputName, setCountryInputName] = useState("");
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [error, setError] = useState("");
  const [title, setTitle] = useState({});

  useEffect(() => {
    // if(fellowship.length ==0)
    fetchXmas();
  }, []);
  useEffect(() => {
    // if(fellowship.length ==0)
    fetchCountryList();
  }, []);
  const CountryChange = useCallback((event, newValue) => {
    if (newValue) {
      fetchStateFromCountry(newValue.id);
    }
  }, []);
  const CountryInputChange = useCallback((event, newInputValue) => {
    setCountryInputName(newInputValue);
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "Required";
    } else if (values.firstname.length > 15) {
      errors.firstname = "Must be 15 characters or less";
    }

    if (!values.lastname) {
      errors.lastname = "Required";
    } else if (values.lastname.length > 20) {
      errors.lastname = "Must be 20 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (countryInputName == "") {
      errors.country = "Required";
    }

    return errors;
  };
  const show = (value) => {
    const obj = { ...value };
    setTitle(obj);
    setVisible(true);
  };

  const hide = () => {
    setTitle({});
    setVisible(false);
  };

  const handleDownload = (pdf) => {
    let link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", title.pdf);
    document.body.appendChild(link);

    link.click();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",

      country: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        country: countryInputName,
      };

      var dataNew = new FormData();
      dataNew.append("firstname", values.firstname);
      dataNew.append("lastname", values.lastname);
      dataNew.append("email", values.email);
      dataNew.append("country", countryInputName);

      salomapi
        .post(`/christmaspagedownloads?type=${title.title}`, dataNew)
        .then((response) => {
          if (response.data.status === "success") {
            setFormSuccess(true);
            resetForm({});

            setState("");

            CountryInputChange("", "");
            setError("");
            handleDownload();
            hide();
          } else {
            setFormSuccess(false);
            setError(response.data.msg);
          }

          setTimeout(() => {
            setFormSuccess(false);
          }, 3000);
        });
    },
  });

  const alertClose = () => {
    setFormSuccess(false);
    window.scrollTo(0, 1150);
  };

  return (
    <>
      <div className={styles.xmasWrapper}>
        <div className={styles.xmasBanner}>
          <figure>
            <img src={xmasData?.banner?.bannerImage} alt="Christmas Banner" />
            {/* <figcaption>
            Your Story Has the Power to Empower
            </figcaption> */}
          </figure>
          <a
            href="#your-story"
            className={`${styles.banner_form_btn} btn btn-primary`}
          >
            Submit Your Story
          </a>
        </div>
        <XmasVideos />
        <StoryForm xmasData={xmasData?.storyForm} />

        <div className={styles.xmas_package_wrap}>
          <figure className={styles.floatingXmasImg}>
            <img src={imgXmas} />
          </figure>

          <Container>
            <h2>Download Your Gift from Shalom World </h2>
            <Row className={styles.package_lists}>
              {xmasData?.downloads?.map((value) => {
                return (
                  <Col xs={12} md={4} className={styles.package_wrap}>
                    <figure>
                      <img src={value.thumbnail} />
                    </figure>
                    <h3>{value.title}</h3>
                    <p>{value.description}</p>

                    {value.pdf ? (
                      <button
                        onClick={() => show(value)}
                        className={styles.dwnld_btn}
                      >
                        {value.buttonText}
                      </button>
                    ) : (
                      <a
                        href={value.link}
                        target="_blank"
                        className={styles.dwnld_btn}
                      >
                        {" "}
                        {value.buttonText}
                      </a>
                    )}

                    <div>
                      <Rodal
                        visible={visible}
                        onClose={hide}
                        width="840"
                        height="400"
                        animation="slideUp"
                      >
                        <div className={styles.formWrapper}>
                          {" "}
                          <Form
                            className={styles.form}
                            onSubmit={formik.handleSubmit}
                          >
                            <Row>
                              <Col xs={12} lg={6}>
                                <input
                                  placeholder="First name *"
                                  onChange={formik.handleChange}
                                  value={formik.values.firstname}
                                  name="firstname"
                                  className={
                                    !formik.errors.firstname ||
                                      !formik.submitCount
                                      ? styles.input
                                      : styles.input + " " + styles.formErrReact
                                  }
                                ></input>
                              </Col>

                              <Col xs={12} lg={6}>
                                <input
                                  placeholder="Last name *"
                                  onChange={formik.handleChange}
                                  value={formik.values.lastname}
                                  name="lastname"
                                  className={
                                    !formik.errors.lastname ||
                                      !formik.submitCount
                                      ? styles.input
                                      : styles.input + " " + styles.formErrReact
                                  }
                                ></input>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} lg={6}>
                                <Autocomplete
                                  id="combo-box-demo"
                                  classes={classes}
                                  options={countryList}
                                  getOptionLabel={(option) =>
                                    option.country_name
                                  }
                                  renderOption={(option) => (
                                    <div style={{ color: "white" }}>
                                      {option.country_name}
                                    </div>
                                  )}
                                  inputValue={countryInputName}
                                  onInputChange={CountryInputChange}
                                  onChange={CountryChange}
                                  renderInput={(params) => (
                                    <TextField
                                      className={
                                        !formik.errors.country ||
                                          !formik.submitCount
                                          ? styles.input
                                          : styles.input +
                                          " " +
                                          styles.formErrReact
                                      }
                                      {...params}
                                      placeholder="Select Country"
                                      name="country"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                {/* <input placeholder="Select Country *" className={styles.input}></input> */}
                              </Col>

                              <Col xs={12} lg={6}>
                                <input
                                  placeholder="Email *"
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                  name="email"
                                  className={
                                    !formik.errors.email || !formik.submitCount
                                      ? styles.input
                                      : styles.input + " " + styles.formErrReact
                                  }
                                ></input>
                              </Col>
                            </Row>

                            <Row>
                              {Object.keys(formik.errors).length != 0 &&
                                formik.submitCount ? (
                                <div className="form-group col-sm-12">
                                  <span className={styles.formtotNullfieldErr}>
                                    Please fill all required fields in a valid
                                    format.
                                  </span>
                                </div>
                              ) : null}
                            </Row>
                            <Row>
                              <Col xs={12} lg={6}>
                                <button
                                  disabled={
                                    formik.isSubmitting ||
                                    Object.keys(formik.errors).length > 0
                                  }
                                  type="submit"
                                  className={styles.prayer_request_btn}
                                // onClick={() =>

                                //   !formik.isSubmitting &&
                                //   Object.keys(formik.errors).length === 0 &&
                                //   handleDownload(value.pdf)
                                // }
                                >
                                  Download
                                </button>
                              </Col>
                              <Col>
                                <a
                                  onClick={hide}
                                  className={styles.prayer_request_btn}
                                >
                                  Cancel
                                </a>
                              </Col>
                            </Row>
                          </Form>
                          <div
                            id="altertMsg"
                            className={styles.alertMsgWrapper}
                          >
                            {isFormSuccess ? (
                              <div alertClose={alertClose}>
                                Successfully updated
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Rodal>
                    </div>

                    {/* <a href={value.pdf}  target="_blank"  className={styles.dwnld_btn}> {value.buttonText}</a> */}
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapPropsToState = (state) => {
  return { countryList: state.swprayer.countryList, xmasData: state.xmasData };
};
export default connect(mapPropsToState, { fetchCountryList, fetchXmas })(
  ChristmasPage
);
