import React, { useState } from "react";
import styles from "./PodcastEpisodeThumb.module.scss";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import { Link } from "react-router-dom";
import PodcastShareModal from "../PodcastShareModal";
import { useSeasonal } from "../../logic/Seasonal";

const PodcastEpisodeThumb = ({
  episode,
  podcastSlug,
  handlePlay,
  handleAddToPlaylist,
  handleUpdatePlaylist,
  activeId,
  progress,
  podCastPlayListData,
  play,
  handleClickUrl,
}) => {
  useViewportAnimation();

  const [isLoaded, setLoaded] = useState(false);
  const [showShareModal, setShareModal] = useState(false);
  const [message, setMessage] = useState("");
  const { classes } = useSeasonal();

  const playToggleClass = (id) => {
    if (id === activeId) {
      handlePlay(!play);
    } else {
      handleUpdatePlaylist(id);
    }
  };

  const onLoad = () => {
    setLoaded(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    return;
  };

  const formatTime = (duration) => {
    let arr = duration.split(":"); // splitting the string by colon
    arr[0] = arr[0] !== "" ? `${arr[0]} hr` : "";
    arr[1] = arr[1] !== "" ? `${arr[1]} min` : "";
    arr[2] = arr[2] !== "" ? `${arr[2]} Sec` : "";
    let newVal = arr.join(" ");
    return newVal.replace("00 hr", "");
  };

  const handleSetMessage = (msg) => {
    setMessage(msg);
  };

  return (
    <>
      <div
        className={`${styles.PodcastEpisodeThumb} ${play && activeId === episode?.id ? styles.active : ""
          }  animation`}
      >
        <Link
          to={`/podcast/${podcastSlug}/${episode?.url}`}
          onClick={(e) => {
            handleClickUrl(episode);
          }}
        >
          <div
            className={`${isLoaded ? "loaded" : null} ${styles.episodeWrap}`}
            onLoad={onLoad}
          >
            <figure className="figImg">
              <img src={episode?.thumbnail?.small} alt={episode?.title} />
            </figure>

            <div className={styles.episodeThumbContent}>
              <h3 className={styles.episodeTitle}>{episode?.title}</h3>

              <article className={styles.short_desc}>
                {episode?.description &&
                  episode?.description
                    .replace(/(<([^>]+)>)/gi, "")
                    .substring(0, 300)}
              </article>

              <div className={styles.episodeAudioStatus}>
                <div className={styles.audioDtls}>
                  <span
                    className={`play-icon ${play && activeId === episode.id ? "pause" : ""
                      } ${classes.podcastPlayClass} `}
                    onClick={(e) => {
                      handleClick(e);
                      playToggleClass(episode.id);
                    }}
                  >
                    {play && activeId === episode.id ? (
                      // play
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="12"
                        viewBox="0 0 9 12"
                      >
                        <g transform="translate(-323 -1192)">
                          <rect
                            width="3"
                            height="12"
                            rx="1"
                            transform="translate(323 1192)"
                          />
                          <rect
                            width="3"
                            height="12"
                            rx="1"
                            transform="translate(329 1192)"
                          />
                        </g>
                      </svg>
                    ) : (
                      // pause
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                      >
                        <path
                          d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z"
                          transform="translate(10) rotate(90)"
                          fill="#ebab0b"
                        />
                      </svg>
                    )}
                  </span>
                  <span className={styles.date}> {episode?.date} </span>
                  <span className={styles.time}>
                    {activeId === episode.id
                      ? progress?.remain
                      : formatTime(episode?.duration)}
                  </span>
                  <div className={styles.play_progress}>
                    {activeId === episode.id && progress?.percentage > 1 && (
                      <div className={`progress-bar `}>
                        <div
                          className={`progress ${classes.seasonalClass}`}
                          style={{
                            width:
                              activeId === episode.id
                                ? progress?.percentage + "%"
                                : "0%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.episodeShare}>
                  <div
                    className="share_btn"
                    onClick={(e) => {
                      handleClick(e);
                      setShareModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.49"
                      height="22.673"
                      viewBox="0 0 20.49 22.673"
                    >
                      <g
                        id="Component_54_1"
                        data-name="Component 54 – 1"
                        transform="translate(0.7 0.765)"
                      >
                        <g id="Group_4346" data-name="Group 4346">
                          <g id="Group_4331" data-name="Group 4331">
                            <path
                              id="Path_4380"
                              data-name="Path 4380"
                              d="M-54.467,317.206v12.6a1.053,1.053,0,0,0,1.075,1.029H-36.45a1.052,1.052,0,0,0,1.074-1.029v-12.6"
                              transform="translate(54.467 -309.626)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="1.4"
                            />
                            <g
                              id="Group_4331-2"
                              data-name="Group 4331-2"
                              transform="translate(5.406)"
                            >
                              <path
                                id="Path_4381"
                                data-name="Path 4381"
                                d="M-46.109,321.01V309.376"
                                transform="translate(50.214 -308.903)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="square"
                                strokeWidth="1.4"
                              />
                              <path
                                id="Path_4382"
                                data-name="Path 4382"
                                d="M-50.214,312.874l3.856-3.856a.4.4,0,0,1,.557,0l3.855,3.856"
                                transform="translate(50.214 -308.903)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="square"
                                strokeWidth="1.4"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <PodcastShareModal
        show={showShareModal}
        handleSetMessage={handleSetMessage}
        onHide={(e) => {
          setShareModal(false);
          setMessage();
        }}
        message={message}
        episode={episode}
        podcastSlug={""}
        copyLinkText={
          window.location.origin +
          "/podcast/" +
          episode?.podcastSlug +
          "/" +
          episode?.url
        }
      />
    </>
  );
};

export default PodcastEpisodeThumb;
