import React, { lazy } from "react";
import "./CommonStyle.scss";
import { useDimensions } from "../../logic/Dimentions";
import { useSeasonal } from "../../logic/Seasonal";
import Publication from "../Publication";

const Menu = lazy(() => import("../Menu"));
const RelatedSites = lazy(() => import("../RelatedSites"));
const ShalomTvApp = lazy(() => import("../ShalomTvApp"));
const Subscription = lazy(() => import("../Subscription"));
const Footer = lazy(() => import("../Footer"));
const MobileMenu = lazy(() => import("../MobileMenu"));
const BottomTab = lazy(() => import("../BottomTab"));

const DonationLayout = ({ children }) => {
    const { width } = useDimensions();
    const { classes } = useSeasonal();
    return (
        <>
            {width >= 1025 ? <Menu /> : <MobileMenu />}
            <div>{children}</div>

            {width >= 1025 ? (
                <>
                    {/* <Donate/> */}
                    <ShalomTvApp />
                    <Publication themeClasses={classes} />
                    <RelatedSites />
                    <Subscription />
                    <Footer />
                </>
            ) : (
                <BottomTab />
            )}
        </>
    );
};

export default DonationLayout;
