/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import styles from './CountryDropdown.module.scss'
import BrowserPersistence from '../../util/simplePersistance'
import Au from '../../assets/img/flags/au.png'
import Ca from '../../assets/img/flags/ca.png'
import Eu from '../../assets/img/flags/eu.png'
import Ie from '../../assets/img/flags/ie.png'
import Uk from '../../assets/img/flags/uk.png'
import Us from '../../assets/img/flags/us.png'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { useSeasonal } from '../../logic/Seasonal';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
const storage = new BrowserPersistence()

const CountryDropDown = ({
  selectCountry,
  country,
  isMobileMenu,
  dropdownOpen,
  setDropdownOpen,
  onClickAway,
}) => {

  let history = useHistory()
  //const classes = useStyles()
  useEffect(() => {
    if (!storage.getItem('CountryCode')) {
      selectCountry('us')
      // storage.setItem("CountryCode", 'us', 86400)
    } else {
      selectCountry(storage.getItem('CountryCode'))
      const countryArr = ['us', 'eu', 'ca', 'au', 'ir', 'all']
      if (countryArr.includes(country)) {
        selectCountry('all')
      }
    }
  }, [])

  const selectCountrySelection = (value) => {
    selectCountry(value)
    setDropdownOpen(false)
    if (isMobileMenu) {
      storage.setItem("CountryCode", value, 10)
      history.push('')
      window.location.reload()
    }
  }
  const { seasonal, classes } = useSeasonal();

  return (
    <div className={styles.ddlCountry}>
      <span>
        <FormControl variant="filled">
          <Select
            open={dropdownOpen}
            inputProps={{ onBlur: (evt) => onClickAway(evt) }}
            onBlur={onClickAway}
            onClick={onClickAway}
            //inputProps={{ "aria-label": "Without label" }}
            value={country}
            onChange={(e) => selectCountrySelection(e.target.value)}
            style={{ color: 'white', margin: '0' }}
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {!isMobileMenu ? (
              <MenuItem value="all" className={classes?.hoverColor}>
                <FaMapMarkerAlt /> All
              </MenuItem>
            ) : null}
            <MenuItem value="us" className={classes?.hoverColor}>
              <img src={Us} alt="USA" /> USA
            </MenuItem>
            <MenuItem value="eu" className={classes?.hoverColor}>
              <img src={Uk} alt="UK" /> UK
            </MenuItem>
            <MenuItem value="ca" className={classes?.hoverColor}>
              <img src={Ca} alt="CAN" /> CAN
            </MenuItem>
            <MenuItem value="au" className={classes?.hoverColor}>
              <img src={Au} alt="AUS" /> AUS
            </MenuItem>
            <MenuItem value="ir" className={classes?.hoverColor}>
              <img src={Ie} alt="IRE" /> IRE
            </MenuItem>
          </Select>
        </FormControl>
      </span>
    </div>
  )
}
export default CountryDropDown
