import React from 'react';
import styles from './CountryDataCards.module.scss';
import ReactHtmlParser from 'react-html-parser';
import { useSeasonal } from '../../../logic/Seasonal';

const CountryDataCards = ({cardData}) => {
    const {seasonal,classes} = useSeasonal();
    return(
        <div className={styles.car_donate_features}>
             {cardData.length>0 ?(
                 cardData.map((card) =>{
                     return(
                        <div className={`${styles.features_list_box} ${classes?.contentLinkColor}`}>
                            <div className="box_center">
                                <figure>
                            
                                <img src={card.icon} />
                                </figure><h5>{card.title}</h5><p>{ReactHtmlParser(card.content)}</p>
                            </div>
                        </div>
                     )
                    
                 })
             ):null }
          
                    {/* <div className={styles.features_list_box}><div className="box_center"><figure>
                        <img src="https://ark.shalomworld.org/wp-content/uploads/2021/06/tow-truck-1.png" /></figure>
                        <h5>FREE TOWING</h5>
                        <p>
                            Your vehicle will be picked up at your convenience, at no cost to you. Please have your vehicle title available at the time of pickup.
                            </p></div></div>
                            <div className={styles.features_list_box}><div className="box_center"><figure>
                                <img src="https://ark.shalomworld.org/wp-content/uploads/2021/06/Group-9703.png" /></figure>
                                <h5>TAX-DEDUCTIBLE</h5>
                                <p>Once the vehicle is sold, you will receive a tax receipt. Please note that the sale process may take about four to twelve weeks.</p>
        </div></div> */}
        </div>
    )
}
export default CountryDataCards;