import React from "react";
import styles from './EpisodeListShimmer.module.scss';


const EpisodeListShimmer = () => {
  return (
      <div className={`${styles.episodeListShimmer} col-md-4 col-sm-12`}>
         <div className={`${styles.shimmerImg} Shimmer `}></div>
         <div className={`${styles.shimmerTtl} Shimmer `}></div>
        
      </div>
  );
};

export default EpisodeListShimmer;
