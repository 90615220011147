import React, { useState, useRef, useCallback } from 'react';
import styles from './NewsDetails.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useFormik } from 'formik';
import { useHistory, useLocation } from "react-router-dom";
import { IoChevronBack } from 'react-icons/io5'
import GoogleRecaptcha from '../Recaptcha';
import StickyNewsHeader from '../StickyNewsHeader';
import { salomapi4 } from '../../apis';
import { useDimensions } from '../../logic/Dimentions';
import { useSeasonal } from '../../logic/Seasonal';
import BlogCommentList from './BlogCommentList';

const NewsDetails = ({ newsDetails }) => {
  const location = useLocation();
  const captcha = useRef(null);
  const { classes } = useSeasonal();
  let history = useHistory();

  const [isFormSuccess, setFormSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { width } = useDimensions();
  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaValid, isCaptchaValid] = useState(false);
  const [validUser, isValidUser] = useState(false);
  const [category, setCatgeory] = useState("all")

  const SetCatgeoryData = useCallback((category) => {
    setCatgeory(category)
    history.push({
      pathname: "/news",
      state: { category }
    });
  }, [setCatgeory])

  const blogContent = newsDetails ? newsDetails?.blogContent?.replace(
    /(?:\r\n|\r|\n)/g,
    "<br>"
  ) : null

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.description) {
      errors.description = 'Required';
    }
    if (!captchaValid) {
      errors.captchaValid = 'Please valid the Captcha'
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      if (captchaValid) {
        salomapi4.post('/comments?author_name=' + values.name + '&content=' + values.description + '&post=' + newsDetails.blogId).then(response => {
          setErrorMsg("")
          resetForm({})
          setFormSuccess(true)
          setCaptchaReset(true);
          isCaptchaValid(false);
          setTimeout(() => {
            setFormSuccess(false)
            setCaptchaReset(false);
          }, 4000)
        })
          .catch(err => {
            setErrorMsg(err.response.data.message)
          })
      }

      if (captcha.current.getValue()) {

        isValidUser(true);
        isCaptchaValid(true);
      }
      else {

        isValidUser(false);
        isCaptchaValid(false);
      }
    },
  });

  const handleOnChange = () => {
    isCaptchaValid(true);
  }

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.detail_page}>
      {width >= 992 ? <StickyNewsHeader SetCatgeoryData={SetCatgeoryData} category={category} /> : null}

      <Container>

        {width >= 992 ?
          <>
            {/* Go back button if coming from events */}
            {location && location.state && location?.state?.from.indexOf("/events/") !== -1 && (
              <a title='Back' className={styles.btn_back}>
                <IoChevronBack onClick={handleGoBack}></IoChevronBack>
              </a>
            )}

            <div className={styles.cover_wrap}>
              <img src={newsDetails ? newsDetails.blogImage : null} alt="" />
            </div>
            <div className={styles.blog_intro}>
              <div className={styles.intro_head}>
                <p className={`${styles.blog_cat} ${classes?.backgroundColr}`}>{newsDetails && newsDetails?.category?.length > 0 ? newsDetails?.category[0].category : null}</p>&nbsp;&nbsp;&nbsp;
                <div className={styles.blog_auth}>
                  <span></span>
                  <p>By
                    <label>
                      <a href={() => false}>{newsDetails ? newsDetails.authorName : null}</a>
                    </label>
                  </p>
                </div>&nbsp;
                <p className={styles.blog_date}>{newsDetails ? newsDetails.createdDate : null}</p>&nbsp;
              </div>
              <p className={styles.intro_cont}>
                {newsDetails ? newsDetails.blogTitle : null}
              </p>
            </div>
          </> : <div className={styles.newMobile}>
            {(newsDetails && newsDetails.category) &&
              <>
                <div className="news-detail-img">
                  <img src={newsDetails ? newsDetails.blogImage : null} alt="" />
                  <span className={classes?.backgroundColr}>{newsDetails?.category[0]?.category}</span>
                </div>
                <h5>{newsDetails?.blogTitle}</h5>
                <p>{newsDetails?.createdDate} <label>{newsDetails?.authorName}</label></p></>}
          </div>}

        {blogContent ? (

          <div className={styles.blog_cont} dangerouslySetInnerHTML={{ __html: blogContent }}>
          </div>

        ) : null}

        <div className={styles.commentBoxBlogWrap}>
          <h5 className={styles.cmntBox_head}>Responses</h5>
          <div className={styles.commentBox}>
            <form className={styles.form_align} onSubmit={formik.handleSubmit}>
              <Row className={`col-lg-12`}>
                <textarea className={styles.textClass} onChange={formik.handleChange} value={formik.values.description} name="description" placeholder="Leave a message"></textarea>
              </Row>
              <Row className={`col-lg-12`}>
                <input type="text" onChange={formik.handleChange} value={formik.values.name} name="name" placeholder="Please Enter Your Name" required />
              </Row>
              <Row className={`col-lg-12`}>
                <GoogleRecaptcha onChange={handleOnChange} isCaptchaReset={captchaReset} />
              </Row>
              <Row className={`col-lg-12`}>
                <button className={`${classes?.backgroundColr} ${classes.pauseClass} ${styles.coment_sub_btn} `} type="submit">Submit Comment</button>
              </Row>
              {Object.keys(formik.errors).length != 0 && formik.submitCount ?
                <div className="form-group col-sm-12">
                  <span >Please enter your comment before submit.</span>
                </div> : null
              }
              {isFormSuccess ?
                <div className="form-group col-sm-12">
                  <span className={styles.resonseSuccessMsg}>Your comment has been submitted for the review</span>
                </div> : null
              }
              {errorMsg ?
                <div className="form-group col-sm-12">
                  <span className={styles.resonseFailedMsg}>{errorMsg}</span>
                </div> : null
              }

            </form>
            <BlogCommentList newsDetails={newsDetails} classes={classes} />
          </div>
        </div>
      </Container>
    </div>
  )

}


export default NewsDetails;