import { SHORT_FILM_CONTEST } from "../actions/types";

const INTIAL_STATE = [];

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SHORT_FILM_CONTEST:
      return action.payload;
    default:
      return state;
  }
};
