import { FETCH_CAREERS, FETCH_MORE_JOBS, FETCH_CAREER_DETAILS, CLEAR_CAREERS } from './types';
import salomapi from '../apis';

export const fetchCareers = () => async dispatch => {
    const response = await salomapi.get('/career');
    dispatch({ type: FETCH_CAREERS, payload: response.data, maxPage: response.data.jobs.pages })
}
export const fetchMoreJobs = (page, data) => async dispatch => {
    let resp = []
    const response = await salomapi.get('/list/career?page=' + page + '&perpage=6');
    if (page === 2)
        resp = data.concat(response.data.career)
    else
        resp = response.data.career
    dispatch({ type: FETCH_MORE_JOBS, payload: resp, page: page })
}
export const fetchCareerDetails = (url) => async dispatch => {
    const response = await salomapi.get('/career/single?url=' + url);

    dispatch({ type: FETCH_CAREER_DETAILS, payload: response.data })
}

export const clearJobs = () => async dispatch => {

    dispatch({ type: CLEAR_CAREERS, payload: [] })
}