import React from "react";
import styles from "./PodcastEpisodes.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import PodcastEpisodeList from "../PodcastEpisodeList";
import AboutPodcast from "../AboutPodcast";

const PodcastEpisodes = ({
  data,
  handleAddToPlaylist,
  handleUpdatePlaylist,
  activeId,
  progress,
  play,
  handlePlay,
  podCastPlayListData,
  handleClickUrl,
  setSeasonId,
  seasonId,
  handleSeasonChange,
  loadMoreEpisodes,
}) => {


  return (
    <>
      <div className={styles.PodcastEpisodesWrapper}>
        <Container>
          <Row>
            <Col lg={8} className={styles.PodcastEpisodes}>
              <div className={styles.episodes_seasons}>
                {data && <h2>Episodes</h2>}

                {data && data?.seasons && data?.seasons.length > 1 && (
                  <div className={`${styles.ddlSeason}`}>
                    <select onChange={(e) => {
                      setSeasonId(e.target.value)
                      handleSeasonChange(e.target.value)
                    }}>
                      {data?.seasons.map((season, index) =>
                        <option value={index} key={index}>{season?.title}</option>)
                      }
                    </select>
                  </div>
                )}
              </div>

              {data?.seasons[seasonId] && <PodcastEpisodeList
                data={data}
                podcastSlug={data?.url}
                handleAddToPlaylist={handleAddToPlaylist}
                handleUpdatePlaylist={handleUpdatePlaylist}
                activeId={activeId}
                progress={progress}
                play={play}
                handlePlay={handlePlay}
                podCastPlayListData={podCastPlayListData}
                handleClickUrl={handleClickUrl}
                setSeasonId={setSeasonId}
                seasonId={seasonId}
                loadMoreEpisodes={loadMoreEpisodes}
              />
              }


            </Col>

            <Col lg={4} className={styles.aboutPodcastSidebar}>
              {data?.title && <AboutPodcast data={data} />}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PodcastEpisodes;
