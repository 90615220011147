import styles from './MonthlyPledge.module.scss'
import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import AddressForm from '../AddressForm'

const MonthlyPledge = ({ MonthlyDescription, clubLevels, classes }) => {
  const [isDisabled, setDisabled] = useState(true)
  const [monthValue, setMonthValue] = useState('')
  const [value, setValue] = React.useState(0);

  const onChangeValue = (event) => {
    if (!event.target.value) {
      setMonthValue(event.target.value)
      setDisabled(false)
    } else {
      setMonthValue(event.target.value)
      setDisabled(true)
    }
  }
  const changeMonthAmount = (e) => {

    if (!isNaN(e.target.value) && e.target.value > 0) {
      setMonthValue(e.target.value)
    } else {
    }
  }
  const currentlyClicked = (e) => {
  }
  return (
    <>
      <div className={styles.spfmail_content}>
        <Container>
          <p>{MonthlyDescription}</p>
          <div className={styles.clublevel_wrap}>
            <h5>Select any amount.</h5>
            <ul>
              {clubLevels && clubLevels.length > 0
                ? clubLevels.map((level, index) => {
                  return (
                    <li key={index}>
                      <input
                        type="radio"
                        id={`r${index}`}
                        value={level.level}
                        name="abcd"
                        onClick={onChangeValue}
                      />
                      <label for={`r${index}`}>
                        <span>
                          {level.level} {level.levelType}
                        </span>
                      </label>
                    </li>
                  )
                })
                : null}
            </ul>
          </div>
          <div className={styles.month_amt}>
            <h5>Monthly Amount USD</h5>
            <input
              type="number"
              min="0"
              disabled={isDisabled}
              value={monthValue}
              onChange={changeMonthAmount}
              name="monthlyAmount"
            ></input>
          </div>
        </Container>
      </div>
      <AddressForm monthlyValue={monthValue} tabValue={'Monthly'} newClasses={classes} />
    </>
  )
}

export default MonthlyPledge
