import React from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './SwprayerTab.module.scss'
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';
import { useHistory } from "react-router-dom";
import { useSeasonal } from '../../logic/Seasonal';


const SwprayerTab = ({value, setValue}) => {
    let history = useHistory();
    const {seasonal,classes} = useSeasonal();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue==0){
            history.push('/swprayer/todaymass')
        }else if(newValue==1){
            history.push('/swprayer/masspetition')
        }else if(newValue==2){
            history.push('/swprayer/prayerrequest')
        }else if(newValue==3){
            history.push('/swprayer/testimony')
        }else if(newValue==4){
            history.push('/swprayer/about')
        }
      };
  const { height, width } = useDimensions();


    return (
        
        width>=992?
            <div className={styles.Container}>
                 <div className="container">
                <Tabs aria-label="simple tabs example" onChange={handleChange} value={value} className={`${styles.tabContainer}  ${classes?.swtab}`}>
                    <Tab label="Daily Mass"  className={`${styles.tab}`} />
                    <Tab label="Mass Petition" className={styles.tab} />
                    <Tab label="Prayer Request" className={styles.tab} />
                    <Tab label="Testimony" className={styles.tab}/>
                    <Tab label="About" className={styles.tab}/>
                </Tabs>
                </div>
           </div>
           :( <div className="container"><ul className={styles.mobTab}>
                <li onClick={()=>setValue(0)} className={` ${styles.li} ${ value === 0 ? styles.active : ''} ${ value === 0 ? classes?.txtColor+' '+classes?.borderBtmOnly : ''}`}>Daily Mass</li>
                <li onClick={()=>setValue(1)} className={` ${styles.li} ${ value === 1 ? styles.active : ''} ${ value === 1 ? classes?.txtColor+' '+classes?.borderBtmOnly : ''}`}>Mass Petition</li>
                <li onClick={()=>setValue(2)} className={` ${styles.li} ${ value === 2 ? styles.active : ''} ${ value === 2 ? classes?.txtColor+' '+classes?.borderBtmOnly : ''}`}>Prayer Request</li>
                <li onClick={()=>setValue(3)} className={` ${styles.li} ${ value === 3 ? styles.active : ''} ${ value === 3 ? classes?.txtColor+' '+classes?.borderBtmOnly : ''}`}>Testimony</li>
                <li onClick={()=>setValue(4)} className={` ${styles.li} ${ value === 4 ? styles.active : ''} ${ value === 4 ? classes?.txtColor+' '+classes?.borderBtmOnly : ''}`}>About</li>
               </ul></div>)
            
        
    )
}

export default SwprayerTab;