import styles from './SingleGift.module.scss'
import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import AddressForm from '../AddressForm'

const SingleGift = () => {
  const [singleGift, setSingleGift] = useState('')
  const changeSingleGiftAmount = (e) => {
    if (!isNaN(e.target.value) && e.target.value > 0) {
      setSingleGift(e.target.value)
    }
  }
  return (
    <>
      <div className={styles.gift_content}>
        <Container>
          <h3>Select a Donation Designation</h3>
          <p>Enter an amount in the designated area(s) you wish to give.</p>
          <div className={styles.month_amt}>
            <h5>Support Shalom World $</h5>
            <input
              type="number"
              value={singleGift}
              onChange={changeSingleGiftAmount}
              name="singleGift"
            ></input>
          </div>
          <p>
            Your commitment to a monthly gift will help ensure 24/7 Gospel
            broadcasting, to reach the lost and the hurting.
          </p>
        </Container>
      </div>
      <AddressForm monthlyValue={singleGift} tabValue={'Single'} />
    </>
  )
}

export default SingleGift
