import React, { useState } from "react";
import styles from "./PodcastThumb.module.scss";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import { Link } from 'react-router-dom';

const PodcastThumb = ({ item, handleClick }) => {
  const [isLoaded, setLoaded] = useState(false)

  useViewportAnimation();
  const onLoad = () => {
    setLoaded(true)
  }

  const handleClickItem = () => {
    handleClick(item)
  }

  return (
    <div className={`${styles.PodcastThumb}  col-lg-3 col-md-4 col-sm-6 animation`}>
      <Link to={`/podcast/${item?.url}`} onClick={handleClickItem}>
        <figure>
          <img alt="podcast" className={isLoaded ? "loaded" : null} src={item?.thumbnail} onLoad={onLoad} />
        </figure>
        <h3 className={styles.thumbTitle}>{item?.title}</h3>
      </Link>

    </div>
  );
};

export default PodcastThumb;
