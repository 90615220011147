import React, { useState } from "react";
import styles from "./ProgramThumb.module.scss";
// import { useHistory } from "react-router-dom";
import { useDimensions } from "../../logic/Dimentions";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import { clearThumbnails } from "../../actions/eventDetailsAction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ProgramThumb = ({ value, id }) => {
  const dispatch = useDispatch();

  // State to track image loading
  const [isLoaded, setLoaded] = useState(false);

  /**
  * Get the viewport width using custom hook
  */

  const { width } = useDimensions();

  /**
   * Apply viewport animation using custom hook
   */
  useViewportAnimation();

  /**
   * Function to handle image load event
   */
  const onLoad = () => {
    setLoaded(true);
  };

  /**
   * Handle the click event on a link.
   * @param {Object} evnt - The event object with a "type" property.
   */
  const handleClick = (evnt) => {
    if (evnt.type === "event") {
      dispatch(clearThumbnails());
    }
  };

  return (
    <div
      id={id}
      className={`${styles.ProgramThumb} col-md-3 col-md-4 col-sm-6 animation`}
      style={{ maxWidth: width > 1200 ? "20%" : width < 992 ? "33%" : null }}
    >
      {/* Link to the appropriate route based on 'value.type' {event OR show}*/}
      <Link
        to={
          value.type === "event" ? `/events/${value.url}` : `/show/${value.url}`
        }
        className={value.type === "event" ? `event` : `show`}
        onClick={() => {
          // Call handleClick function with value.type as an argument
          handleClick(value);
        }}
      >
        <figure>
          {/* Image element with load event handler */}
          <img
            alt=""
            className={isLoaded ? "loaded" : "loaded"}
            src={value.image}
            onLoad={onLoad}
          />
        </figure>
      </Link>
    </div>
  );
};

export default ProgramThumb;
