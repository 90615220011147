/* eslint-disable import/no-anonymous-default-export */
import { FETCH_AWARDS, } from '../actions/types';

const INTIAL_STATE = {
    data: [],
};

export default (state = INTIAL_STATE, action) => {

    switch (action.type) {
        case FETCH_AWARDS:
            return {
                ...state,
                data: action.payload,
            };

        default:
            return state;
    }
}