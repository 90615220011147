import React, { lazy, useState, useEffect, useRef } from 'react'
import './CommonStyle.scss'
import { useDimensions } from '../../logic/Dimentions'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

// Lazy-loaded components
const Menu = lazy(() => import('../Menu'))
const Donate = lazy(() => import('../FooterDonate'))
const RelatedSites = lazy(() => import('../RelatedSites'))
// const OtherSection = lazy(() => import('../OtherSection'))
const ShalomTvApp = lazy(() => import("../ShalomTvApp"));
const MediaPartners = lazy(() => import('../MediaPartners'))
const FindShalom = lazy(() => import('../FindShalom'))
const Subscription = lazy(() => import('../Subscription'))
const Footer = lazy(() => import('../Footer'))
const MobileMenu = lazy(() => import('../MobileMenu'))
const BottomTab = lazy(() => import('../BottomTab'))
const AdBanner = lazy(() => import('../AdBanner'))

const MainLayout = ({ children, ads }) => {
  const { width } = useDimensions()
  const advertismentRef = useRef()
  const headerRef = useRef()
  const location = useLocation()
  const [stickyMenu, setStickyMenu] = useState(false)
  const [showAd, setShowAd] = useState(true)
  const [imageHeight, setImageHeight] = useState(0)

  // Effect to add scroll and resize event listeners
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {});
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {});
    window.addEventListener("resize", handleResize);
  }, [width]);

  // Effect to update the showAd state based on ads and location changes
  useEffect(() => {
    // Check if the current location is not the homepage
    if (location.pathname !== "/") {
      setShowAd(false);
    }
    // Check if ads are available and the current location is the homepage
    if (ads && ads.url && location.pathname === "/") {
      setShowAd(true);
    } else {
      setShowAd(false);
    }
  }, [ads, location.pathname]);

  const handleScroll = () => {
    // Calculate the position of the advertisement and the header
    if (
      advertismentRef !== undefined &&
      advertismentRef?.current?.getBoundingClientRect?.current?.getBoundingClientRect &&
      advertismentRef.current !== undefined &&
      advertismentRef.current.getBoundingClientRect !== undefined &&
      advertismentRef.current.getBoundingClientRect() &&
      advertismentRef.current.getBoundingClientRect().height &&
      advertismentRef.current.getBoundingClientRect().height >= 1 &&
      window.pageYOffset >
      advertismentRef.current.getBoundingClientRect().height -
      headerRef.current.getBoundingClientRect().height
    ) {
      setStickyMenu(true);
    } else {
      setStickyMenu(false);
    }
  };

  const handleCloseAd = () => {
    // Close the sticky menu and hide the advertisement
    setStickyMenu(false)
    setShowAd(false)
    setImageHeight(0)
  }

  const handleResize = (e) => {
    const windowSize = window.innerWidth
    // Set the image height for the advertisement on the homepage
    if (
      location.pathname === '/' &&
      advertismentRef.current &&
      advertismentRef.current.clientHeight
    ) {
      setImageHeight(advertismentRef.current.clientHeight)
    }
    // Hide the image height when the window size is less than 992px
    if (windowSize < 992) {
      setImageHeight(0)
    }
  }

  const handleImageLoaded = (e) => {
    // Set the image height for the advertisement on the homepage after it is loaded
    if (
      location.pathname === '/' &&
      advertismentRef &&
      advertismentRef.current &&
      advertismentRef.current.clientHeight &&
      ads?.url
    ) {
      setImageHeight(advertismentRef.current.clientHeight)
    }
  }

  return (
    <>
      <div className={`layout_wrap ${showAd === false ? "" : "ads"}`}
        style={{ paddingTop: (ads?.url) ? imageHeight : 0 + "px" }}>

        {/* Ad Banner */}
        {width >= 1025 ? (
          <>
            {showAd ? (
              <AdBanner
                ref={advertismentRef}
                closeAd={handleCloseAd}
                adBannerData={ads}
                imageLoaded={handleImageLoaded}
              />
            ) : null}
          </>
        ) : (
          ''
        )}

        {/* Page Container */}
        <div className="page_container">

          {/* Desktop Menu */}
          {width >= 1025 ?
            <Menu
              stickyHeadMenu={stickyMenu}
              adBannerData={ads}
              imageHeight={imageHeight}
              showAd={showAd ? "Yes" : "No"}
            /> :
            /* Mobile Menu */
            <MobileMenu
              stickyMenu={stickyMenu}
              bref={headerRef}
              showAd={showAd ? "Yes" : "No"}
              imageHeight={imageHeight}

            />}

          {/* Children Content */}
          <div>{children}</div>

          {/* Desktop Components */}
          {width >= 1025 ? (
            <>
              <Donate />
              <RelatedSites />
              <ShalomTvApp />
              <MediaPartners />
              <FindShalom />
              <Subscription />
              <Footer />
            </>
          ) : (

            /* Mobile Bottom Tab */
            <BottomTab />
          )}
        </div>
      </div>
    </>
  )
}

const mapPropsToState = (state) => {
  return { ads: state.ads.ads }
}

export default connect(mapPropsToState, {})(MainLayout)
