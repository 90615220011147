/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import VideoPlayer from "../VideoPlayer";
import {
  fetchEpisodeVideo,
  removeEpisodeVideo,
} from "../../actions/episodeVideoAction";
import {
  fetchLatestVideo,
  fetchRelatedVideo,
  removeLatestRelatedVideo,
} from "../../actions/episodesAction";
import { useParams, Link } from "react-router-dom";
import styles from "./EpisodeVideo.module.scss";
import Container from "react-bootstrap/Container";
import EpiosdeRelatedVideo from "./EpisodeRelatedVideos";
import SecMenu from "../SecMenu";
import { useDimensions } from "../../logic/Dimentions";
import MobileMenu from "../MobileMenu";
import _ from "lodash";
import SocialMediaWrap from "../../components/SocailMediaIcons";
import { useSeasonal } from "../../logic/Seasonal";
import { useHistory } from "react-router-dom";
import BrowserPersistence from "../../util/simplePersistance";
import { connect } from "react-redux";

const EpisodeVideo = ({
  fetchEpisodeVideo,
  removeEpisodeVideo,
  episodeVideo,
  latestEpisodes,
  fullscreen,
  setFullscreen,
  toggleScreen,
  setToggleScreen,
  showControls,
  setShowControls,
}) => {

  let { episodeId } = useParams();
  const storage = new BrowserPersistence();
  const { width } = useDimensions();
  const [programDetailsRd, setProgramDetailsRd] = useState();
  const [play, setPlay] = useState(true);
  const [preferredQuality, setPreferredQuality] = useState("Auto");
  const [selectedVideo, setSelectedVideo] = useState(0);
  const [language, setLanguage] = useState("na");

  const { classes } = useSeasonal();
  let history = useHistory();

  useEffect(() => {
    const region = storage.getItem("CountryCode");
    fetchEpisodeVideo(episodeId, region);
  }, [episodeId]);

  useEffect(() => {
    if (episodeVideo && episodeVideo.status === "episode_deleted") {
      history.push(`/episode-not-found`);
    }
    if (episodeVideo && episodeVideo.status === "error") {
      history.push(`/404`);
    }
  }, [episodeVideo, programDetailsRd]);

  useEffect(() => {
    return () => {
      removeEpisodeVideo();
    };
  }, []);

  useEffect(() => {
    let flag = 0;
    _.map(latestEpisodes, (a) => {
      if (flag === 0) {
        _.find(a, (o) => {
          if (o.url === episodeId) {
            flag = 1;
            setProgramDetailsRd(o);
            return o;
          }
        });
      }
    });
  }, [episodeId]);

  const sortVideos = (videos) => {
    videos.sort(function (a, b) {
      return b.height - a.height;
    });
    videos.splice(0, 0, videos.pop());
    return videos;
  };

  const handleSelectSubtitle = (lang) => {
    setLanguage(lang);
    var videoElement = document.querySelector("#VideoPlayer video");
    if (videoElement?.textTracks?.length) {
      for (let i = 0; i < videoElement.textTracks.length; i++) {
        videoElement.textTracks[i].mode = "";
        if (videoElement.textTracks[i].language === lang) {
          videoElement.textTracks[i].mode = "showing";
        } else {
          videoElement.textTracks[i].mode = "disabled";
        }
      }
    }
  };

  return (
    <>
      <div className={styles.EpisodeVideo}>

        {width >= 992 ? <SecMenu /> : <MobileMenu />}

        <div className={styles.video_align}>
          {width >= 992 ? (
            <VideoPlayer
              vidoes={
                episodeVideo.videos ? sortVideos(episodeVideo.videos) : []
              }
              fullscreen={fullscreen}
              setFullscreen={setFullscreen}
              toggleScreen={toggleScreen}
              setToggleScreen={setToggleScreen}
              showControls={showControls}
              setShowControls={setShowControls}
              play={play}
              setPlay={setPlay}
              preferredQuality={preferredQuality}
              setPreferredQuality={setPreferredQuality}
              selectedVideo={selectedVideo}
              setSelectedVideo={setSelectedVideo}
              url={""}
              durationData={JSON.parse(
                localStorage.getItem("SHALOM_WORLD_BROWSER_videoPlayed")
              )}
              allTracks={episodeVideo?.tracks}
              handleSelectSubtitle={handleSelectSubtitle}
              setLanguage={setLanguage}
              language={language}
              light={episodeVideo?.thumbnail}
            />
          ) : null}
        </div>

        {width >= 992 ? (
          <div className={styles.body}>

            <Container>
              {((programDetailsRd && programDetailsRd.title) ||
                episodeVideo.title) && (
                  <>
                    <div className={styles.is_title}>
                      <h1 className={classes?.seasonalClass}>
                        {programDetailsRd && programDetailsRd.title
                          ? programDetailsRd.title
                          : episodeVideo.title}
                      </h1>
                      <div
                        className={`${styles.socialShareBtn} ${classes.hoverColor} socialShareBtns`}
                      >
                        <span className={classes.hoverColor}>
                          <i className="icon-share-episode"></i> Share
                        </span>
                        <div
                          className={
                            window.location.pathname.includes("episode")
                              ? `${styles.shareIcons} ${classes.socialMediaColor}`
                              : ``
                          }
                        >
                          <SocialMediaWrap classes={classes} />
                        </div>
                      </div>
                    </div>
                    <p className={styles.episode}>
                      <span>
                        {programDetailsRd && programDetailsRd.episode
                          ? programDetailsRd.episode
                          : episodeVideo.episode}
                        -
                        {programDetailsRd && programDetailsRd.program
                          ? programDetailsRd.program
                          : episodeVideo.program}
                      </span>
                    </p>
                    <p className={styles.episode}>
                      <span>{episodeVideo.description}</span>
                    </p>

                    <Link
                      className={`${styles.web_btn} ${classes?.fullSchduleBtn}`}
                      to={{
                        pathname: "/feedback",
                        state: {
                          program: episodeVideo.program
                            ? episodeVideo.program
                            : "",
                          programId: episodeVideo.programId
                            ? episodeVideo.programId
                            : "",
                          episode: episodeVideo.title ? episodeVideo.title : "",
                          episodeId: episodeVideo.episode
                            ? episodeVideo.episode
                            : "",
                          id: "_" + episodeVideo.id,
                        },
                      }}
                    >
                      LEAVE A FEEDBACK
                    </Link>
                  </>
                )}
            </Container>
          </div>
        ) : (
          <div className="container">
            <h5>{episodeVideo.title}</h5>
          </div>
        )}

        <EpiosdeRelatedVideo episodeVideo={episodeVideo} />

      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    episodeVideo: state.episodeVideo.episodeVideos,
    maxLatestPage: state.episodeVideo.maxLatestPages,
    maxRelatedPage: state.episodeVideo.maxRelatedPages,
    moreLatestVideos: state.latestEpisodes.latestVideos,
    moreRelatedVideos: state.latestEpisodes.relatedVideos,
    currentLatestPage: state.latestEpisodes.currentLatestPage,
    currentRelatedPage: state.latestEpisodes.currentRelatedPage,
    latestEpisodes: state.latestEpisodes,
  };
};

export default connect(mapStateToProps, {
  fetchEpisodeVideo,
  fetchLatestVideo,
  fetchRelatedVideo,
  removeLatestRelatedVideo,
  removeEpisodeVideo,
})(EpisodeVideo);
