/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Episodes.module.scss";
import CategoryList from "../CategoryList";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  fetchCategoryEpisodeStart,
  fetchCategoryEpisode,
  fetchLatestEpisode,
  resetLatestData,
} from "../../actions/episodesAction";
import { removeEpisodeVideo } from "../../actions/episodeVideoAction";
import Container from "react-bootstrap/Container";
import EpisodeList from "../EpisodeList";
import CountryDropDown from "../CountryDropdown";
import { useLatestEpisodes } from "../../logic/LatestEpisodes/useLatestEpisodes";
import { useParams, useHistory } from "react-router-dom";
import { useDimensions } from "../../logic/Dimentions";
import { useSeo } from "../../logic/Seo/useSeo";
import { TitleComponent } from "../TitleComponent";
import { useSeasonal } from "../../logic/Seasonal";
import SocialMediaWrap from "../SocailMediaIcons";
import Paginate from "../../util/Paginate";

const Episodes = ({
  fetchCategoryEpisodeStart,
  fetchCategoryEpisode,
  fetchLatestEpisode,
  removeEpisodeVideo,
  resetLatestData,
  latestEpisodes,
  categories,
}) => {
  const listingRef = useRef(null);

  let oldScrollY = 0;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [country, setCountry] = useState("all");
  const { location } = useHistory();
  let path = location.pathname;
  let { category } = useParams();
  const { width } = useDimensions();
  const { classes } = useSeasonal();
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 15,
  });
  const [check, setCheck] = useState(false);

  useSeo();
  useEffect(() => {
    removeEpisodeVideo();
  }, []);

  useLatestEpisodes({
    fetchCategoryEpisode,
    resetLatestData,
    fetchLatestEpisode,
    latestEpisodes,
    category,
    categories,
    path,
    pagination,
    country,
  });

  if (path === "/episodes") {
    category = "popular";
  }

  const loadItems = () => {
    fetchCategoryEpisodeStart()
    fetchCategoryEpisode(category, pagination.page, country);
    executeScroll()
  };

  useEffect(() => {
    if (pagination && pagination.page >= 1) {
      loadItems();
    }
  }, [pagination]);

  const onPagination = (pageNumber) => {
    setPagination({
      ...pagination,
      page: pageNumber,
    });
  };

  const onClickAway = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setDropdownOpen((prevCheck) => !prevCheck);
  };

  const controlDirection = (evt) => {
    if (window.scrollY > oldScrollY) {
      setDropdownOpen(false);
    } else {
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", controlDirection);
    return () => {
      window.removeEventListener("scroll", controlDirection);
    };
  }, []);

  //   Reset the count if Url location changed
  useEffect(() => {
    setPagination({
      page: 0,
      perPage: 15,
    })
    setCheck(prevCheck => !prevCheck);
    //Reset so it reflect paginate

  }, [category]);

  const executeScroll = () =>
    listingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  return (

    <>
      <div className={styles.bgImageWrap_new}>
        <img src={latestEpisodes[category]?.bgImage} className="realImg" alt="" />
      </div>

      <div className={`${styles.headingAlign} mob-app-pad-0 mob-app-mar-0`} ref={listingRef}>

        {width >= 992 && <SocialMediaWrap classes={classes} />}

        <TitleComponent title="Shalom world " />

        <Container >
          {width >= 992 ? (
            <h1 className={`${styles.ttlHome} ${classes?.seasonalClass}`}>
              Episodes
            </h1>
          ) : null}

          <CountryDropDown
            selectCountry={setCountry}
            country={country}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            onClickAway={onClickAway}
          />
          <CategoryList />
          <div className={styles.episodeListingWrap}>
            <EpisodeList data={latestEpisodes[category]} status={latestEpisodes?.status} />

            <Paginate
              currentPage={pagination.page ? pagination.page : 1}
              onPagination={onPagination}
              totalPages={latestEpisodes[category]?.totalPage}
              pageNumberLimit={width >= 992 ? 5 : 3}
              check={check}
            />
          </div>


        </Container>
      </div>
    </>

  );
};

const mapStateToProps = (state) => {
  return { latestEpisodes: state.latestEpisodes, categories: state.categories };
};

export default connect(mapStateToProps, {
  fetchCategoryEpisodeStart,
  fetchCategoryEpisode,
  fetchLatestEpisode,
  removeEpisodeVideo,
  resetLatestData,
})(Episodes);
