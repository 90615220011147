import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import { fetchPeaceFellowship } from "../../actions/fellowshipAction";
import styles from "./PeaceFellowship.module.scss";
import Container from "react-bootstrap/Container";
import WaysToGive from "../WaysToGive";
import VideoPlayer from "../VideoPlayer";
import { useSeasonal } from "../../logic/Seasonal";
import { Link } from 'react-router-dom';

const PeaceFellowship = ({ fetchPeaceFellowship, fellowship }) => {
  const [play, setPlay] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const [scrollPosition, setSrollPosition] = useState(0);
  const [showControls, setShowControls] = useState(0);
  const [toggleScreen, setToggleScreen] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
    if (document.body.className.match('isplaying')) {
      document.body.classList.remove("isplaying");
    }
  };

  const isExternalUrl = (url) => {
    // Check if the URL starts with "http://" or "https://"
    return /^https?:\/\//.test(url);
  };

  useEffect(() => {
    fetchPeaceFellowship();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { classes } = useSeasonal();

  return (

    <div className={styles.PeaceFellowship}>
      <div className={styles.video_align}>
        <div className="vdoWrap wide video_default">
          {fellowship.bannerVideo && fellowship.bannerVideo.url ? (
            <VideoPlayer
              url={fellowship.bannerVideo.url}
              fullscreen={fullscreen}
              setFullscreen={setFullscreen}
              play={play}
              setPlay={setPlay}
              hideHeaderMenu={true}
              showControls={showControls}
              setShowControls={setShowControls}
              toggleScreen={toggleScreen}
              setToggleScreen={setToggleScreen}
            />
          ) : null}

        </div>
      </div>
      <section className={styles.spf_intro}>
        <Container>
          <div className={`${styles.logo_wrap} ${classes?.seasonalClass}`}>
            <img src={fellowship.fellowshipLogo} alt="" />
          </div>
          <p className={styles.para_spf}>{fellowship.fellowshipDescp}</p>

          <div
            className={styles.para_spf}
            dangerouslySetInnerHTML={{
              __html: fellowship.fellowshipShortDescp,
            }}
          ></div>
          <>

            {isExternalUrl(fellowship.fellowshipBtnUrl) ? (
              <a href={fellowship.fellowshipBtnUrl} target="_blank" rel="noopener noreferrer" className={`${styles.web_btn} ${classes?.fullSchduleBtn}`}>
                JOIN NOW
              </a>
            ) : (
              <Link className={`${styles.web_btn} ${classes?.fullSchduleBtn}`} to={fellowship.fellowshipBtnUrl}> JOIN NOW</Link>
            )}
          </>

          <div className={styles.donate_method}>
            <span>OR</span>
            <Row className={styles.row_style}>
              <div className="col-sm-6">
                <div className={styles.method_box}>
                  <h5>
                    {fellowship.fellowShipOr
                      ? fellowship.fellowShipOr.heading
                      : null}
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: fellowship.fellowShipOr
                        ? fellowship.fellowShipOr.address
                        : null,
                    }}
                  ></p>
                  <a href="/peacefellowship/mail">
                    {fellowship.fellowShipOr
                      ? fellowship.fellowShipOr.btnTitle
                      : null}
                  </a>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={styles.method_box}>
                  <h5>
                    {fellowship.fellowShipPhone
                      ? fellowship.fellowShipPhone.heading
                      : null}
                  </h5>

                  <h5 className={styles.phone_no}>
                    {fellowship.fellowShipPhone
                      ? fellowship.fellowShipPhone.btnUrl
                      : null}
                  </h5>
                  <p>
                    {fellowship.fellowShipPhone
                      ? fellowship.fellowShipPhone.address
                      : null}
                  </p>

                  {fellowship.fellowShipPhone ? (
                    <a
                      href={
                        "tel:" + fellowship?.fellowShipPhone?.btnUrl
                      }
                    >
                      {fellowship.fellowShipPhone.btnTitle}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </section>

      <WaysToGive moreWays={fellowship.moreWaysGive} classes={classes} />
    </div >
    // </div>
    //     </>
    //     )}
    // </Transition>
  );
};
const mapPropsToState = (state) => {
  return { fellowship: state.fellowshipData.fellowship };
};
export default connect(mapPropsToState, { fetchPeaceFellowship })(
  PeaceFellowship
);
