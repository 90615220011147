import React,{lazy} from 'react';
import ApplyNow from '../components/ApplyNow';
import SocialMediaWrap from '../components/SocailMediaIcons';
import {useDimensions} from '../logic/Dimentions';
import { useSeasonal } from '../logic/Seasonal';

const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));
const MobileMenu =  lazy(()=>import('../components/MobileMenu'));
const BottomTab = lazy(()=>import('../components/BottomTab'));

const ApplyNowPage = () => {
    const { height, width } = useDimensions();
    const {classes} = useSeasonal();

    return (
        <>
        {width>1024?<Menu/>:<MobileMenu/>}
        {width>=992&&<SocialMediaWrap classes={classes}/>}
        <ApplyNow/>
        {width>=992&&<Subscription/>}
        {width>=992?<Footer/>:<BottomTab/>}
        </>
    )
}

export default ApplyNowPage;