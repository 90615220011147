import React from 'react';
import styles from './LoadMoreLoader.module.scss';
import { useSeasonal } from '../../../logic/Seasonal'

const CommonLoader = () => {
    const { seasonal } = useSeasonal()

    return (
        <div className={styles.loadingWrap}>
            <div style={seasonal?.color !== undefined && { backgroundColor: seasonal?.color }} className={styles.dotsAnim}></div>
            <div style={seasonal?.color !== undefined && { backgroundColor: seasonal?.color }} className={styles.dotsAnim}></div>
        </div>
    )
}

export default CommonLoader;