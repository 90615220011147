import { FETCH_PODCASTS, FETCH_MOREPODCASTS } from '../actions/types';

const INTIAL_STATE = {
    programs: [],
    totalPages: 0,
    currentPage: 1,

};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PODCASTS:
            return {
                programs: action.payload,
                banner: action.banner
            }

        case FETCH_MOREPODCASTS:
            return {
                programs: {
                    data: state.programs.data.concat(action.payload.data),
                    totalPages: action?.payload?.totalPages,
                    currentPage: action?.payload?.currentPage,
                },
                banner: state.banner
            }
        default:
            return state;
    }
}


