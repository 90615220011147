/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { connect } from "react-redux";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useLocation } from "react-router-dom";
import { fetchSocialLink } from "../../actions/otherdetailsAction";
import styles from "./SocialMediaIcons.module.scss";
import { useSeasonal } from "../../logic/Seasonal";
import { FaFacebookF, FaWhatsapp, FaXTwitter } from "react-icons/fa6";

/**
 * This component renders the social media icons to share the current page.
 * It uses the react-share library to create the share buttons.
 * It also uses the useLocation hook to get the current URL and the fetchSocialLink
 * action to get the social media sharing links from the backend.
 * The useSeasonal hook is used to get the current seasonal color scheme.
 */

const SocialMediaWrap = ({
  socialLink,
  fetchSocialLink,
  urlParams,
  donationData,
}) => {
  // Get the current location object using the useLocation hook from the react-router-dom
  const location = useLocation();
  const hash = location.hash;

  // Get the seasonal color and apply to classes
  const { classes } = useSeasonal();

  // Define a function getClass that returns a class name based on the location object and the donation data object

  const getClass = () => {
    let className = "";

    // Check if the current path is "/dollar-a-day" or "/donate" or "/prisons"
    if (
      location?.pathname === "/donate" ||
      location?.pathname === "/prisons" ||
      location?.pathname === "/dollar-a-day" ||
      location?.pathname === "/dollar-a-day-ca" ||
      location?.pathname === "/dollar-a-day-eu" ||
      location?.pathname === "/dollar-a-day-uk" ||
      location?.pathname === "/dollar-a-day-ch"


    ) {
      // If the country is US, CA, or MX, hide the element, otherwise show it
      if (
        donationData?.country?.toUpperCase() === "US" ||
        donationData?.country?.toUpperCase() === "CA" ||
        donationData?.country?.toUpperCase() === "MX" ||
        donationData?.region?.toUpperCase() === "UK" ||
        donationData?.region?.toUpperCase() === "EU" || 
        donationData?.region?.toUpperCase() === "AA" ||
        donationData?.region?.toUpperCase() === "AU"
      ) {
        className = "d-none";
      } else {
        // If page is dollar-a-day, do not show social icons
        if (
          location?.pathname === "/dollar-a-day" ||
          location?.pathname === "/prisons" ||
          location?.pathname === "/dollar-a-day-ca" ||
          location?.pathname === "/dollar-a-day-eu" ||
          location?.pathname === "/dollar-a-day-uk" ||
          location?.pathname === "/dollar-a-day-ch"

        ) {
          className = "d-none";
        } else {
          className = "d-show";
        }
      }
    } else {
      // If the path is not "/dollar-a-day" or "/donate", always show the element
      className = "d-show";
    }
    return className;
  };

  // Render a div element with the class name returned by the getClass function

  return (
    <div className={`${classes?.socialMediaColor} ${getClass()}`}>
      <div
        className={`${window.location.pathname.includes("peacefellowship") ||
          window.location.pathname.includes("news") ||
          window.location.pathname.includes("blogauthor")
          ? styles.haveWhiteBG
          : ""
          } ${styles.socialMediaicoWrap}  socialShare`}
      >
        <ul
          className={`${styles.home_share} `}
          data-spy="affix"
          data-offset-top="600"
        >
          {Array.isArray(socialLink)
            ? socialLink.map((value, index) => {
              if (value.type === "facebook") {
                return (
                  <li key={index}>
                    <div
                      role="button"
                      tabIndex="0"
                      className="SocialMediaShareButton SocialMediaShareButton--facebook"
                    >
                      <FacebookShareButton
                        url={`${window.location.href}${urlParams && hash !== urlParams ? urlParams : ""
                          }`}
                      >
                        <a
                          href={() => false}
                          // className={`icon-social_fb ${classes?.hoverColor}`}
                          
                        ><FaFacebookF/></a>
                      </FacebookShareButton>
                    </div>
                  </li>
                );
              }
              if (value.type === "twitter") {
                return (
                  <li key={index}>
                    <div
                      role="button"
                      tabIndex="0"
                      className="SocialMediaShareButton SocialMediaShareButton--twitter"
                    >
                      <TwitterShareButton
                        url={`${window.location.href}${urlParams && hash !== urlParams ? urlParams : ""
                          }`}
                      >
                        <a
                          href={() => false}
                          // className="icon-social_twitter"
                        ><FaXTwitter/></a>
                      </TwitterShareButton>
                    </div>
                  </li>
                );
              }

              if (value.type === "whatsapp") {
                return (
                  <li key={index}>
                    <div
                      role="button"
                      tabIndex="0"
                      className="SocialMediaShareButton SocialMediaShareButton--whatsapp"
                    >
                      <WhatsappShareButton
                        url={`${window.location.href}${urlParams && hash !== urlParams ? urlParams : ""
                          }`}
                      >
                        <a
                          href={() => false}
                          // className="icon-social_whatsapp"
                        ><FaWhatsapp/></a>
                      </WhatsappShareButton>
                    </div>
                  </li>
                );
              }
            })
            : null}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    socialLink: state.socialLink.data.socialLinks,
    donationData: state.donationData.data,
  };
};

export default connect(mapStateToProps, { fetchSocialLink })(SocialMediaWrap);
