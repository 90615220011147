import axios from "axios";

const PRODUCTION = process.env.REACT_APP_PRODUCTION;
const APIURL =
    PRODUCTION === "true"
        ? process.env.REACT_APP_APIURL_LIVE
        : process.env.REACT_APP_STAGING_APIURL;

const salomapi = axios.create({
    baseURL: `${APIURL}`,
});

export default salomapi;

const APIURL2 =
    PRODUCTION === "true"
        ? process.env.REACT_APP_APIURL_LIVE2
        : process.env.REACT_APP_STAGING_APIURL2;

export const salomapi2 = axios.create({
    baseURL: `${APIURL2}`,
});

const APIURL3 =
    PRODUCTION === "true"
        ? process.env.REACT_APP_APIURL_LIVE3
        : process.env.REACT_APP_STAGING_APIURL3;

export const salomapi3 = axios.create({
    baseURL: `${APIURL3}`,
});

const APIURL4 =
    PRODUCTION === "true"
        ? process.env.REACT_APP_APIURL_LIVE4
        : process.env.REACT_APP_STAGING_APIURL4;

export const salomapi4 = axios.create({
    baseURL: `${APIURL4}`,
});
