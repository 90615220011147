import {FETCH_ABOUTUS,FETCH_MORE_ENDORSEMENTS} from './types';
import salomapi from '../apis';

export const fetchAboutus =  () => async dispatch => {
    const response = await salomapi.get('/about');
    dispatch({type:FETCH_ABOUTUS,payload:response.data})
}
export const fetchMoreEndorsements =  (region,page,endorseList) => async dispatch => {
    let resp = []
    const response = await salomapi.get('/endorsement?region='+region+'&page='+page+'&perpage=6');
    // if(page===2)
    // resp = endorseList.concat(response.data.endorsements)
    // else
  if(response.data.status === 'success' ){
    resp = response.data.endorsements
    dispatch({type:FETCH_MORE_ENDORSEMENTS,payload:{[region]:resp},maxPage:response.data.page,page:page,region:region})
  }
}
