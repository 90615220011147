import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import { useHome } from '../logic/Home/useHome';
import Careers from '../components/Careers';
import { connect } from 'react-redux';
import { fetchOtherdetails } from '../actions/otherdetailsAction';
import { TitleComponent } from '../components/TitleComponent';
import { useSeo } from '../logic/Seo/useSeo';
const Career = ({ fetchOtherdetails, otherdetails }) => {
    useHome({ fetchOtherdetails, otherdetails });
    useSeo();
    return (
        <CommonLayout>
            <TitleComponent title="Shalom world " />
            <Careers />
        </CommonLayout>

    )
}
const mapStateToProps = (state) => {
    return ({ otherdetails: state.otherdetails.data })
}
export default connect(mapStateToProps, { fetchOtherdetails })(Career);