import {FETCH_NEWS_COMMENTS} from '../actions/types.js';

const INTIAL_STATE = {
  comments :[],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_NEWS_COMMENTS:
            return {
                ...state,
                comments :action.payload
            }

        default:
            return state;
    }
}