import React from 'react'
import Container from 'react-bootstrap/Container'
import FeedbackLayout from '../components/Layouts/FeedbackLayout'
import { Link } from 'react-router-dom'
import { useSeasonal } from '../logic/Seasonal'
import SocialMediaWrap from '../components/SocailMediaIcons'
import { useDimensions } from '../logic/Dimentions'

const ShowErrorPage = () => {
  const { classes } = useSeasonal()
  const { width } = useDimensions()

  return (
    <>
      <FeedbackLayout>
        {width >= 992 && <SocialMediaWrap classes={classes} />}
        <Container>
          <div className="pagenotfoundWrap">
            <div className="notFoundText">
              <div className={`text404 ${classes?.txtColor}`}>
                {/* <span>404</span> Program not found */}
                This Video has been removed
              </div>
              <Link
                className={`btn btn-outline-primary btn-lg ${classes?.fullSchduleBtn}`}
                to={'/'}
              >
                Home
              </Link>{' '}
            </div>
          </div>
        </Container>
      </FeedbackLayout>
    </>
  )
}

export default ShowErrorPage
