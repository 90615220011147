import { FETCH_COOKIE } from "./types";
import { salomapi3 } from '../apis';
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();
export const fetchCookie = (region) => async (dispatch) => {

    async function fetchFromServer(region) {
        try {
            let params = '';
            if (region) {
                params = `?region=${region}`;
            }
            const response = await salomapi3.get(`/cookiepolicy${params}`);
            await saveCookie(response.data);
            dispatch({ type: FETCH_COOKIE, payload: response.data });
        } catch (error) {
            dispatch({
                type: FETCH_COOKIE,
                payload: error,
            });
            throw error;
        }
    }

    const localCookieData = await retrieveCookie();

    if (localCookieData && localCookieData?.content) {
        dispatch({ type: FETCH_COOKIE, payload: localCookieData });
    } else {
        await fetchFromServer(region);
    }

    async function retrieveCookie() {
        return storage.getItem("cookiePolicyList");
    }

    async function saveCookie(season) {
        return storage.setItem("cookiePolicyList", { ...season, count: 1, confirm: false }, 31536000);
    }
};
