import styles from "./SignInTab.module.scss";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import GoogleRecaptcha from "../../components/Recaptcha";
import { postSign, getProfile } from "../../actions/mediagalleryAction";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ForgotPassword from "./forgotPassword";
import { useSeasonal } from "../../logic/Seasonal";
import ResetPassword from "./ResetPassword";
import { fetchUser } from "../../actions/forgotPasswordAction";
import { Spinner } from "react-bootstrap";

const SignInTab = ({
  postSign,
  getProfile,
  fetchUser,
  token,
  SetTabActive,
  verifyError,
  verifyMsg,
  pathName,
}) => {
  const [isFormSuccess, setFormSuccess] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [forgotClicked, setForgotClicked] = useState(false);
  let history = useHistory();
  const captcha = useRef(null);
  const [captchaReset, setCaptchaReset] = useState(false);
  const [userId, setUserId] = useState("");
  const [captchaValid, isCaptchaValid] = useState(false);
  const [validUser, isValidUser] = useState(false);
  const [screens, setScreen] = useState("signIn");
  const { classes } = useSeasonal();
  const [isLoading, setIsLoading] = useState(false);

  // let Urls = "/swplus/signin?token=4063928089";

  useEffect(() => {
    if (pathName === "/swplus/forgotpassword") {
      setScreen("reset");
      fetchUser({ token })
        .then((res) => {
          if (res?.data.status == "success") {
            setUserId(res?.data.id);
          }
        })
        .catch((err) => { });
    } else if (pathName === "/swplus/signin/") {
      setScreen("signIn");
    }
  }, [token]);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.invalidEmail = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    }
    if (!captchaValid) {
      errors.captchaValid = "Please valid the Captcha";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        email: values.email,
        password: values.password,
      };

      if (captchaValid) {
        setIsLoading(true);
        postSign(postData)
          .then((res) => {
            if (res.data.code === "success") {
              getProfile(res.data);
              setErrorMsg("");
              setFormSuccess(true);
              resetForm({});
              history.push({
                pathname: '/swplus/dashboard',
                state: {
                  profile: res.data.message,
                },
              });
            } else {
              if (res.data.message) {
                setErrorMsg(res.data.message);
              } else {
                setErrorMsg("Invalid User or Password");
              }
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setErrorMsg("Invalid User or Password");
            setIsLoading(false);
          });
      }
      if (captcha.current.getValue()) {
        isValidUser(true);
        isCaptchaValid(true);
      } else {
        isValidUser(false);
        isCaptchaValid(false);
      }
    },
  });

  const handleOnChange = () => {
    isCaptchaValid(true);
  };

  return (
    <>
      {screens == "reset" && (
        <ResetPassword userId={userId} setScreen={setScreen} />
      )}
      {screens == "forgot" && (
        <ForgotPassword
          setForgotClicked={setForgotClicked}
          setScreen={setScreen}
        />
      )}
      {screens == "signIn" && (
        <div className={styles.gallery_signup}>
          <h3>Hello, Welcome back!</h3>

          <form className={styles.form_align} onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <div
                  className={`${styles.textClass} ${formik.errors.email &&
                    formik.submitCount &&
                    styles.formerror
                    }`}
                >
                  User Name :
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    name="email"
                    placeholder="Enter your user name"
                  ></input>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  className={`${styles.textClass} ${formik.errors.password &&
                    formik.submitCount &&
                    styles.formerror
                    }`}
                >
                  Password :
                  <input
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    name="password"
                    placeholder="Enter your password"
                  ></input>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  className={`${styles.fg_pw_lnk} ${classes?.txtColor}`}
                  onClick={() => setScreen("forgot")}
                >
                  Forgot password?
                </span>
              </Col>
            </Row>
            <Row className={styles.captchaWrap}>
              <Col>
                <GoogleRecaptcha
                  onChange={handleOnChange}
                  isCaptchaReset={captchaReset}
                />
              </Col>
            </Row>
            <button
              type="submit"
              className={`${styles.signup_btn} ${classes?.bgBtnClass}  ${styles.uploading}`}
            >
              {" "}
              &nbsp;
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              SUBMIT
            </button>

            {Object.keys(formik.errors).length != 0 && formik.submitCount ? (
              <div className="form-group col-sm-12">
                <span className={styles.formtotNullfieldErr}>
                  Please fill all required fields.
                </span>
              </div>
            ) : null}
            {formik.errors.invalidEmail && formik.submitCount ? (
              <div className="form-group col-sm-12">
                <span className={styles.formtotNullfieldErr}>
                  Please Enter a valid Email Id
                </span>
              </div>
            ) : null}

            {isFormSuccess ? (
              <div className="form-group col-sm-12">
                <span className={styles.resonseSuccessMsg}>
                  Your account logged in successfully!!!
                </span>
              </div>
            ) : null}
            {errorMsg ? (
              <div
                className="form-group col-sm-12"
                onClick={() => setErrorMsg("")}
                style={{ cursor: "pointer" }}
              >
                <span className={styles.formtotNullfieldErr}>{errorMsg}</span>
              </div>
            ) : null}
            {verifyMsg?.isView ? (
              <div className="form-group col-sm-12">
                <span className={styles.resonseSuccessMsg}>
                  {verifyMsg.msg}
                </span>
              </div>
            ) : null}
            {verifyError?.isView ? (
              <div className="form-group col-sm-12">
                <span className={styles.formtotNullfieldErr}>
                  {verifyError?.msg}
                </span>
              </div>
            ) : null}
          </form>
        </div>
      )}
    </>
  );
};

export default connect(null, { postSign, getProfile, fetchUser })(SignInTab);
