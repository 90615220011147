import React, { useEffect, useState, lazy } from "react";
import { fetchSwprayer } from "../../actions/swprayerAction";
import { connect } from "react-redux";
import LiveVideoPlayer from "../LiveVideoPlayer";
import { fetchToken, getMassSteamUri } from "../../actions/watchLiveAction";
import { useSwprayerLiveVideo } from "../../logic/Swprayer/useSwprayerLiveVideo";
import styles from './SwprayerLiveVideo.module.scss';
import SecMenu from '../SecMenu';
import { useDimensions } from '../../logic/Dimentions';
import ReactPlayer from "react-player";
import CommonLoader from "../Shimmers/CommonLoader";
const MobileMenu = lazy(() => import('../MobileMenu'));

const SwprayerLiveVideo = ({
  fetchSwprayer,
  swprayer,
  fetchToken,
  getMassSteamUri,
  fullscreen,
  setFullscreen
}) => {


  const [playError, setPlayError] = useState(true);
  const [region, setRegion] = useState('masslive');
  const logicProps = useSwprayerLiveVideo({ fetchToken, getMassSteamUri, region });
  const { watchUrl } = logicProps;
  const { height, width } = useDimensions();
  const [play, setPlay] = useState(false);
  useEffect(() => {
    if (swprayer == 0) {
      fetchSwprayer();
    }
  }, [swprayer]);



  return (
    <>
      {!fullscreen ? <div className={styles.swPlayerBanner}>

        <>
          {width >= 992 ? <SecMenu region={region} setRegion={setRegion} /> : <MobileMenu />}
          {watchUrl ?
            width >= 992 ? <LiveVideoPlayer
              url={watchUrl}
              fullscreen={fullscreen}
              setFullscreen={setFullscreen}
              playError={playError}
              setPlayError={setPlayError}
              play={play}
              setPlay={setPlay}
            /> :

              <div className="reactplayerWrap">
                <div className={styles.reactplayerWrapMobFixed}>
                  <ReactPlayer
                    url={watchUrl}
                    width="100%"
                    height="100%"
                    playing={false}
                    controls={true}
                  />
                </div>
              </div> : <div className="loaderWrp"><CommonLoader /></div>}
        </>
      </div> : <LiveVideoPlayer
        url={watchUrl}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        playError={playError}
        setPlayError={setPlayError}
        play={play}
        setPlay={setPlay}
      />}
    </>
  );
};

const mapStateToProps = (state) => {
  return { swprayer: state.swprayer.swPrayer };
};

export default connect(mapStateToProps, {
  fetchSwprayer,
  fetchToken,
  getMassSteamUri,
})(SwprayerLiveVideo);
