import React, { lazy, useState } from "react";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { useSeasonal } from '../../logic/Seasonal'
const PodcastShareModal = lazy(() => import("../PodcastShareModal"));

const Playlist = ({
  podCastPlayListData,
  setPlayListDisplay,
  handlePlayThisItem,
  handlePlay,
  play,
  handleSetActiveId,
  activeId,
  progressData,
  playListDisplay

}) => {

  const [showShareModal, setShareModal] = useState(false);
  const [shareContentModal, setShareContentModal] = useState(false);
  const [message, setMessage] = useState('');

  const handleSetMessage = (msg) => {
    setMessage(msg)
  };
  const { classes } = useSeasonal();
  let objectFound = podCastPlayListData.find((item) => item.active === true);

  const formatTime = (duration) => {
    let arr = duration.split(":"); // splitting the string by colon
    arr[0] = arr[0] !== "" ? `${arr[0]} hr` : "";
    arr[1] = arr[1] !== "" ? `${arr[1]} min` : "";
    arr[2] = arr[2] !== "" ? `${arr[2]} Sec` : "";
    let newVal = arr.join(" ");
    return newVal
      .replace("00 hr", "")

      ;
  };
  const handleClick = (e) => {
    e.preventDefault();
    return;
  };
  return (
    <>
      <div className={`playlist-wrapper ${[
          "iPad Simulanavigator.platformtor",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
          // iPad on iOS 13 detection
          (navigator.userAgent.includes("Mac") &&
            "ontouchend" in document) ? "ios_device" : "android_device"}`}>
        <span
          className="close"
          onClick={() => {
            setPlayListDisplay(false);
          }}
        >
          <FiX />
        </span>

        <h3>Playlist</h3>
        <h5>Now Playing </h5>

        <div className="now-playing-wrap">
          <div className={`audio-list  row ${play && activeId ? "playing" : "paused"}`}>
            <Link
              className="full_link"
              to={`/podcast/${objectFound?.podcastSlug}/${objectFound?.url}`}
            >
            </Link>
            <div className={`status col-sm-1  `} >
              <span
                className={`play-icon  ${classes.podcastPlayClass} i_center ${play && activeId === objectFound?.id ? "pause" : ""} `}
                onClick={(e) => {
                  handleClick(e);
                  // handlePlay(!play);
                  // handleSetActiveId(objectFound?.id);

                  // handlePlayThisItem(item?.id);
                  if (podCastPlayListData.filter(podCastPlayListItem => podCastPlayListItem.id === objectFound?.id)) {

                    if (play && activeId === objectFound?.id) {
                      handlePlay(!play);
                    } else {
                      handlePlayThisItem(objectFound?.id);
                      // handleClick(e);
                      handleSetActiveId(objectFound?.id);
                    }

                  }
                  else {
                    handlePlayThisItem(objectFound?.id);
                    handleSetActiveId(objectFound?.id);
                    handlePlay(true);
                  }

                }}
              >
                {play && activeId === objectFound.id ? 
                  // play
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><g transform="translate(-323 -1192)"><rect width="3" height="12" rx="1" transform="translate(323 1192)"/><rect width="3" height="12" rx="1" transform="translate(329 1192)"/></g></svg>
                  : 
                  // pause
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><path d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z" transform="translate(10) rotate(90)" fill="#ebab0b"/></svg>
              
                }
              </span>

              <span className="si_no i_center">1</span>
              <div className={`sound_bars ${classes.soundbarColor} i_center`}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            </div>

            <div className={`thumb-title  col-sm-7`}>
              <div className="audio-thumb">
                <figure>
                  <img src={objectFound?.thumbnail?.small} alt="" />
                </figure>
                <div className="audio-dtls">
                  <strong className={classes.txtColor}>{objectFound?.title}</strong>
                  {/*<span>
                     {objectFound?.programTitle
                      ? objectFound?.programTitle
                      : objectFound?.podcastTitle}
                    {objectFound?.host && objectFound?.host?.[0]?.name && (
                      <>, {objectFound?.host && objectFound?.host?.[0]?.name}</>
                    )}
                  </span> */}
                </div>
              </div>
            </div>
            {/* <div className="episode-number col">
              Episode {objectFound?.episodeNumber}
            </div> */}
            <div className="audio-duration col-sm-3">
              {" "}
              {play && activeId === objectFound.id ? progressData?.remain : formatTime(objectFound?.duration)}
            </div>
            <div className="share col-sm-1">
              <Link
                className="share_btn"
                onClick={() => {
                  setShareModal(true);
                  setShareContentModal(objectFound?.id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.49"
                  height="22.673"
                  viewBox="0 0 20.49 22.673"
                >
                  <g transform="translate(0.7 0.765)">
                    <path
                      d="M-54.467,317.206v12.6a1.053,1.053,0,0,0,1.075,1.029H-36.45a1.052,1.052,0,0,0,1.074-1.029v-12.6"
                      transform="translate(54.467 -309.626)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1.4"
                    />
                    <g transform="translate(5.406)">
                      <path
                        d="M-46.109,321.01V309.376"
                        transform="translate(50.214 -308.903)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="square"
                        strokeWidth="1.4"
                      />
                      <path
                        d="M-50.214,312.874l3.856-3.856a.4.4,0,0,1,.557,0l3.855,3.856"
                        transform="translate(50.214 -308.903)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="square"
                        strokeWidth="1.4"
                      />
                    </g>
                  </g>
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <h5>
          Next From:{" "}
          {objectFound?.programTitle
            ? objectFound?.programTitle
            : objectFound?.podcastTitle}
        </h5>

        <div className="playlist-queue-wrap ">
          <div className="scroll_wrap">
            {podCastPlayListData.map((item, index) => {
              return (
                <>

                  <div className={`audio-list row ${play && activeId === item?.id ? "active" : ""}`}>
                    <Link className="full_link"
                      to={`/podcast/${item?.podcastSlug}/${item?.url}`}

                    ></Link>
                    <div className="status col-sm-1">
                      <span
                        className={`play-icon ${classes.podcastPlayClass} ${play && activeId === item?.id ? "pause" : ""} i_center `}
                        onClick={(e) => {

                          // handlePlayThisItem(item?.id);
                          if (podCastPlayListData.filter(podCastPlayListItem => podCastPlayListItem.id === item?.id)) {

                            if (play && activeId === item?.id) {
                              handlePlay(!play);
                            } else {
                              handlePlayThisItem(item?.id);
                              handleClick(e);
                              handleSetActiveId(item?.id);
                            }

                          }
                          else {
                            handlePlayThisItem(item?.id);
                            handleSetActiveId(item?.id);
                            handlePlay(true);
                          }

                        }}
                      >
                        {play && activeId === item.id ? 
                          // play
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><g transform="translate(-323 -1192)"><rect width="3" height="12" rx="1" transform="translate(323 1192)"/><rect width="3" height="12" rx="1" transform="translate(329 1192)"/></g></svg>
                          : 
                          // pause
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><path d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z" transform="translate(10) rotate(90)" fill="#ebab0b"/></svg>
                      
                        }
                      </span>

                      <span className={`si_no i_center ${play && activeId === item?.id ? classes.txtColor : ''}`}>{index + 1}</span>
                      <div className={`sound_bars ${play && activeId === item?.id ? classes.soundbarColor : ''} i_center`}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                      </div>
                    </div>

                    <div className="thumb-title col-sm-7">
                      <div className="audio-thumb">
                        <figure>
                          <img src={item?.thumbnail?.small} alt="" />
                        </figure>

                        <div className="audio-dtls">
                          <strong className={play && activeId === item?.id ? classes.txtColor : ''}>{item?.title} </strong>
                          {/* <span>
                              {item?.programTitle}
                              {item?.host && item?.host?.[0]?.name && (
                                <>, {item?.host && item?.host?.[0]?.name}</>
                              )}
                            </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="audio-duration col-sm-3">
                      {formatTime(item?.duration)}
                    </div>

                    <div className="share col-sm-1">

                      <Link
                        className="share_btn"
                        onClick={() => {
                          setShareModal(true);
                          setShareContentModal(item?.id);
                        }}

                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.49"
                          height="22.673"
                          viewBox="0 0 20.49 22.673"
                        >
                          <g transform="translate(0.7 0.765)">
                            <path
                              d="M-54.467,317.206v12.6a1.053,1.053,0,0,0,1.075,1.029H-36.45a1.052,1.052,0,0,0,1.074-1.029v-12.6"
                              transform="translate(54.467 -309.626)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="1.4"
                            />
                            <g transform="translate(5.406)">
                              <path
                                d="M-46.109,321.01V309.376"
                                transform="translate(50.214 -308.903)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="square"
                                strokeWidth="1.4"
                              />
                              <path
                                d="M-50.214,312.874l3.856-3.856a.4.4,0,0,1,.557,0l3.855,3.856"
                                transform="translate(50.214 -308.903)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="square"
                                strokeWidth="1.4"
                              />
                            </g>
                          </g>
                        </svg>
                      </Link>
                    </div>
                  </div>

                </>
              );
            })}
          </div>
        </div>
      </div>


      <PodcastShareModal
        show={showShareModal}
        handleSetMessage={handleSetMessage}
        onHide={(e) => {
          setShareModal(false);
          setMessage();
        }}
        message={message}
        episode={podCastPlayListData.find((item) => item.id === shareContentModal)}
        podcastSlug={""}
        copyLinkText={
          window.location.origin +
          "/podcast/" +
          podCastPlayListData.find((item) => item.id === shareContentModal)
            ?.podcastSlug +
          "/" +
          podCastPlayListData.find((item) => item.id === shareContentModal)?.url
        }
      />
    </>
  );
};

export default Playlist;
