import React, { useState } from "react";
import styles from "./Endorsements.module.scss";
import ReactPlayer from "react-player/lazy";
import { useSeasonal } from "../../logic/Seasonal";

const EndorsementItemInner = ({
  endorse,
  currentPlay,
  setCurrentPlay,
  key,
  classes,
}) => {
  const [play, setPlay] = useState(false);

  return (
    <div className="col-lg-4 col-xs-6" key={key}>
      <div
        className={styles.img_wrap}
        onClick={() => {
          setPlay(!play);
          setCurrentPlay(endorse?.video);
        }}
      >
        {!play ? (
          <>
            <img src={endorse?.image} className={styles.img_tag}></img>
            <span></span>
          </>
        ) : (
          <ReactPlayer
            key={key}
            url={endorse?.video}
            width="100%"
            height="100%"
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload noplaybackrate",
                  disablePictureInPicture: true,
                },
              },
            }}
            playing={currentPlay === endorse?.video && play}
            style={{ position: "absolute" }}
            controls={true}
          />
        )}
      </div>
      <div className={styles.content_wrap}>
        <div className={styles.quote_box}>
          <q>{endorse?.quote}</q>
        </div>
        <h4 className={classes?.txtColor}>{endorse?.name}</h4>
        <p>{endorse?.designation}</p>
      </div>
      {/* <a
        className={styles.full_link}
        
      ></a> */}
    </div>
  );
};

export default EndorsementItemInner;
