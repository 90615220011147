import React, { useState, useCallback, useRef } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Container, Row, Col } from "react-bootstrap";
import GoogleRecaptcha from "../../components/Recaptcha";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { salomapi3 } from "../../apis";
import { useSeasonal } from "../../logic/Seasonal";
import styles from "./NewSwpluseKnowMore.module.scss";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "black",
    },
  },
}));

function NewSwpluseKnowMore({ SetForm }) {
  const [termsandcondtn, setTerms] = useState(false);
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [isAlertMsg, setAlertMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [captchaReset, setCaptchaReset] = useState(false);
  const captcha = useRef(null);
  const [captchaValid, isCaptchaValid] = useState(false);
  const [validUser, isValidUser] = useState(false);
  const divClass = classNames(styles.div_fields, "col-sm-6");
  const inputClass = classNames(styles.input_fields);
  const { seasonal, classes } = useSeasonal();
  const re = /^[0-9\b]+$/;
  const validate = (values) => {
    const errors = {};

    if (!values.firstname) {
      errors.firstname = "Required";
    } else if (values.firstname.length > 15) {
      errors.firstname = "Must be 15 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.InvalidEmail = "Invalid email address";
    }

    if (!values.parish) {
      errors.parish = "Required";
    }

    if (!values.city) {
      errors.city = "Required";
    }
    if (!values.state) {
      errors.state = "Required";
    }
    // if (!values.phone) {
    //   errors.phone = "Required";
    // }
    // if (!values.role) {
    //   errors.role = "Required";
    // }
    if (!values.message) {
      errors.message = "Required";
    }
    // if (!values.diocese) {
    //   errors.diocese = "Required";
    // }
    // if (!values.require_swplus) {
    //   errors.require_swplus = "Required";
    // }
    // if (!values.require_swplus) {
    //   errors.require_swplus = "Required";
    // }
    if (!values.disclaimer) {
      errors.disclaimer = "Required";
    }
    if (!captchaValid) {
      errors.captchaValid = "Please valid the Captcha";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastName: "",
      phone: "",
      email: "",
      parish: "",
      city: "",
      state: "",
      role: "",
      require_swplus: "",
      diocese: "",
      message: "",
      disclaimer: false,
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        firstname: values.firstname,
        lastname: values.lastName,
        phone: values.phone,
        email: values.email,
        parish: values.parish,
        city: values.city,
        state: values.state,
        require_swplus: values.require_swplus,

        diocese: values.diocese,
        role: values.role,
        message: values.message,
        disclaimer: values.disclaimer,
      };

      if (captchaValid) {
        salomapi3.post("/media/knowmore", postData).then((response) => {
          if (response.data.status === "success") {
            setFormSuccess(true);
            setSuccessMsg(response?.data?.msg);
            setAlertMsg(true);
            resetForm({});
            setCaptchaReset(true);
            isCaptchaValid(false);

            setTimeout(() => {
              setFormSuccess(false);
              SetForm(false);
              setCaptchaReset(false);
              setAlertMsg(false);
              setSuccessMsg("");
            }, 4000);
          } else setErrorMsg(response.data.msg);
        });
      }
      if (captcha.current.getValue()) {
        isValidUser(true);
        isCaptchaValid(true);
      } else {
        isValidUser(false);
        isCaptchaValid(false);
      }
      //  alert(JSON.stringify(values, null, 2));
    },
  });

  const handleOnChange = () => {
    isCaptchaValid(true);
  };

  return (
    <div className={`${styles.swplus_knowmore} d-none`}>
      <Container>
        <form className={styles.form_align} onSubmit={formik.handleSubmit}>
          <Row className={styles.div_align}>
            <div className={divClass}>
              <label>First Name *</label>
              <input
                className={`${inputClass} ${formik.errors.firstname &&
                  formik.submitCount &&
                  styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                name="firstname"
                placeholder="First Name"
              ></input>
            </div>
            <div className={divClass}>
              <label>Last Name *</label>
              <input
                className={`${inputClass} ${formik.errors.lastName &&
                  formik.submitCount &&
                  styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                name="lastName"
                placeholder="Last Name"
              ></input>
            </div>
          </Row>
          <Row className={styles.div_align}>
            <div className={divClass}>
              <label>Phone</label>
              <input
                className={`${inputClass} ${formik.errors.phone && formik.submitCount && styles.formerror
                  }`}
                type="text"
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    formik.setFieldValue("phone", e.target.value);
                  }
                }}
                value={formik.values.phone}
                name="phone"
                placeholder="phone"
              ></input>
            </div>
            <div className={divClass}>
              <label>Email *</label>
              <input
                className={`${inputClass} ${formik.errors.email && formik.submitCount && styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                placeholder="Email Address"
              ></input>
            </div>
          </Row>
          <Row className={styles.div_align}>
            <div className={divClass}>
              <label>Would you like SW+ on your parish/ministry website</label>
              <select
                name="require_swplus"
                value={formik.values.require_swplus}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`${inputClass} ${formik.errors.require_swplus &&
                  formik.submitCount &&
                  styles.formerror
                  }`}
              >
                <option>Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className={divClass}>
              <label>Parish Name *</label>
              <input
                className={`${inputClass} ${formik.errors.parish && formik.submitCount && styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.parish}
                name="parish"
                placeholder="Enter Parish Name"
              ></input>
            </div>
          </Row>
          <Row className={styles.div_align}>
            <div className={divClass}>
              <label>Your role at the parish</label>
              <input
                className={`${inputClass} ${formik.errors.role && formik.submitCount && styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.role}
                name="role"
                placeholder="Enter Your role at the parish"
              ></input>
            </div>
            <div className={divClass}>
              <label>Diocese</label>
              <input
                className={`${inputClass} ${formik.errors.diocese &&
                  formik.submitCount &&
                  styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.diocese}
                name="diocese"
                placeholder="Enter Diocese"
              ></input>
            </div>
          </Row>
          <Row className={styles.div_align}>
            <div className={divClass}>
              <label>State *</label>
              <input
                className={`${inputClass} ${formik.errors.state && formik.submitCount && styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.state}
                name="state"
                placeholder="Enter  State"
              />
            </div>
            <div className={divClass}>
              <label>City *</label>
              <input
                className={`${inputClass} ${formik.errors.city && formik.submitCount && styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.city}
                name="city"
                placeholder="Enter  City"
              />
            </div>
          </Row>

          <Row className={styles.div_align}>
            <div className={divClass}>
              <label>Your request *</label>
              <input
                className={`${inputClass} ${formik.errors.message &&
                  formik.submitCount &&
                  styles.formerror
                  }`}
                type="text"
                onChange={formik.handleChange}
                value={formik.values.message}
                name="message"
                placeholder="Enter Your request"
              ></input>
            </div>
          </Row>
          <Row className={styles.div_align}>
            <div className={styles.captchaWrap}>
              <GoogleRecaptcha
                onChange={handleOnChange}
                isCaptchaReset={captchaReset}
              />
            </div>
          </Row>
          <Row className={styles.div_align}>
            <Col>
              <div className="checkbox_wrap">
                <input
                  type="checkbox"
                  id="accept"
                  className="form-control"
                  name="disclaimer"
                  checked={formik.values.disclaimer}
                  value={formik.values.disclaimer}
                  onChange={(e) => {
                    formik.setFieldValue("disclaimer", e.target.checked);
                  }}
                />
                <label htmlFor="accept">
                  <span>
                    I agree to the
                    <a href="/termsandconditions" target="_blank">
                      Terms and Conditions .
                    </a>
                  </span>
                </label>
              </div>
            </Col>
          </Row>
          <button
            className={`${styles.signup_btn} ${seasonal?.classes?.bgBtnClass}`}
            type="submit"
          >
            Submit
          </button>
          {Object.keys(formik.errors).length != 0 && formik.submitCount ? (
            <div className="form-group col-sm-12">
              <span className={styles.formtotNullfieldErr}>
                Please fill all required fields.
              </span>
            </div>
          ) : null}
          {formik.errors.InvalidEmail && formik.submitCount ? (
            <div className="form-group col-sm-12">
              <span className={styles.formtotNullfieldErr}>
                Please Enter a valid Email Id
              </span>
            </div>
          ) : null}

          {errorMsg ? (
            <div className="form-group col-sm-12">
              <span className={styles.resonseFailedMsg}>{errorMsg}</span>
            </div>
          ) : null}
          {isFormSuccess ? (
            <div className="form-group col-sm-12">
              <div
                className={`${styles.alert_msg_wrap} ${isAlertMsg ? styles.show : styles.hide
                  }`}
              >
                <span
                  className={`${styles.resonseSuccessMsg} ${styles.alert_msg}`}
                >
                  {successMsg}
                </span>
                <a
                  className={styles.close_alert}
                  onClick={() => {
                    setAlertMsg(false);
                  }}
                >
                  &times;
                </a>
              </div>
            </div>
          ) : null}
        </form>
      </Container>
    </div>
  );
}

export default NewSwpluseKnowMore;
