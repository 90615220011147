import React,{useState, useEffect} from 'react'
import { connect } from "react-redux";
import styles from './PrivacyStatements.module.scss';
import Container from 'react-bootstrap/Container';
import BrowserPersistence from '../../util/simplePersistance';
import ReactHtmlParser from 'react-html-parser';
import {fetchPrivacy} from '../../actions/privacyActions';
const storage = new BrowserPersistence();

   
    

const PrivacyStatements = ({fetchPrivacy,privacy}) => {
    const region = storage.getItem('CountryCode');
    useEffect(() => {
       
        fetchPrivacy(region);
      }, [region]);

    
    return(
      <Container>
        <div className={styles.sw_cmsWrap}>
            <div>{ReactHtmlParser(privacy.content)}</div>
          
        </div>
      </Container>
    )
}


const mapPropsToState = (state) => {
    return {  privacy: state.privacy };
  };
  export default connect(mapPropsToState, { fetchPrivacy })(
    PrivacyStatements
  );