import React, { useEffect } from "react";
import CommonLayout from "../../Layouts/CommonLayout";
import Banner from "../Banner/Banner";
import CountrySelectBox from "../CountrySelectBox/CountrySelectBox";
import CountryDataCard from "../CountryDataCards";
import ContentSection from "../ContentSection";
import VechcleTypes from "../VechcleTypes";
import DonateMore from "../DonateMore";
import { connect } from "react-redux";
import styles from "./AmazonSmile.module.scss";
import Howandwhy from "../Howandwhy";
import { fetchAmazonSmile } from "../../../actions/amazonSmileAction";
import StepsToShop from "../StepsToShop";
import { useSeasonal } from '../../../logic/Seasonal';

const AmazonSmile = ({ amazonSmile, fetchAmazonSmile }) => {
  useEffect(() => {
    // if(fellowship.length ==0)
    fetchAmazonSmile();
  }, []);

  const handleChangeCountry = (event) => {
    fetchAmazonSmile(event);
  };
  const {seasonal,classes} = useSeasonal();
  return (
    <CommonLayout>
      <div className={styles.spfamazon_wrapper}>
        {Object.keys(amazonSmile).length > 0 ? (
          <>
          <div className="container">
            <Banner bannerDetails={amazonSmile.banner_details} />
            <CountrySelectBox
              handleChangeCountry={handleChangeCountry}
              countries={amazonSmile.countries}
              countrySelectionText={amazonSmile.country_selection_text}
              countrySelectionText1={amazonSmile.country_selection_text_1}
            />
          </div>
          <div>
            <section className={styles.content_section}>
              {amazonSmile.region === "others" && (
                <div className={`container ${styles.others}`}>
                <p>{amazonSmile.pageDescription}</p>
                </div>
              )}
              {amazonSmile.country_data && amazonSmile.region != "others" ? (
                <>
                <div className="container">
                  <ContentSection
                    heading={amazonSmile.country_data.pageHeading}
                    description={amazonSmile.country_data.pageDescription}
                    donationTxt={amazonSmile.country_data.shopButtonTxt}
                    donationUrl={amazonSmile.country_data.shopButtonUrl}
                    target="_blank"
                  />
                  <section className={`${styles.howandwhy} ${classes?.contentLinkColor}`}>
                  <div className="row">
                  <div className="col-md-6">
                    <Howandwhy
                      heading={amazonSmile.country_data.whyItMattersTitle}
                      description={
                        amazonSmile.country_data.whyItMattersDescription
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <Howandwhy
                      heading={amazonSmile.country_data.howItWorksTitle}
                      description={
                        amazonSmile.country_data.howItWorksDescription
                      }
                    />
                  </div>
                  </div>
                  </section>
                  </div>


                  {amazonSmile.country_data.steps && (
                    <section className={styles.howtoshop}>
                      <div className="container">
                        <h3>{amazonSmile.country_data.howToShopTitle}</h3>
                      <div className={styles.stepdetails}>
                      <StepsToShop
                        step="01"
                        description={amazonSmile.country_data.stepOneTitle}
                        buttonTxt={amazonSmile.country_data.stepOneButtonText}
                        buttonUrl={amazonSmile.country_data.stepOneButtonUrl}
                        target="_blank"
                      />
                      <StepsToShop
                        step="02"
                        description={amazonSmile.country_data.stepTwoTitle}
                        datas={amazonSmile.country_data.stepTwoDescription}
                      />
                      <StepsToShop
                        step="03"
                        description={amazonSmile.country_data.stepThreeContent}
                        buttonTxt={amazonSmile.country_data.stepOneButtonText}
                        buttonUrl={amazonSmile.country_data.stepOneButtonUrl}
                        target="_blank"
                      />
                      </div>
                      </div>
                    </section>
                  )}
                </>
              ) : null}
              {amazonSmile.country_data ? (
                <DonateMore
                  moreWaysGiveTitle={amazonSmile.country_data.moreWaysGiveTitle}
                  moreWaysGive={amazonSmile.country_data.moreWaysGive}
                />
              ) : null}
            </section>
          </div>
          </>
        ) : null}
      </div>
      
    </CommonLayout>
  );
};
const mapPropsToState = (state) => {
  return { amazonSmile: state.amazonSmileData.amazonSmile };
};
export default connect(mapPropsToState, { fetchAmazonSmile })(AmazonSmile);
