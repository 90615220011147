import React from "react";
import styles from "./PodcastEpisodeCarousel.module.scss";
// import imglist1 from "../../assets/img/list.png";
// import imglist2 from "../../assets/img/list2.png";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Link } from "react-router-dom";
import { useSeasonal } from '../../logic/Seasonal'

const PodcastEpisodeCarousel = ({
  data,
  handlePlay,
  play,
  handleUpdatePlaylist,
  activeId,
  copyLinkText,
  type,
  currentPid
}) => {
  const formatTime = (duration) => {
    if (!duration) return;
    let arr = duration.split(":"); // splitting the string by colon
    arr[0] = arr[0] !== "" ? `${arr[0]} hr` : "";
    arr[1] = arr[1] !== "" ? `${arr[1]} min` : "";
    arr[2] = arr[2] !== "" ? `${arr[2]} Sec` : "";
    let newVal = arr.join(" ");
    return newVal.replace("00 hr", "");
  };

  const playToggleClass = (id, episode) => {
    if (id === activeId) {
      handlePlay(!play);
    } else {
      handleUpdatePlaylist(id);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    return;
  };
  const { classes } = useSeasonal()

  return (
    <>
      <div className={`${styles.PodcastEpisodeCarousel} `}>

        {data?.episodes && data?.episodes.length >= 1 && (
          <>
            <div className={styles.CarouselTitleWrapper}>
              {data?.episodes.filter(item => item.id !== currentPid)?.length >= 1 && <h4>Other episodes</h4>}

            </div>
            <div className={`${styles.EpisodeCarouselWrap} ${classes.swiperSliderClass}`}>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                slidesPerView={6.75}
                spaceBetween={10}
                className="episode-carousel-container"
                loop={false}
                breakpoints={{
                  0: {
                    slidesPerView: 2.15,
                  },
                  480: {
                    slidesPerView: 2.75,
                  },
                  768: {
                    slidesPerView: 3.75,
                  },
                  1024: {
                    slidesPerView: 4.75,
                  },
                  1600: {
                    slidesPerView: 5.75,
                  },
                }}
              >
                {data?.episodes &&
                  data?.episodes.map((item, index) => {
                    return (
                      <SwiperSlide key={index} className={`swipe-slide-${index} ${currentPid === item.id ? "d-none" : ""}`}>

                        <Link to={type === 'desktop' ? `/podcast/${item?.podcastSlug}/${item?.url}` : false}>
                          <div
                            className={`episode-slide ${styles.episodeListSlide}`}
                          >
                            <figure className="figImg">
                              <img src={item?.thumbnail?.small} alt={item?.title} />
                              <figcaption>
                                <span
                                  className={`play-icon ${play && activeId === item.id ? "pause" : ""
                                    } ${classes.podcastPlayClass}`}
                                  onClick={(e) => {
                                    handleClick(e);
                                    playToggleClass(item.id, item);
                                  }}
                                >
                                  {play && activeId === item.id ?
                                    // play
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><g transform="translate(-323 -1192)"><rect width="3" height="12" rx="1" transform="translate(323 1192)" /><rect width="3" height="12" rx="1" transform="translate(329 1192)" /></g></svg>
                                    :
                                    // pause
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><path d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z" transform="translate(10) rotate(90)" fill="#ebab0b" /></svg>

                                  }
                                </span>
                                <span className="time">
                                  {formatTime(item?.duration)}
                                </span>
                              </figcaption>
                            </figure>
                            <h5 >{item?.title}</h5>
                            <span className="pTooltip">{item?.title}</span>
                          </div>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PodcastEpisodeCarousel;
