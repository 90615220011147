import React from 'react'
import CommonLayout from '../components/Layouts/CommonLayout'
import Searchresult from '../components/SearchResult'
import SocialMediaWrap from '../components/SocailMediaIcons'
import { useDimensions } from '../logic/Dimentions'
import { useSeasonal } from '../logic/Seasonal'

const SearchResult = () => {
  const { width } = useDimensions()
  const { classes } = useSeasonal()
  return (
    <CommonLayout>
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <Searchresult />
    </CommonLayout>
  )
}

export default SearchResult
