/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PodCastPlayer from "../PodCastPlayer";
import PodcastAudioThumb from "../Mobile/PodcastAudioThumb";
import { connect } from "react-redux";
import { useDimensions } from "../../logic/Dimentions";

import {
  updatePlaylist,
  purgePlaylist,
  triggerPlay,
  setActiveId,
  setProgress,
} from "../../actions/podCastPlayListAction";
import { useLocation, useHistory } from "react-router-dom";

const PodCastLayout = ({
  podCastPlayListData,
  play,
  triggerPlay,
  purgePlaylist,
  activeId,
  setActiveId,
  setProgress,
  progressData,
}) => {
  const location = useLocation();
  const { width } = useDimensions();
  const [audioDtls, setAudioDtls] = useState(false);
  const [episodeInfo, setEpisodeInfo] = useState(false);
  const [isPlaylist, setPlaylist] = useState(false);

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event

          if (
            width < 991 &&
            (history.location.pathname.includes("/podcast/") ||
              history.location.pathname.includes("/podcasts"))
          ) {
            if (history.action === "POP") {

              if (isPlaylist) {
                setEpisodeInfo(false);
                setPlaylist(false);
              } else if (episodeInfo) {
                setPlaylist(false);
                setEpisodeInfo(false);
              } else if (audioDtls) {
                setEpisodeInfo(false);
                setAudioDtls(false);
                setPlaylist(false);
              } else {
                if (history.location.pathname.includes("/podcasts")) {
                  setPlaylist(false);
                  history.goBack();
                } else {
                  history.push("/podcasts");
                }
              }
              // Code here will run when back button fires. Note that it's after the `return` for useEffect's callback; code before the return will fire after the page mounts, code after when it is about to unmount.
            }
          }
        }
      }
    });
  }, [locationKeys]);

  /**
   *Set ID as active in playlist, Update Redux 'Playlist', setActiveId, trigger play
   */

  const handleUpdatePlaylist = (id) => {
    let indexFound = 0;
    podCastPlayListData?.forEach((element, index) => {
      if (id === element.id) {
        podCastPlayListData[index].active = true;
        handleSetActiveId(podCastPlayListData[index].id);
        indexFound = index;
      } else {
        podCastPlayListData[index].active = false;
      }
    });

    updatePlaylist(podCastPlayListData, indexFound);

    const timer = setTimeout(() => {
      handlePlay(true);
    }, 60);
    return () => clearTimeout(timer);
  };

  const handlePlay = (status) => {
    triggerPlay(status);
  };

  const handleClearPlaylist = () => {
    purgePlaylist();
  };

  const handleSetActiveId = (id) => {
    setActiveId(id);
  };

  const handleProgress = (obj) => {
    setProgress(obj);
  };

  useEffect(() => {
    if (!location?.pathname.includes("podcast")) {
      handlePlay(false);
      handleClearPlaylist();
    }
  }, [location]);

  return (
    <>
      {/* Posdcast Player section */}

      {podCastPlayListData && podCastPlayListData?.length >= 1 && (
        <>
          {width >= 992 ? (
            <>
              <PodCastPlayer
                podCastPlayListData={podCastPlayListData}
                handleSetActiveId={handleSetActiveId}
                activeId={activeId}
                play={play}
                handlePlay={handlePlay}
                handleUpdatePlaylist={handleUpdatePlaylist}
                handleClearPlaylist={handleClearPlaylist}
                handleProgress={handleProgress}
                progressData={progressData}
              />
            </>

          ) : (
            // Do not show Podcast player here, it will be loaded on detail screen, show only sticky meny
            <>
              <PodcastAudioThumb
                episode={podCastPlayListData.find(
                  (item) => item.active === true
                )}
                podCastPlayListData={podCastPlayListData}
                handleSetActiveId={handleSetActiveId}
                activeId={activeId}
                play={play}
                handlePlay={handlePlay}
                handleUpdatePlaylist={handleUpdatePlaylist}
                handleClearPlaylist={handleClearPlaylist}
                handleProgress={handleProgress}
                progressData={progressData}
                setAudioDtls={setAudioDtls}
                audioDtls={audioDtls}
                episodeInfo={episodeInfo}
                setEpisodeInfo={setEpisodeInfo}
                setPlaylist={setPlaylist}
                isPlaylist={isPlaylist}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    podCastPlayListData: state.podCastPlayList?.playLists.data,
    podCastEpisode: state.podCastEpisode,
    play: state.podCastPlayList?.play,
    activeId: state.podCastPlayList?.activeId,
    progressData: state.podCastPlayList?.progress,
  };
};

export default connect(mapStateToProps, {
  triggerPlay,
  purgePlaylist,
  setActiveId,
  setProgress,
})(PodCastLayout);
