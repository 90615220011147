import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import homePage from "../pages/homePage";
import Shows from "../pages/Shows";
import Episodes from "../pages/Episodes";
import EpisodeVideo from "../pages/EpisodeVideo";
import Careers from "../pages/Careers";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import Swprayer from "../pages/Swprayer";
import Playgournd from "../pages/Playground";
import ShowDetailsPage from "../pages/ShowDetails";
import News from "../pages/News";
import WatchLive from "../pages/WatchLive";
import PeaceFellowship from "../pages/PeaceFellowship";
import MediaGallery from "../pages/MediaGallery";
import Statements from "../pages/Statements";
import TermsandConditions from "../pages/TermsandConditions";
import WatchOn from "../pages/WatchOn";
import Feedback from "../pages/Feedback";
import NewsDetailPage from "../pages/NewsDetailPage";
import Mail from "../pages/Mail";
import ApplyNowPage from "../pages/ApplyNowPage";
import CardonationPage from "../pages/CardonationPage";
import EmployeeMatchingPage from "../pages/EmployeeMatchingPage";
import ScrollToTop from "./ScrollToTop";
import AmazonSmilePage from "../pages/AmazonSmilePage";
import CommonLoader from "./Shimmers/CommonLoader";
import SearchResult from "../pages/SearchResultPage";
import CookiePolicyPopup from "./CookiePolicy";
// import NewsLetterPopup from "./SubscriptionNewsLetter";
import ErrorPage from "../pages/Errorpage";
import EpisodeErrorPage from "../pages/EpisodeErrorPage";
import ShowErrorPage from "../pages/ShowErrorPage";
import Xmaspage from "../pages/XmasPage";
import DonationPage from "../pages/DonationPage";
import SwplusDashboardPage from "../pages/SwPlusDashboard";
import ShortFilmContestPage from "../pages/ShortFilmContestPage";
import PodcastPage from "../pages/PodcastPage";
import PodcastDetailPage from "../pages/PodcastDetailPage";
import PodcastEpisodeDetailPage from "../pages/PodcastEpisodeDetailPage";
import PromotionPage from "../pages/PromotionPage";
import SeasoanlLayout from "./Seasonal";
import PodCastLayout from "./PodCastLayout";
import DollarDayPage from "../pages/DollarDayPage";
import AllEventRelatedVideosPage from "../pages/AllEventRelatedVideosPage";
import AllPastVideosPage from "../pages/AllPastVideosPage";
// import AppModal from "./AppModal";
import "../styles/_styles.scss";

const EventPage = lazy(() => import('../pages/EventPage'));
// import AwardsPage from "../pages/AwardsPage";

const App = () => {
  return (
    <Router basename={"/"}>
      <div>
        <Suspense fallback={<CommonLoader />}>
          <ScrollToTop />
          <SeasoanlLayout>
            <Switch>
              <Route exact path="/" component={homePage}></Route>
              <Route exact path="/shows" component={Shows}></Route>
              <Route exact path="/shows/:category" component={Shows}></Route>
              <Route
                exact
                path="/show/:showId/:speakerId?/:categoryId?"
                component={ShowDetailsPage}
              ></Route>
              <Route exact path="/episodes" component={Episodes}></Route>
              <Route
                exact
                path="/episodes/:category"
                component={Episodes}
              ></Route>
              <Route
                path="/episode/:episodeId"
                component={EpisodeVideo}
              ></Route>
              <Route exact path="/career" component={Careers}></Route>
              <Route
                exact
                path="/career/:careerId"
                component={ApplyNowPage}
              ></Route>
              <Route exact path="/contact/" component={ContactUs}></Route>
              <Route exact path="/about" component={AboutUs}></Route>
              <Route exact path="/news" component={News}></Route>
              <Route path="/news/:newsId" component={NewsDetailPage}></Route>
              <Route exact path="/watchlive" component={WatchLive}></Route>
              <Route
                exact
                path="/watchlive/:fullschedule"
                component={WatchLive}
              ></Route>
              <Route
                exact
                path="/peacefellowship"
                component={PeaceFellowship}
              ></Route>
              <Route
                exact
                path="/peacefellowship/mail"
                component={Mail}
              ></Route>

              <Route
                exact
                path="/searchResult/:searchTerm"
                component={SearchResult}
              ></Route>
              <Route exact path="/swplus" component={MediaGallery}></Route>
              <Route
                exact
                path="/swplus/signin/"
                component={MediaGallery}
              ></Route>
              <Route
                exact
                path="/swplus/forgotpassword"
                component={MediaGallery}
              ></Route>
              <Route
                exact
                path="/swplus/dashboard"
                component={SwplusDashboardPage}
              ></Route>

              <Route exact path="/news" component={News}></Route>
              <Route exact path="/swprayer" component={Swprayer}></Route>
              <Route exact path="/swprayer/:type" component={Swprayer}></Route>
              <Route exact path="/playground" component={Playgournd}></Route>
              <Route
                exact
                path="/privacystatement"
                component={Statements}
              ></Route>
              <Route
                exact
                path="/termsandconditions"
                component={TermsandConditions}
              ></Route>
              <Route exact path="/watchon" component={WatchOn}></Route>
              <Route
                path="/watchon/:tab/:platform?"
                component={WatchOn}
              ></Route>
              <Route exact path="/feedback" component={Feedback}></Route>
              <Route
                exact
                path="/cardonation"
                component={CardonationPage}
              ></Route>
              <Route
                exact
                path="/employermatching"
                component={EmployeeMatchingPage}
              ></Route>
              <Route
                exact
                path="/amazonsmile"
                component={AmazonSmilePage}
              ></Route>
              <Route exact path="/god-with-us" component={Xmaspage}></Route>
              <Route
                exact
                path="/short-film-contest"
                component={ShortFilmContestPage}
              ></Route>
              <Route exact path="/donate" component={DonationPage}></Route>

              {/* Podcast Group */}
              <Route exact path="/podcasts" component={PodcastPage}></Route>
              <Route
                exact
                path="/podcast/:programId"
                component={PodcastDetailPage}
              ></Route>
              <Route
                exact
                path="/podcast/:programId/:episodeId"
                component={PodcastEpisodeDetailPage}
              ></Route>
              <Route
                exact
                path="/promotion/:promotionId"
                component={PromotionPage}
              />
              {/*
              <Route exact path="/awards" component={AwardsPage} />
              <Route exact path="/awards/:year" component={AwardsPage} />
               */}
              <Route exact path="/dollar-a-day" component={DollarDayPage}></Route>
              <Route exact path="/dollar-a-day-ca" component={DollarDayPage}></Route>
              <Route exact path="/dollar-a-day-eu" component={DollarDayPage}></Route>
              <Route exact path="/dollar-a-day-fr" component={DollarDayPage}></Route>
              <Route exact path="/dollar-a-day-uk" component={DollarDayPage}></Route>
              <Route exact path="/dollar-a-day-ch" component={DollarDayPage}></Route>
              <Route exact path="/prisons" component={DollarDayPage}></Route>

              <Route exact path="/events/:eventId" component={EventPage}></Route>

              <Route exact path="/events/:eventId/all-related-videos" component={AllEventRelatedVideosPage}></Route>
              <Route exact path="/events/:eventId/all-past-videos" component={AllPastVideosPage}></Route>

              <Route
                exact
                path="/episode-not-found"
                component={EpisodeErrorPage}
              />

              <Route exact path="/show-not-found" component={ShowErrorPage} />

              <Route component={ErrorPage} />
            </Switch>
            {/* <AppModal /> */}
            <CookiePolicyPopup />
            {/* <NewsLetterPopup /> */}
            <PodCastLayout />

          </SeasoanlLayout>

        </Suspense>

      </div>
    </Router>
  );
};

export default App;
