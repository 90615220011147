import React from "react";
import styles from "./PodcastListenOn.module.scss";
import ListenOn from "../../ListenOn";
import { connect } from "react-redux";
const PodcastListenOn = ({ podCastData, listenOn, setListenOn, data }) => {
    return (
        <div
            className={`${styles.PodcastSubscribe} ${listenOn ? styles.show : ""}`}
        >
            <span
                className={styles.downArrow}
                onClick={() => {
                    setListenOn(false);
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                >
                    <g transform="translate(-174 -155)">
                        <g
                            transform="translate(174 155)"
                            fill="#fff"
                            stroke="#707070"
                            strokeWidth="1"
                            opacity="0"
                        >
                            <rect width="28" height="28" stroke="none" />
                            <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
                        </g>
                        <path
                            d="M0,0,10.521,3.912,19.8,0"
                            transform="translate(178.5 167.5)"
                            fill="none"
                            stroke="rgba(255,255,255,0.44)"
                            strokeLinecap="round"
                            strokeWidth="3"
                        />
                    </g>
                </svg>
            </span>
            <ListenOn data={data?.streamingPlatforms} />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        podCastData: state.podCast,
    };
};
export default connect(mapStateToProps)(PodcastListenOn);
