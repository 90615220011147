import React from "react";
import { useHome } from "../logic/Home/useHome";
import SwplusDashboard from "../components/SwplusDashboard";
import { connect } from "react-redux";
import { fetchOtherdetails } from "../actions/otherdetailsAction";
import { TitleComponent } from "../components/TitleComponent";
import { useSeo } from "../logic/Seo/useSeo";
import FeedbackLayout from "../components/Layouts/FeedbackLayout";

const SwplusDashboardPage = ({ fetchOtherdetails, otherdetails }) => {
  useHome({ fetchOtherdetails, otherdetails });
  useSeo();
  return (
    <FeedbackLayout>
      <TitleComponent title="Shalom world " />
      <SwplusDashboard />
    </FeedbackLayout>
  );
};
const mapStateToProps = (state) => {
  return { otherdetails: state.otherdetails.data };
};
export default connect(mapStateToProps, { fetchOtherdetails })(
  SwplusDashboardPage
);
