import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleRecaptcha = (props) => {
  const captcharef = useRef(null);
  // const [siteKey, isSiteKey] = useState(
  //   "6LfbmLQUAAAAAPI30cRn2mAPs0emzC3G8O2FhjqY"
  // );

  const [siteKey, isSiteKey] = useState(
    "6LeQAw0eAAAAADt-Dwy8YVO--de4dC7doKUcy_JS"
  );
  useEffect(() => {
    if (captcharef.current) {
      captcharef.current.reset();
    }
  }, [props.isCaptchaReset]);

  return (
    <>
      <ReCAPTCHA
        ref={captcharef}
        sitekey={siteKey}
        onChange={props.onChange}
        theme={"dark"}
      />
    </>
  );
};

export default GoogleRecaptcha;
