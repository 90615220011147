import React,{lazy} from 'react';
import BlogList from '../components/BlogList';
import CommonLayout from '../components/Layouts/CommonLayout';
import MailPage from '../components/MailPage';
const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));

const Mail = () => {

    return (
        <>
        <CommonLayout>
        <MailPage/>
        </CommonLayout>
        </>
    )
}

export default Mail;