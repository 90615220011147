import React, { useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player'
import Style from './ShowAbout.module.scss'
import { useSeasonal } from '../../../logic/Seasonal'
import SocialMediaWrap from "../../SocailMediaIcons";
import { useHistory } from "react-router-dom";

const ShowAbout = ({ value }) => {
  let { showId } = useParams()
  const { classes } = useSeasonal()
  const { hash } = useLocation();

  let myRef = useRef()
  let history = useHistory();

  const support = () => {
    history.push(`/donate`);
    // window.location.href = `https://shalomworld.my-shalom.org/sw/donation?CauseName=Shalom%20World%27s%20program%20production/${showId}`
    // window.location.href = `https://sit-shalomworld.my-shalom.org/sw/donation?CauseName=Shalom%20World%27s%20program%20production/${showId}`;
  }

  useEffect(() => {

    if (hash && hash === "#promo") {
      if (value?.promoVideo?.length > 0 && value?.description && value?.title) {

        const timer = setTimeout(() => {
          myRef.current.scrollIntoView({ behavior: 'smooth', block: "start" })
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [hash, value])

  return (
    <div className={Style.ShowAbout} >
      <h4>{value.title}</h4>
      <p>{value.description}</p>
      <button
        className={`${Style.web_btn} ${classes?.pauseClass}`}
        onClick={support}
      >
        Support
      </button>

      {value?.promoVideo?.length > 0 ? (
        <ReactPlayer

          url={value.promoVideo[0].video}
          width="100%"
          height="100%"
          playing={true}
          controls={true}
          muted={true}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload noplaybackrate',
                disablePictureInPicture: true,
              },
            },
          }}
        />
      ) : null}

      <div
        className={`${Style.socialShareBtn} ${classes.hoverColor} socialShareBtns`}

      >
        <span className={classes.hoverColor}>
          <i className="icon-share-episode"></i> Share
        </span>
        <div

          className={
            window.location.pathname.includes("show")
              ? `${Style.shareIcons} ${classes.socialMediaColor}`
              : ``
          }
        >
          <SocialMediaWrap classes={classes} urlParams={"#promo"} />
        </div>
      </div>
      <div className="clearfix" ref={myRef}></div>




    </div>
  )
}

export default ShowAbout
