
export const useTransition = () =>  {
    const duration = 1000;

    const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    }

    const transitionStyles = {
    entering: { opacity: 0,transition: `opacity ${duration}ms ease-in-out` },
    entered:  { opacity: 1,transition: `opacity ${duration}ms ease-in-out` },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
    };

    return {duration,defaultStyle,transitionStyles}
}