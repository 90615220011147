import { combineReducers } from "redux";
import bannerReducer from "./bannerReducer";
import mobileBannerReducer from "./mobileBannerReducer";

import programesReducer from "./programesReducer";
import otherdetailsReducer1 from "./otherdetailsReducer";
import latestEpisodesReducer from "./latestEpisodesReducer";
import currencyReducer1 from "./currencyReducer";
import footerReducer from "./footerReducer";
import categoryReducer from "./categoryReducer";
import episodeReducer from "./episodeReducer";
import careersReducer from "./careersReducer";
import contactusReducer from "./contactusReducer";
import menuReducer from "./menuReducer";
import newsReducer from "./newsReducer";
import newsCommentsReducer from "./newsCommentsReducer";
import aboutUsReducer from "./aboutUsReducer";
import LiveScheduleReducer from "./LiveScheduleReducer";
import swprayerDetailsReducer from "./swprayerDetailsReducer";
import testimoniesReducer from "./testimoniesReducer";
import dailymassReducer from "./dailymassReducer";
import swprayerReducer from "./swprayerReducer";
import secMenuReducer from "./secMenuReducer";
import fellowshipReducer from "./fellowshipReducer";
import mediagalleryReducer from "./mediagalleryReducer";
import watchonReducer from "./watchonReducer";
import fullScheduleReducer from "./fullScheduleReducer";
import commonSearchReducer from "./commonSearchReducer";
import watchLiveImagesReducer from "./watchLiveImagesReducer";
import socialLinkReducer1 from "./socialLinkReducer";
import carDonationReducer from "./carDonationReducer";
import realEstateDonationReducer from "./realEstateDonationReducer";
import employeeMatchingReducer from "./employeeMatchingReducer";
import amazonSmileReducer from "./amazonSmileReducer";
import mediaUserReducer1 from "./mediaUserReducer";
import termsReducer from "./termsReducer";
import privacyReducer from "./privacyReducer";
import websiteReducer1 from "./websiteReducer";
import mediaUserProfileReducer1 from "./mediaUserProfileReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cookiereducer1 from "./cookiereducer";
import liveScheduleMobileReducer from "./liveScheduleMobileReducer";
import mobmenuReducer from "./mobileMenuReducer";
import seoReducer from "./seoReducer";
import xmasReducer from "./xmasReducer";
import twitterReducer from "./twitterReducer";
import seasonalReducer from "./seasonalReducer";
import upcomingReducer from "./upComingReducer";
import donationReducer1 from "./donationReducer";
import regionReducer from "./regionReducer";
import shortFilmContestReucer from "./shortFilmContestReucer";
import adsReducer from "./adsReducer";
import podCastsReducer from "./podCastsReducer";
import podCastPlayListReducer from "./podCastPlayListReducer";
import podCastEpisodeReducer from "./podCastEpisodeReducer";
import promotionReducer from "./promotionReducer";
import awardsReducer from "./awardsReducer";
import eventsDetailsReducer from "./eventDetailsReducer";

//mediaUserReducer
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "userId",
    "userName",
    "userType",
    "accesstoken",
    "expiresIn",
    "tokenType",
    "scope",
    "requestToken",
    "emailVerified",
  ],
};

//mediaUserProfileReducer1

const persistConfig2 = {
  key: "root2",
  storage,
  whitelist: [
    "apiToken",
    "organization",
    "firstName",
    "lastName",
    "email",
    "location",
    "country",
    "phone",
    "styleType",
    "code",
    "domains",
    "cms",
    "user_cms",
    "status",
  ],
};
//Cookie
// const persistConfig3 = {
//   key: "root3",
//   storage,
// };

//Site
// const persistConfig4 = {
//   key: "root4",
//   storage,
// };
//Social links
const persistConfig5 = {
  key: "root5",
  storage,
};
//Currency
const persistConfig6 = {
  key: "root6",
  storage,
};

const mediaUserReducer = persistReducer(persistConfig, mediaUserReducer1);
const mediaUserProfileReducer = persistReducer(
  persistConfig2,
  mediaUserProfileReducer1
);
const socialLinkReducer = persistReducer(persistConfig5, socialLinkReducer1);
const currencyReducer = persistReducer(persistConfig6, currencyReducer1);


export default combineReducers({
  ads: adsReducer,
  banner: bannerReducer,
  mobileBanner: mobileBannerReducer,
  programes: programesReducer,
  otherdetails: otherdetailsReducer1,
  latestEpisodes: latestEpisodesReducer,
  currency: currencyReducer,
  footer: footerReducer,
  categories: categoryReducer,
  episodeVideo: episodeReducer,
  careers: careersReducer,
  contactUs: contactusReducer,
  news: newsReducer,
  comments: newsCommentsReducer,
  menu: menuReducer,
  aboutUs: aboutUsReducer,
  liveSchedule: LiveScheduleReducer,
  swprayerDetails: swprayerDetailsReducer,
  testimonies: testimoniesReducer,
  dailymass: dailymassReducer,
  swprayer: swprayerReducer,
  secMenu: secMenuReducer,
  fellowshipData: fellowshipReducer,
  mediaGallery: mediagalleryReducer,
  watchon: watchonReducer,
  fullSchedule: fullScheduleReducer,
  upcomingSchedule: upcomingReducer,
  commonSearch: commonSearchReducer,
  watchLiveImages: watchLiveImagesReducer,
  socialLink: socialLinkReducer,
  carDonationData: carDonationReducer,
  realEstateDonationData: realEstateDonationReducer,
  employeeMatchingData: employeeMatchingReducer,
  amazonSmileData: amazonSmileReducer,
  mediaUserReducer: mediaUserReducer,
  terms: termsReducer,
  privacy: privacyReducer,
  websiteData: websiteReducer1,
  cookieData: cookiereducer1,
  mediaUserProfileReducer: mediaUserProfileReducer,
  liveScheduleMobile: liveScheduleMobileReducer,
  mobilMenu: mobmenuReducer,
  seo: seoReducer,
  seasonal: seasonalReducer,
  xmasData: xmasReducer,
  twitterData: twitterReducer,
  donationData: donationReducer1,
  region: regionReducer,
  shortFilmContest: shortFilmContestReucer,
  mob: shortFilmContestReucer,
  podCasts: podCastsReducer,
  podCastPlayList: podCastPlayListReducer,
  podCastEpisode: podCastEpisodeReducer,
  promotion: promotionReducer,
  awards: awardsReducer,
  eventDetails: eventsDetailsReducer,
});
