import { FETCH_VIDEO, REMOVE_VIDEO, UPDATE_VIDEO } from './types';
import salomapi from '../apis';


export const fetchEpisodeVideo = (videoName, region) => async dispatch => {

    const response = await salomapi.get('/episode/single?url=' + videoName);

    if (response.data.status === 'episode_deleted') {
        dispatch({ type: FETCH_VIDEO, payload: response.data, maxLatestPage: 1, maxRelatedPage: 1, })
    } else {

        if (response.data.episodes?.length) {
            dispatch({ type: FETCH_VIDEO, payload: response.data.episodes[0], maxLatestPage: 1, maxRelatedPage: 1, })
        } else {
            dispatch({ type: FETCH_VIDEO, payload: response.data, maxLatestPage: 1, maxRelatedPage: 1, })
        }
    }
}
export const removeEpisodeVideo = (videoName, region) => async dispatch => {

    dispatch({ type: REMOVE_VIDEO, payload: [], maxLatestPage: null, maxRelatedPage: null })

}

export const updateEpisodeVideo = (data) => async dispatch => {

    dispatch({ type: UPDATE_VIDEO, payload: data, maxLatestPage: 1, maxRelatedPage: 1, })

}