import React from "react";
import DollarADayContentCA from "./DollarADayContentCA";
import DollarADayContentPrisons from "./DollarADayContentPrisons";
import DollarADayContentUS from "./DollarADayContentUS";
import DollarADayContentUK from "./DollarADayContentUK";
import DollarADayContentEurope from "./DollarADayContentEurope";
import DollarADayContentSwitzerland from "./DollarADayContentSwitzerland";
// import DollarADayContentAUS from "./DollarADayContentAUS";

/**
 *  This component renders a html content section. 
 * @param{setPlaying}  prop is used to set the `playing` state.
 * @param{pathName}  prop specifies the region for the video player.
 * The possible values are `/dollar-a-day-ca`, `/dollar-a-day-aus`, `/one-pound-a-day`, `/euro-a-day`, `/dollar-a-day-uk`, `/dollar-a-day-eu` and `/dollar-a-day-us`
 */
const DonateDollarADayContent = ({ pathName, setPlaying, }) => {
  return (
    <>
      {pathName === "/dollar-a-day-ca" ? (
        // Content for CA
        <DollarADayContentCA />

      ) : pathName === "/dollar-a-day-uk" ? (
        // Content for UK
        <DollarADayContentUK />

      ) : pathName === "/dollar-a-day-eu" ? (
        // Content for EU
        <DollarADayContentEurope />

      ) : pathName === "/dollar-a-day-ch" ? (
        // Content for CH,CHF
        <DollarADayContentSwitzerland/>
  
      ) : pathName === "/prisons" ? (
        // Content for Prisons
        <DollarADayContentPrisons />
      ) : (
        // Content for US, MX
        <DollarADayContentUS />
      )}
    </>
  );
};

export default DonateDollarADayContent;