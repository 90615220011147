import {FETCH_REAL_ESTATE_DONATION} from '../actions/types.js';

const INTIAL_STATE = {
    realEstateDonation:{},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_REAL_ESTATE_DONATION:
        return {
            ...state,
            realEstateDonation:action.payload
        }
        default:
            return state;
    }
}