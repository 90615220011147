import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import styles from "./OurShows.module.scss";
import ProgramList from "../ProgramList";
import {
  fetchPrograms,
  fetchCategoryPrograms,
  removeProgammes
} from "../../actions/programeAction";
import CategoryList from "../CategoryList";
import { connect } from "react-redux";
import { useDimensions } from "../../logic/Dimentions";
import { useProgramList } from "../../logic/ProgramList/useProgramList";
import { useParams, useHistory } from "react-router-dom";
import { TitleComponent } from "../TitleComponent";
import { useSeo } from "../../logic/Seo/useSeo";
import { useSeasonal } from "../../logic/Seasonal";
import SocialMediaWrap from "../SocailMediaIcons";
import Paginate from "../../util/Paginate";

const OurShows = ({
  fetchPrograms,
  categories,
  fetchCategoryPrograms,
  removeProgammes,
  programes,
}) => {
  const listingRef = useRef(null);

  const [categoryData, SetCatgeory] = useState("");
  const { width } = useDimensions();
  const { location } = useHistory();
  const { classes } = useSeasonal();
  let path = location.pathname;
  let { category } = useParams();
  const [check, setCheck] = useState(false);

  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 15,
  });
  const [isShimmer, setIsShimmer] = useState(false);

  useSeo();
  useProgramList({
    setPagination,
    fetchPrograms,
    programes,
    category,
    categories,
    path,
    fetchCategoryPrograms,
    pagination,
  });


  // Dynamically add and remove the script for Dollar-A-Day functionality
  useEffect(() => {
    const script = document.createElement("script");

    // Determine the script source based on the environment (production or QA)
    if (process.env.REACT_APP_PRODUCTION === "true") {
      script.src =
        "https://ark.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    } else {
      script.src =
        "https://qabackend.shalomworld.org/wp-content/plugins/shalomworldtv/includes/js/dollar-a-day.js";
    }
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //   Reset the count if Url location changed
  useEffect(() => {
    setPagination({
      page: 1,
      perPage: 15,
    })
    setCheck(prevCheck => !prevCheck);
    //Reset so it reflect paginate

  }, [category]);


  if (path === "/shows") {
    category = "popular";
  }

  useEffect(() => {
    if (pagination && pagination.page >= 1) {
      loadItems();
    }
  }, [pagination]);


  const onPagination = (pageNumber) => {
    setIsShimmer(true)

    setPagination({
      ...pagination,
      page: pageNumber,
    });
  };

  const loadItems = () => {
    removeProgammes(category)
    executeScroll();
    fetchCategoryPrograms(category, pagination.page, pagination.perPage);

    const timer = setTimeout(() => {
      setIsShimmer(false)
    }, 1000);
    return () => clearTimeout(timer);
  };

  const executeScroll = () =>
    listingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });


  return (
    <div
      className={`${styles.headingAlign} mob-app-pad-0 mob-app-mar-0`}
      ref={listingRef}
    >
      <TitleComponent title="Shalom world | Our shows" />
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <Container>
        <div className={styles.bgImageWrap_new}>
          <img src={programes.bgImage} alt="" />
        </div>
        {width >= 992 ? (
          <h1 className={`${styles.ttlHome} ${classes?.seasonalClass}`}>
            Our Shows
          </h1>
        ) : null}
        <CategoryList SetCatgeory={SetCatgeory} setPagination={setPagination} />
        <div>
          <ProgramList
            category={categoryData}
            categoryProgrames={programes}
            isShimmer={isShimmer}
          />

          <Paginate
            currentPage={pagination.page ? pagination.page : 1}
            onPagination={onPagination}
            totalPages={programes[category]?.totalPage}
            pageNumberLimit={width >= 992 ? 5 : 3}
            check={check}
          />
        </div >

      </Container >
    </div >
  );
};
const mapStateToProps = (state) => {
  return { programes: state.programes, categories: state.categories };
};

export default connect(mapStateToProps, {
  fetchPrograms,
  fetchCategoryPrograms,
  removeProgammes
})(OurShows);
