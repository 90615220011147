import { useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";

export const useHomeLiveVideo = props => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const qRegion = query.get("region");

    const { fetchToken, getSteamUri, region, playError, setPlayError, watchUrl, setWatchUrl } = props

    useEffect(() => {
        if (playError) {
            fetchToken().then((value) => {

                if (qRegion) {
                    getSteamUri(value, qRegion).then((value2) => {
                        setWatchUrl(`${value2.url}?token=${value.secure_token}&timestamp=${value.timestamp}&clientId=${value.client_id}`)
                    })
                } else {
                    getSteamUri(value, region).then((value2) => {
                        setWatchUrl(`${value2.url}?token=${value.secure_token}&timestamp=${value.timestamp}&clientId=${value.client_id}`)
                    })
                }

            })
        }

    }, [region, playError])

    return {

        watchUrl
    }
}