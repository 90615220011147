import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown'
import { fetchSecMenu } from "../../actions/menuAction";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './SecMenu.module.scss';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useSeasonal } from '../../logic/Seasonal';
import BrowserPersistence from "../../util/simplePersistance";
import Assets from "../Layouts/Assets";

const storage = new BrowserPersistence();

const useStyles = makeStyles({
  list: {
    width: 540,
  },
  fullList: {
    width: 'auto',

  },
});

const SecMenu = ({ fetchSecMenu, secMenu, liveSchedule, region, setRegion, redRegion }) => {

  const [isActiveClass, setActiveClass] = useState(true);

  const toggleClass = () => {
    setActiveClass(!isActiveClass);
  };

  useEffect(() => {
    if (secMenu === 0 || secMenu === void 0 || secMenu.length === 0) {
      fetchSecMenu();
      // document.body.classList.add('sidemenu');
    }
  }, []);
  useEffect(() => {
    // Add the class to the body element
    document.body.classList.add('sidemenu');

    // Remove the class when the user navigates to another page
    return () => {
      document.body.classList.remove('sidemenu');
    };
  }, []);
  const classes2 = useStyles();
  const [state, setState] = useState({ left: false });
  const location = useLocation();
  const [selectRegion, setSelectedRegion] = useState(storage.getItem("CountryCode"));
  const { seasonal, classes } = useSeasonal();

  useEffect(() => {
    const solution = _.toArray(liveSchedule).map((product) => {
      let newRegion = region;
      if (product.slug === newRegion) {
        setSelectedRegion(product);
      }
    })
  }, [liveSchedule, region])

  const toggleDrawer = (open) => (event) => {

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, ['left']: open });

  };

  const list = () => (
    <div
      className={clsx(classes2.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >

      <List className={`${seasonal?.classes?.sideMenu} sideMenuWrap`}>
        {secMenu.map((value, index) => (
          <ListItem button key={value.id} >
            <Link to={`/${value.slug}`} className={`${classes.hoverClass} ${classes.textDecororationNone}`}>
              <ListItemText primary={value.title} />
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );

  window.addEventListener('scroll', () => {
    if (state.left) {
      setState({ ...state, ['left']: false });
    }
  });

  return (
    <div className={styles.live_header}>
      <div className={styles.menu_wrap}>
        <div className={`${styles.hamburger} ${classes.hamburgerHover} c0111 c0127 custom-live-header ${state['left'] ? styles.open : ''}`} onClick={() => {

          if (state['left']) {
            setState({ ...state, ['left']: false })
          }
          else {

            setState({ ...state, ['left']: true })
          }
        }}>
          {/* ${classes.watchLivebtn} */}
          <span className={``}></span>
          <span className={``}></span>
          <span className={``}></span>
        </div>



        {location.pathname.slice(0, location.pathname.lastIndexOf('/')) != '/episode' &&
          <div className={`${styles.hmDropdown} ${classes.watchLiveDDL} hamDDL`}>

            {selectRegion?.caption && <Dropdown onClick={toggleClass} >
              <Dropdown.Toggle variant="success" id="dropdown-basic"
                className={`dropdown-btn `}
              >
                <span className={`custom-dropdown-toggle-span ${styles.actionPlay} ${classes.seasonalLeftBorder}`}></span>
                {selectRegion?.caption} ({selectRegion?.name})
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {_.toArray(liveSchedule).map((value, index) => {
                  return (
                    <>
                      {value.slug !== region ? <Dropdown.Item onClick={() => {
                        setRegion(value.slug)
                        setSelectedRegion(value)
                      }}
                      >

                        {value?.name && <>
                          {value.caption} ({value.name})
                        </>}

                      </Dropdown.Item> : null}
                    </>

                  )
                })}
              </Dropdown.Menu>
            </Dropdown>}

          </div>
        }
        <div className={`${styles.hamburgerMenu} hamMenu`}>
          <Drawer anchor={'left'} open={state['left']}
            className={`custom-hamburger ${styles.drawer} ${state['left'] ? styles.classname : ''}`}
            onClose={toggleDrawer(false)}
            onClick={toggleDrawer(false)}  >
            {list()}
          </Drawer>
        </div>
      </div>
      {/* <div className={styles.logo_img}>
        <Link data-toggle="Home" to="/">
          <img src={Assets.logos} alt="Shalomworld" />
        </Link>
      </div> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return { secMenu: state.secMenu, liveSchedule: state.liveSchedule, redRegion: state.region }
}

export default connect(mapStateToProps, { fetchSecMenu })(SecMenu);