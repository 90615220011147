/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { connect } from 'react-redux'
import { fetchLiveSchedule } from '../../actions/LiveScheduleAction'
import _ from 'lodash'
import styles from './UpcomingPrograms.module.scss'
import { useSeasonal } from '../../logic/Seasonal'
import BrowserPersistence from '../../util/simplePersistance'
// import { useHistory } from "react-router-dom";
// import { FaPlayCircle, FaPauseCircle, FaPlay } from 'react-icons/fa'
// import Row from 'react-bootstrap/Row'

const storage = new BrowserPersistence()

const UpcomingPrograms = ({
  fetchLiveSchedule,
  liveSchedule,
  region,
  setRegion,
  play,
  setPlay,
  geolocation
}) => {
  const watchScheduleRef = useRef()
  const { classes } = useSeasonal()



  useEffect(() => {
    var len = Object.keys(liveSchedule).length
    if (len === 0) {
      fetchLiveSchedule()
    } else {
      if (
        region === undefined &&
        storage.getItem('CountryCode') === undefined &&
        Object.keys(liveSchedule)[0]
      ) {
        setRegion(Object.keys(liveSchedule)[0])
        //history.push(`/`);
      }
    }
    if (typeof region === 'undefined' && Object.keys(liveSchedule)[0] !== 'undefined') {
      setRegion(Object.keys(liveSchedule)[0])
    }
  }, [liveSchedule])

  let maxLength = Math.max(
    ..._.toArray(liveSchedule)?.map((schedules) => schedules.schedules?.length),
  )

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  }

  return (
    <section className={styles.upcomingSec} ref={watchScheduleRef}>
      <Container>
        {_.toArray(liveSchedule).length > 0 && (
          <Carousel responsive={responsive}>
            {_.toArray(liveSchedule).map((value, index) => {
              return (
                <div
                  className={styles.upcomingProgram}
                  key={index}
                  onClick={() => {
                    setRegion(value.slug)
                    setPlay(play => !play);
                  }}
                >
                  <div
                    className={
                      region !== value.slug
                        ? `${styles.channelTitle}`
                        : `${styles.channelTitle} ${styles.activeChannel}  ${classes?.seasonalClass}`
                    }
                  >
                    <div className={`${styles.caption} ${classes?.txtColor}`}>
                      <span>LIVE</span> {value.caption}
                    </div>

                    <div className={`${styles.name}`}>

                      {region === value.slug && play ? (
                        <span
                          className={`${styles.actionPouse} ${classes?.pauseClass}`}
                        ></span>
                      ) : (
                        <span
                          className={`${styles.actionPlay} ${classes?.playClass}`}
                        ></span>
                      )}

                      {value.name}
                    </div>
                  </div>
                  {value?.schedules?.length > 0
                    ? value?.schedules.map((value2, index2) => {
                      return (
                        <div className={styles.programList} key={index2}>
                          <div className={styles.program}>{value2?.name}</div>
                          <div className={styles.nowplay}>
                            {' '}
                            {value2?.start} - {value2?.end}
                          </div>
                        </div>
                      )
                    })
                    : null}
                  {_.times(maxLength - value?.schedules?.length, () => (
                    <div className={styles.commingsoon}>Coming soon</div>
                  ))}
                </div>
              )
            })}
          </Carousel>
        )}
      </Container>
    </section >
  )
}

const mapStateToProps = (state) => {
  return { liveSchedule: state.liveSchedule, geolocation: state.liveSchedule }
}

export default connect(mapStateToProps, { fetchLiveSchedule })(UpcomingPrograms)
