import React from "react";
import Cardonation from "../components/PeacefellowshipIneer/CarDonation";

import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from "../logic/Seasonal";

const CardonationPage = () => {
  const { width } = useDimensions();
  const { classes } = useSeasonal();
  return (
    <>
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <Cardonation classes={classes} />
    </>
  );
};

export default CardonationPage;
