import {FETCH_WATCHON_DETAILS} from '../actions/types.js';

const INTIAL_STATE = {
    watchonDetails:[]
};
export default (state = INTIAL_STATE,action) => {
    switch(action.type){ 
        case FETCH_WATCHON_DETAILS: 
        return {
            ...state,
            watchonDetails:action.payload
        }
        default:
            return state;
    }
}