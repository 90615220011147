import React, { useState } from "react";
import styles from "./CustomAccordion.module.scss";
import { useSeasonal } from "../../../logic/Seasonal";
import ReactHtmlParser from "react-html-parser";
const CustomAccordion = ({ heading, content }) => {
  const [isActive, setIsActive] = useState(false);
  const { seasonal, classes } = useSeasonal();
  return (
    <div

      className={`custom-accordion ${styles.accordion_item} ${isActive ? styles.active : ""} ${
        classes?.seasonalClass
      } ${classes?.seasonalClass2}`}
    >
      <div
        className={`custom-accordion-toggle ${styles.accordion_toggle} ${classes.seasonalClass} ${classes.seasonalClass2}`}
        onClick={() => setIsActive(!isActive)}
      >
        <h3>{heading} </h3>
      </div>
      {isActive && (
        <div className={`${styles.accordion_content} ${classes.contentAnchorColor}`}>
          {" "}
          {ReactHtmlParser(content)}
        </div>
      )}
    </div>
  );
};

export default CustomAccordion;
