import React,{useState, useEffect} from 'react'
import { connect } from "react-redux";
import styles from './Terms.module.scss';
import Container from 'react-bootstrap/Container'
import {fetchTerms} from '../../actions/termsAction';
import BrowserPersistence from '../../util/simplePersistance';
import ReactHtmlParser from 'react-html-parser';
const storage = new BrowserPersistence();
const Terms = ({fetchTerms, terms}) => {
    const region = storage.getItem('CountryCode');
    useEffect(() => {
        // if(fellowship.length ==0)
        fetchTerms(region);
      }, [region]);

      
    
    return(
        <Container>
        <div className={styles.sw_cmsWrap}>
            <div>{ReactHtmlParser(terms.content)}</div>
            {/* <div className={styles.sw_cmsWrap}>
            <div className="container">
                <div className={styles.stmnt_para}>
                    <p>
                        <strong>Shalom Media USA Inc.(www.shalomworld.org) – Terms and Conditions</strong>
                    </p>
                    <div className="content-wrp">
                    <p>Terms and Conditions</p>
                    <p>Welcome to www.shalomworld.org !</p>
                    <p>Thanks for visiting our web site. This website is provided by Shalom Media USA, Inc. (“Shalom Media USA, Inc.”), located at 211 E Wisconsin Rd, Edinburg, TX.78539. www.shalomworld.org is the official web site for Shalom Media USA, Inc. a not for profit organization.</p>
                    </div>
                    <p><strong>Using our Web Site Services</strong></p>
                    <p>www.shalomworld.org is so diverse in its content and services and so, sometimes additional terms or product requirements may apply. Additional terms will be available with the relevant Services, and those additional terms will become part of your agreement with us if you use those services. We have taken maximum effort to design our web sites to be useful and informative in content and design. By visiting this website, services, and contents, you agree to abide by the following Terms and Conditions. By using our sites you automatically agree to them. Naturally, if you don’t agree, please do not use our sites. We reserve the right to make any modifications that we deem necessary at any time. Please continue to check these terms to see what those changes may be. Your use of the Shalom World and Shalom Media USA Web sites means that you accept those changes.</p>
                    <p><strong>You must follow any policies made available to you within the Services</strong></p>
                    <p>By visiting the web site you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Don’t misuse our Services.</p>
                    
                    <p>Shalomworld.org uses information for the following general purposes:</p>
                    <ul>
                        <li>
                        to customize the content you see,
                        </li>
                        <li>fulfill your requests for products and services,</li>
                        <li>improve our services, contact you, conduct research, and</li>
                        <li>provide anonymous reporting for internal and external clients.</li>
                    </ul>
                    <p>
                        <strong>WE COLLECT INFORMATION FOR</strong>&nbsp;
                        :We collect information to provide better services to all of our users.We use your Personally Identifiable Information to provide you with the services or products for which you requested us with
                    </p>
                </div>
                </div>
            </div>
           */}
        </div>
        </Container>
    )
}



const mapPropsToState = (state) => {
    return {  terms: state.terms };
  };
  export default connect(mapPropsToState, { fetchTerms })(
    Terms
  );