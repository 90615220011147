import styles from './SignUpTab.module.scss'
import React, { useState, useCallback, useRef } from 'react'
import { useFormik } from 'formik'
import classNames from 'classnames'
import { Row, Col } from 'react-bootstrap'
import GoogleRecaptcha from '../../components/Recaptcha'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { salomapi3 } from '../../apis'
import { useSeasonal } from '../../logic/Seasonal'
import { Spinner } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: 'black',
    },
  },
}))

const SignUpTab = ({ countryList, mediaGallery }) => {
  const [termsandconditn, setTerms] = useState(false)
  const [countryName, setCountryName] = useState('')
  const [cmsName, setCmsName] = useState('')
  const [isFormSuccess, setFormSuccess] = useState(false)
  const [isAlertMsg, setAlertMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [captchaReset, setCaptchaReset] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const captcha = useRef(null)
  const [captchaValid, isCaptchaValid] = useState(false)
  const [validUser, isValidUser] = useState(false)
  const divClass = classNames(styles.div_fields, 'col-sm-6')
  const inputClass = classNames(styles.input_fields)
  //const classes = useStyles()

  const { seasonal, classes } = useSeasonal()
  const re = /^[0-9\b]+$/
  const regexp = /^\S*$/
  const regexp_Domain = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
  const validate = (values) => {
    const errors = {}

    if (!values.firstName) {
      errors.firstName = 'Required'
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less'
    }

    if (!values.lastName) {
      errors.lastName = 'Required'
    } else if (values.lastName.length > 20) {
      errors.lastName = 'Must be 20 characters or less'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.invalidEmail = 'Invalid email address'
    }
    if (countryName == '') {
      errors.country = 'Required'
    }
    if (cmsName == '') {
      errors.cms = 'Required'
    }
    if (!values.domname) {
      errors.domname = 'Required'
    } else if (!regexp_Domain.test(values.domname)) {
      errors.invalidDomain = 'Invalid domain name'
    }
    if (!values.password) {
      errors.password = 'Required'
    }
    if (!values.cpassword) {
      errors.cpassword = 'Required'
    }
    if (!values.phone) {
      errors.phone = 'Required'
    }
    if (!values.location) {
      errors.location = 'Required'
    }
    if (!termsandconditn) {
      errors.disclaimer = 'Required'
    }
    if (!captchaValid) {
      errors.captchaValid = 'Please valid the Captcha'
    }
    return errors
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      domname: '',
      location: '',
      country: '',
      password: '',
      phone: '',
      cpassword: '',
      cms: '',
      disclaimer: false,
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true)
      const postData = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        domain: values.domname,
        location: values.location,
        country: countryName,
        password: values.password,
        phone: values.phone,
        repassword: values.cpassword,
        cms: cmsName,
      }
      if (captchaValid) {
        salomapi3.post('/mediaregister', postData).then((response) => {
          if (response.data.status === 'success') {
            setIsLoading(false)
            setFormSuccess(true)
            setSuccessMsg(response?.data?.msg)
            setAlertMsg(true)
            resetForm({})
            setErrorMsg('')
            setCaptchaReset(true)
            isCaptchaValid(false)
            setTerms(false)
            setTimeout(() => {
              setFormSuccess(false)
              setCaptchaReset(false)
              setAlertMsg(false)
              setSuccessMsg('')
            }, 6000)
          } else {
            setErrorMsg(response.data.msg)
            setIsLoading(false)
          }
        })
      }
      if (captcha.current.getValue()) {
        isValidUser(true)
        isCaptchaValid(true)
      } else {
        isValidUser(false)
        isCaptchaValid(false)
      }
      //  alert(JSON.stringify(values, null, 2));
    },
  })

  const CountryChange = useCallback((event, newValue) => {
    if (newValue) setCountryName(newValue.country_name)
  }, [])

  const CountryCms = useCallback((event, newValue) => {
    if (newValue) setCmsName(newValue.value)
  }, [])
  const handleOnChange = () => {
    isCaptchaValid(true)
  }

  return (
    <div className={styles.gallery_signup}>
      <h3>Hello, Welcome back!</h3>

      <form className={styles.form_align} onSubmit={formik.handleSubmit}>
        <Row className={styles.div_align}>
          <div className={divClass}>
            <label>First Name </label>
            <input
              className={`${inputClass} ${formik.errors.firstName &&
                formik.submitCount &&
                styles.formerror
                }`}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              name="firstName"
              placeholder="First Name"
            ></input>
          </div>
          <div className={divClass}>
            <label>Last Name</label>
            <input
              className={`${inputClass} ${formik.errors.lastName && formik.submitCount && styles.formerror
                }`}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              name="lastName"
              placeholder="Last Name"
            ></input>
          </div>
        </Row>
        <Row className={styles.div_align}>
          <div className={divClass}>
            <label>Phone</label>
            <input
              className={`${inputClass} ${formik.errors.phone && formik.submitCount && styles.formerror
                }`}
              type="text"
              onChange={(e) => {
                if (e.target.value === '' || re.test(e.target.value)) {
                  formik.setFieldValue('phone', e.target.value)
                }
              }}
              value={formik.values.phone}
              name="phone"
              placeholder="Phone"
            ></input>
          </div>
          <div className={divClass}>
            <label>Email</label>
            <input
              className={`${inputClass} ${formik.errors.email && formik.submitCount && styles.formerror
                }`}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              placeholder="Email Address"
            ></input>
          </div>
        </Row>
        <Row className={styles.div_align}>
          <div className={`${divClass} ${styles.ddlCountry}`}>
            <label>Country</label>
            {/* <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        getOptionLabel={(option) => option.title}

                        renderInput={(params) => <TextField {...params} className={styles.color} label="Combo box" variant="outlined" />}
                        /> */}
            <Autocomplete
              id="combo-box-demo"
              classes={classes}
              options={countryList}
              getOptionLabel={(option) => option.country_name}
              renderOption={(option) => (
                <div style={{ color: 'white' }}>{option.country_name}</div>
              )}
              style={{ width: 204 }}
              onChange={CountryChange}
              renderInput={(params) => (
                <TextField
                  className={`${inputClass} ${formik.errors.country &&
                    formik.submitCount &&
                    styles.formerror
                    }`}
                  {...params}
                  placeholder="Select Country"
                  name="country"
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className={divClass}>
            <label>Location</label>
            <input
              className={`${inputClass} ${formik.errors.location && formik.submitCount && styles.formerror
                }`}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.location}
              name="location"
              placeholder="Enter Location"
            ></input>
          </div>
        </Row>
        <Row className={styles.div_align}>
          <div className={divClass}>
            <label>Password</label>
            <input
              className={`${inputClass} ${formik.errors.password && formik.submitCount && styles.formerror
                }`}
              type="password"
              onChange={(e) => {
                if (e.target.value === '' || regexp.test(e.target.value)) {
                  formik.setFieldValue('password', e.target.value)
                }
              }}
              value={formik.values.password}
              name="password"
              placeholder="Password"
            ></input>
          </div>
          <div className={divClass}>
            <label>Confirm Password</label>
            <input
              className={`${inputClass} ${formik.errors.cpassword &&
                formik.submitCount &&
                styles.formerror
                }`}
              type="password"
              onChange={(e) => {
                if (e.target.value === '' || regexp.test(e.target.value)) {
                  formik.setFieldValue('cpassword', e.target.value)
                }
              }}
              value={formik.values.cpassword}
              name="cpassword"
              placeholder="Confirm Password"
            ></input>
          </div>
        </Row>
        <Row className={styles.div_align}>
          <div className={divClass}>
            <label>Domain Name</label>
            <input
              className={`${inputClass} ${formik.errors.domname && formik.submitCount && styles.formerror
                }`}
              onChange={formik.handleChange}
              value={formik.values.domname}
              name="domname"
              placeholder="Domain Name : your-parish-website-url"
            ></input>
          </div>

          <div className={divClass}>
            <label>CMS</label>
            <Autocomplete
              id="combo-box-demo"
              className={styles.cmsClass}
              options={mediaGallery.cms}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <div style={{ color: 'white' }}>{option.name}</div>
              )}
              style={{ width: 204 }}
              onChange={CountryCms}
              renderInput={(params) => (
                <TextField
                  className={`${formik.errors.cms && formik.submitCount && styles.formerror
                    }`}
                  {...params}
                  placeholder="Select CMS*"
                  name="cms"
                  variant="outlined"
                />
              )}
            />
          </div>
        </Row>
        <Row className={styles.div_align}>
          <div className={styles.captchaWrap}>
            <GoogleRecaptcha
              onChange={handleOnChange}
              isCaptchaReset={captchaReset}
            />
          </div>
        </Row>
        <Row className={styles.div_align}>
          <Col>
            <div className={`checkbox_wrap`}>
              <input
                type="checkbox"
                id="accept"
                name="disclaimer"
                checked={termsandconditn}
                value={termsandconditn}
                className="form-control"
                onChange={(e) => {
                  setTerms(e.target.checked)
                }}
              />
              <label htmlFor="accept">
                <span>
                  I agree to the
                  <a href="/termsandconditions" target="_blank" className={classes?.txtColor} >
                    Terms and Conditions .
                  </a>
                </span>
              </label>
            </div>
          </Col>
        </Row>
        <button
          className={`${styles.signup_btn} ${classes?.bgBtnClass}  ${styles.uploading}`}
          type="submit"
        >
          &nbsp;
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
          Submit
        </button>
        {Object.keys(formik.errors).length != 0 && formik.submitCount ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>
              Please fill all required fields.
            </span>
          </div>
        ) : null}
        {formik.errors.invalidEmail && formik.submitCount ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>
              Please Enter a valid Email Id
            </span>
          </div>
        ) : null}
        {formik.errors.invalidDomain && formik.submitCount ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>
              Please Enter a valid Domain Name
            </span>
          </div>
        ) : null}

        {isFormSuccess ? (
          <div className="form-group col-sm-12">
            <div
              className={`${styles.alert_msg_wrap} ${isAlertMsg ? styles.show : styles.hide
                }`}
            >
              <span
                className={`${styles.resonseSuccessMsg} ${styles.alert_msg}`}
              >
                {successMsg}
              </span>
              <a
                href={() => false}
                className={styles.close_alert}
                onClick={() => {
                  setAlertMsg(false)
                }}
              >
                &times;
              </a>
            </div>
          </div>
        ) : null}
        {errorMsg ? (
          <div className="form-group col-sm-12">
            <span className={styles.resonseFailedMsg}>{errorMsg}</span>
          </div>
        ) : null}
      </form>
    </div>
  )
}

export default SignUpTab
