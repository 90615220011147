import {FETCH_SWPRAYER,FETCH_SWPRAYERDETAILS,FETCH_COUNTRY_LIST,FETCH_STATE_FROM_COUNTRY,FETCH_TIME_FROM_DATE} from './types';
import salomapi from '../apis';
import {salomapi3} from '../apis';


export const fetchSwprayerDetails = () => async dispach => {
    const response = await salomapi.get('/swprayer/details');
    dispach({type:FETCH_SWPRAYERDETAILS,payload:response.data})
}


export const fetchSwprayer = () => async dispach => {
    const response = await salomapi.get('/swprayer')
    dispach({type:FETCH_SWPRAYER,payload:response.data})
}

export const fetchCountryList = () => async dispach => {
    const response = await salomapi3.get('/countries')
   
    dispach({type:FETCH_COUNTRY_LIST,payload:response.data.countries})
}
export const fetchStateFromCountry = (countryId) => async dispach => {
    const response = await salomapi3.get('/states?countryId='+countryId)
    dispach({type:FETCH_STATE_FROM_COUNTRY,payload:response.data.states})
}
export const fetchTimeFromDate = (date) => async dispach => {
    const response = await salomapi.get('/swprayer/masstime?date='+date)
     dispach({type:FETCH_TIME_FROM_DATE,payload:response.data.weeks})
}