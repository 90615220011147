import { FETCH_PODCASTS, FETCH_MOREPODCASTS, UPDATE_PODCAST } from './types';
import salomapi from '../apis';

export const fetchPodcasts = (currentPage) => async dispatch => {

  const response = await salomapi.get('/podcasts?page=' + currentPage + '&perpage=4');
  if (response.data.status === 'success') {
    if (currentPage === 1) {
      dispatch({ type: FETCH_PODCASTS, payload: { data: response?.data?.programs, banner: response?.data?.banner, totalPages: response?.data?.pages, currentPage: 1 } })
    } else {
      dispatch({ type: FETCH_MOREPODCASTS, payload: { data: response?.data?.programs, totalPages: response?.data?.pages, currentPage: currentPage } })
    }
  }
}

export const updatePodcast = (podcast) => async dispatch => {
  dispatch({ type: UPDATE_PODCAST, payload: { data: podcast } })
}


