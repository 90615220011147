import {
    FETCH_EVENTDETAILS,
    FETCH_EVENTRELATEDVIDEOS,
    FETCH_EVENTPASTVIDEOS,
    UPDATE_EVENTDETAILS,
    UPDATE_EVENTDETAILS_STATUS,
    CLEAR_THUMBNAILS
} from "./types";
import salomapi from "../apis";
import salomapi3 from "../apis";

// Action creator for fetching event details
export const fetchEventDetails = (eventId) => async (dispatch) => {
    const response = await salomapi.get("/events", { params: { slug: eventId } });
    dispatch({ type: FETCH_EVENTDETAILS, payload: response.data.result });
};

// Action creator for fetching event details
export const fetchEventRelatedVideos = (eventId) => async (dispatch) => {
    const response = await salomapi.get("/events/relatedvideos", {
        params: { slug: eventId },
    });
    dispatch({ type: FETCH_EVENTRELATEDVIDEOS, payload: response.data.result });
};

// Action creator for fetching event details. Past
export const fetchEventPastVideos = (eventId) => async (dispatch) => {
    const response = await salomapi.get("/events/pastvideos", {
        params: { slug: eventId },
    });
    dispatch({ type: FETCH_EVENTPASTVIDEOS, payload: response.data.result });
};

//Fetches a new token URL for a specific live stream and updates the event details.
export const fetchNewTokenurl =
    (stream, slug, data, eventType) => async (dispatch) => {
        const response = await salomapi3.get(
            `events/watchlivestream?slug=${slug}&stream=${data.stream}`
        );
        dispatch({
            type: UPDATE_EVENTDETAILS,
            payload: response.data.result,
            stream: stream,
            eventType: eventType,
        });
        return response;
    };
export const updateEventStatus = () => async (dispatch) => {
    dispatch({ type: UPDATE_EVENTDETAILS_STATUS, payload: null });
};

export const clearThumbnails = () => async (dispatch) => {

    dispatch({ type: CLEAR_THUMBNAILS, payload: null });
};
