import React from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const TitleComponent = ({ title }) => {
  
  var defaultTitle = "Shalom World | Experience a New Media Culture";
  const location = useLocation();
  let path = location.pathname;
  let page = path;
  
  if (path.includes("show") && path != "/shows") {
    const arr = path.split("/");
    page = arr[arr.length - 1];
  }
  if (path.includes("episode") && path != "/episodes") {
    const arr = path.split("/");
    page = arr[arr.length - 1];
  }
  if (path.includes("news")) {
    const arr = path.split("/");
    page = arr[arr.length - 1];
  }

  if (path.includes("podcast")) {
    //Check if podcast detail or  podcast episode detail page
    let urlParams = path.split("/");

    if (urlParams.length === 3) {
      page = urlParams[2];
    } else if (urlParams.length === 4) {
      page = urlParams[3];
    }
  }

  const seoData = useSelector((state) => state.seo);
  let meta = seoData.find((o) => o.page === page);

  return (
    <>
    <Helmet>
      <title>
        {meta?.seoData?.title[0] ? meta?.seoData?.title[0] : defaultTitle}
      </title>
      <description>
        {meta?.seoData?.description[0]
          ? meta?.seoData?.description[0]
          : defaultTitle}
      </description>
    </Helmet>
    </>

  );
};

export { TitleComponent };
